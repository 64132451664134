import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-couponslider',
  templateUrl: './couponslider.component.html',
  styleUrls: ['./couponslider.component.css']
})
export class CouponsliderComponent implements OnInit {

  checkname:string="";
  couponColl:any=[];
  couponcode:string="";
  

  constructor(public cartservice: CartService,public loginservice: LoginService,public checkoutservice: CheckoutService,
            public router: Router) { 
      
    this.cartservice.servicecouponChange.subscribe((value) => {
      this.fnGetCoupon();    
    }); 

 }
  ngOnInit() {
  }

  
  fnGetCoupon(){  
    var data = {
      'couponcode':''
    }

  
  this.checkoutservice.fnGetCoupon(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                        
            this.couponColl=res.json().result;  
                              
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }



    
  fnGetCouponByCode(){  

  
    var data = {
      'couponcode':this.couponcode
    }

  
  this.checkoutservice.fnGetCoupon(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){   
                             
            let _dcoll =res.json().result;  
            
            if(_dcoll.length>0){
             
              let _cBO=_dcoll[0];
       
              this.fnSetCouponByOrder(_cBO.couponid);
                        
            }

          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }


  fnSetCouponByOrder(couponid){
    var data = {
      'couponid':couponid
    }

  
  this.checkoutservice.fnSetCoupon(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                        
            this.cartservice.fnGetCartList();
            this.cartservice.fnCheckSlideCoupon()
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }





  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "-");
    return str;
  }
  


  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }


}
