import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from './services/cart.service';
import { HomeService } from './services/home.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Nativelovers';

  isScrolltoTopShow: boolean=false;
  ScrollClass:string="img-hidden";

  HeaderScrollClass:string="aaa";

  tempcurrentposition:any=100;
   c:any=0;
   currentScrollTop :any= 0;

   currLat:any;
   currLng:any;
   settingsColl:any=[];

  constructor(private translate: TranslateService,public cartservice: CartService,public homeservice:HomeService ) {
    if (localStorage.lan === undefined) {
      localStorage.setItem('lan', 'en');
    }
    translate.addLangs(['en','ar']);
    translate.setDefaultLang(localStorage.lan);   

    // var a = 0;
    // localStorage.setItem('wh', a.toString());
    
  }
  

  randomkey;
  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lan', language);
  }


  private _opened: boolean = false;
  onActivate(event) {
    let el = document.getElementById("scrollfcus");
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    

   // window.scroll(0,-100);
   
  }


  
  ngOnInit() {
    try{

      window.addEventListener('scroll', this.checkScroll, true);
      var el: HTMLElement = document.getElementById('scrolltodiv');
      el.setAttribute("style", " visibility: hidden;");

      var ele: HTMLElement = document.getElementById('appheader');                   
      ele.classList.add('headroom--unfixed'); 
      ele.classList.add('headroom'); 
      
      this.fnGetSettingsByType();
    }catch(ex){
      
    }
  }

  ngOnDestroy() {        
    window.removeEventListener('scroll', this.checkScroll, true);
  }


//-------------------------------------------------------------------------




@HostListener('window:scroll',['$event'])
checkScroll(event: any) {
  //Scroll To Top
  let scrollPosition = window.pageYOffset ||event.srcElement.scrollTop || document.body.scrollTop || 0;
  let limit=100;
  scrollPosition = Math.floor(Number(scrollPosition))

  
  if (scrollPosition >limit) {  
    var el: HTMLElement = document.getElementById('scrolltodiv');
    el.setAttribute("style", " visibility: visible;");
  } else {      
    var el: HTMLElement = document.getElementById('scrolltodiv');
    el.setAttribute("style", " visibility: hidden;");
  }


  //Scroll To Set Class

   var a = event.srcElement.scrollTop ;
   var b = 100;     
   this.currentScrollTop = a;
     
   if (this.c < this.currentScrollTop && a > b + b) {
     var ele: HTMLElement = document.getElementById('appheader');                   
      ele.classList.add('headroom--unpinned'); 
      ele.classList.remove('headroom--unfixed'); 
      ele.classList.remove('headroom--pinned');
    } else if (this.c > this.currentScrollTop && !(a <= b)) {
      var ele: HTMLElement = document.getElementById('appheader');                   
      ele.classList.add('headroom--pinned');    
      ele.classList.remove('headroom--unpinned'); 
      ele.classList.remove('headroom--unfixed');  
    }

    if ( a <= 5 ){
      var ele: HTMLElement = document.getElementById('appheader');                   
      ele.classList.add('headroom--unfixed'); 
      ele.classList.remove('headroom--unpinned'); 
      ele.classList.remove('headroom--pinned');  
    }
       
 
      this.c = this.currentScrollTop;

}


findMe() {
  if (window.navigator.geolocation) {


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
        console.log(position);
        },
        (error) => {
          console.log(error);
        },

      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
   
 
    
  }


  


    // window.navigator.geolocation.watchPosition(
    //   (position) => {
    //   console.log(position)          
    //   }, (error) => {
    //     console.log(error)          
    //   });
    // } else {
    //   console.log("error")          
    // }


    // window.navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //   this.fnGetCurrentLocation(position);
    //   },
    //   (failure) => {
    //     if (failure.message.indexOf("Only secure origins are allowed") == 0) {
    //       alert('Only secure origins are allowed by your browser.');
    //     }
    //   }
    // );
 // } else {
  // alert("Geolocation is not supported by this browser.");
 // }
}

fnGetCurrentLocation(position){
  
  console.log(position);

  // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition(position => {
  //     console.log(position.coords.latitude);
  //     this.currLat = position.coords.latitude;
  //     this.currLng = position.coords.longitude;

      
  //   });

  
  // }
  // else {
  //   alert("Geolocation is not supported by this browser.");
  // }
}




fnGetSettingsByType() { 
  var data = {   
    'settingstype':'MYACCOUNT'            
  } 
  this.homeservice.fnGetSettingsByType(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.settingsColl=res.json().result;    
          
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }
          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
}


  fnWhatsappShareLink(){
    let whatsphno='';
    let whatsmsg='';

    const index = this.settingsColl.findIndex(a => a.settingsname ==="WHATSAPPNO" );
    if(index>-1){
      whatsphno=this.settingsColl[index].settingsvalue;
    }

    const index1 = this.settingsColl.findIndex(a => a.settingsname ==="WHATSAPPMSG" );
    if(index1>-1){
      whatsmsg=this.settingsColl[index1].settingsvalue;
    }
    
    let href="https://api.whatsapp.com/send?phone="+whatsphno+"&amp;text="+whatsmsg 

    if(href){
      window.open(href, "_blank");  
    }

  }


private  fnShowErrorMsg(ex){
  console.log(ex);       
}


}
