import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { HeaderService } from 'src/app/services/header.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { LoginService } from 'src/app/services/login.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';

var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  cartBO: any = {};
  cartView: any = {};
  customerBO: any = {};
  customerView: any = {};

  deptColl: any = [];
  categoryColl: any = [];
  deptcategoryColl: any = [];
  subcategoryColl: any = [];
  deptsubcategoryColl: any = [];
  tempdeptColl: any = [];

  itemautocomplteColl: any = [];
  customersearchautocomplteColl: any = [];
  noncustomersearchautocomplteColl: any = [];
  popularsearchautocomplteColl: any = [];
  popularbrandsearchautocomplteColl: any = [];
  popularcategorysearchautocomplteColl: any = [];
  autocompleteclass = "lsdropdownhide";


  cityColl: any = [];
  locationid: number = null;
  locationclass = "ldropdownhide";
  locationname: String = "";
  locationstatus: String = "";
  currentlocationname: String = "";
  locationshakeclass = "";
  private geoCoder;


  headermenuclass = "displaybloc";

  itemsearchform = new FormGroup({
    itemsearch: new FormControl(''),
  });


  settingsColl: any = [];
  alertmsgenable = "0";
  alertmsg = "";
  alermsgbackcolor = "";
  alertmsgdismiss = "0";

  customeralertBO: any = {};

  isselectaddress: boolean = false;
  ismanualaddress: boolean = false;

  isenglish: boolean = false;
  isarabic: boolean = false;

  constructor(public headerservice: HeaderService,
    public cartservice: CartService, public loginservice: LoginService, public homeservice: HomeService,
    public checkoutservice: CheckoutService, private translate: TranslateService,
    public router: Router, private modelService: NgbModal, private route: ActivatedRoute, private mapsAPILoader: MapsAPILoader) {
    this.fnServiceChanges()

    if (localStorage.lan === undefined) {
      this.isenglish = true;
    } else {
      if (localStorage.lan === 'ar') {
        this.isarabic = true;
      } else {
        this.isenglish = true;
      }

    }
  }

  fnServiceChanges() {
    this.cartView = this.cartservice.cartView;
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value

      if (this.cartView.cartmain) {
        this.cartBO = this.cartView.cartmain
      }
    });

    this.customerView = this.loginservice.customerView;
    this.loginservice.servicecustomerChange.subscribe((value) => {
      this.customerView = value
      if (this.customerView.customermain) {
        this.customerBO = this.customerView.customermain
        this.fnGetCustomerAlertNotification();
      }
    });

    this.loginservice.serviceloginChange.subscribe((value) => {
      if (value === true) {
        this.fnSignupLoginBtnClick("LOGIN");
      }
    })


    this.headerservice.servicelocationChange.subscribe((value) => {

      if (value === true) {
        this.ismanualaddress = value
        this.fnShowLocation();
      }
    })

  }

  ngOnInit() {
    try {
      this.fngetDepartment();
      this.fngetCategory();
      this.fngetSubCategory();
      this.fnGetCity();
      this.fnCheckLocation();
      this.fnInitializeSearchFormGroup();
      this.fnGetSettingsByType();
    } catch (ex) {
      this.fnShowErrorMsg('Internal Error' + ex);
    }
  }

  fnswitchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lan', language);
    window.location.reload();
  }

  fnInitializeSearchFormGroup() {
    this.itemsearchform.setValue({
      itemsearch: '',
    })
  }

  fnGetSettingsByType() {
    var data = {
      'settingstype': 'DASHBOARD'
    }
    this.homeservice.fnGetSettingsByType(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.settingsColl = res.json().result;

            const index = this.settingsColl.findIndex(a => a.settingsname === 'ALERTMSG');
            if (index > -1) {
              this.alertmsg = this.settingsColl[index].settingsvalue;
            }

            const index1 = this.settingsColl.findIndex(a => a.settingsname === 'ALERTMSGENABLE');
            if (index1 > -1) {
              this.alertmsgenable = this.settingsColl[index1].settingsvalue;
            }

            const index2 = this.settingsColl.findIndex(a => a.settingsname === 'ALERTMSGBACKCOLOR');
            if (index2 > -1) {
              this.alermsgbackcolor = this.settingsColl[index2].settingsvalue;
            }

            const index3 = this.settingsColl.findIndex(a => a.settingsname === 'ALERTMSGDISMISS');
            if (index1 > -1) {
              this.alertmsgdismiss = this.settingsColl[index3].settingsvalue;
            }
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })
  }

  fngetDepartment() {
    var data = {
      'deptid': 0,
    }
    this.headerservice.fnGetDeparment(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.tempdeptColl = res.json().result;
            this.deptColl = [];
            for (let wbo of this.tempdeptColl) {
              if (this.deptColl.length === 12) {
                break;
              }
              this.deptColl.push(wbo);
            }



          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })
  }

  fngetCategory() {
    var data = {
      'categoryid': 0,
      'deptid': 0,
    }
    this.headerservice.fnGetCategory(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.categoryColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })
  }

  fngetSubCategory() {
    var data = {
      'subcategoryid': 0,
      'categoryid': 0,
    }
    this.headerservice.fnGetSubCategory(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.subcategoryColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })
  }

  fnDepartmentOnHover(index) {
    if (index > -1) {
      this.deptcategoryColl = [];
      this.deptsubcategoryColl = [];

      for (let wbo of this.categoryColl) {
        if (wbo.deptid === this.deptColl[index].deptid) {
          this.deptcategoryColl.push(wbo);
        }
      }

      if (this.deptcategoryColl.length > 0) {
        for (let wbo of this.subcategoryColl) {
          if (wbo.categoryid === this.deptcategoryColl[0].categoryid) {
            this.deptsubcategoryColl.push(wbo);
          }
        }
      }



    }
  }

  fnCategoryOnHover(index) {
    if (index > -1) {
      this.deptsubcategoryColl = [];

      for (let wbo of this.subcategoryColl) {
        if (wbo.categoryid === this.deptcategoryColl[index].categoryid) {
          this.deptsubcategoryColl.push(wbo);

        }
      }

    }
  }

  private fnDeptClick(index) {

    if (index > -1) {
      var _deptid = this.deptColl[index].deptid;
      var _deptname = this.deptColl[index].deptname;
      var _pagename = this.deptColl[index].pagename;
      _deptname = this.fnRemoveSpecialCharater(_deptname);
      var _para = _deptid + "-" + _deptname;

      var _deptseourlname = this.deptColl[index].seourlname;
      if (_deptseourlname) {
        _para = _deptseourlname;
      }
      if (_pagename) {
        _para = this.fnRemoveSpecialCharater(_pagename);
      }

      this.router.navigate(['/' + _para]);


    }
  }

  private fnCategoryClick(index) {
    if (index > -1) {
      var _deptid = this.deptcategoryColl[index].deptid;
      var _deptname = this.deptcategoryColl[index].deptname;
      _deptname = this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname = this.deptcategoryColl[index].deptseourlname;

      var _categoryid = this.deptcategoryColl[index].categoryid;
      var _categoryname = this.deptcategoryColl[index].categoryname;
      _categoryname = this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname = this.deptcategoryColl[index].categoryseourlname;

      var _deptpara = _deptid + "-" + _deptname;
      var _categorypara = _categoryid + "-" + _categoryname;

      if (_deptseourlname) {
        _deptpara = _deptseourlname;
      }
      if (_categoryseourlname) {
        _categorypara = _categoryseourlname;
      }

      this.router.navigate(['/' + _deptpara + "/" + _categorypara]);
    }
  }

  private fnSubCategoryClick(scindex) {


    if (scindex > -1) {



      var _deptid = this.deptsubcategoryColl[scindex].deptid;
      var _deptname = this.deptsubcategoryColl[scindex].deptname;
      _deptname = this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname = this.deptsubcategoryColl[scindex].deptseourlname;




      var _subcategoryid = this.deptsubcategoryColl[scindex].subcategoryid;
      var _subcategoryname = this.deptsubcategoryColl[scindex].subcategoryname;
      _subcategoryname = this.fnRemoveSpecialCharater(_subcategoryname);
      var _subcategoryseourlname = this.deptsubcategoryColl[scindex].subcategoryseourlname;


      const index1 = this.subcategoryColl.findIndex(sku => sku.subcategoryid === _subcategoryid);
      if (index1 > -1) {
        var _categoryid = this.subcategoryColl[index1].categoryid;
        var _categoryname = this.subcategoryColl[index1].categoryname;
        _categoryname = this.fnRemoveSpecialCharater(_categoryname);
        var _categoryseourlname = this.subcategoryColl[index1].categoryseourlname;

      }


      var _deptpara = _deptid + "-" + _deptname;
      var _categorypara = _categoryid + "-" + _categoryname;
      var _subcategorypara = _subcategoryid + "-" + _subcategoryname;

      if (_deptseourlname) {
        _deptpara = _deptseourlname;
      }
      if (_categoryseourlname) {
        _categorypara = _categoryseourlname;
      }
      if (_subcategoryseourlname) {
        _subcategorypara = _subcategoryseourlname;
      }
      this.router.navigate(['/' + _deptpara + "/" + _categorypara + "/" + _subcategorypara]);
    }

  }

  private fnAllDeptClick() {

    this.router.navigate(['/all/Department']);
  }


  fnPageBannerHeaderHyperlinkClick(linktype, linktargetid) {


    if (linktype === null) {
      return;
    }
    if (linktargetid === null) {
      return;
    }

    //Get Url Details In Id...
    var data = {
      'linktype': linktype,
      'linktargetid': linktargetid,
    }


    this.homeservice.fnGetTargetDetails(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {

            var result = res.json().result[0];

            let _deptid = result.deptid;
            let _categoryid = result.categoryid;
            let _subcategoryid = result.subcategoryid;
            let _brandid = result.brandid;

            let _deptname = result.deptname;
            let _categoryname = result.categoryname;
            let _subcategoryname = result.subcategoryname;
            let _brandname = result.brandname;

            let _deptseoname = result.deptseoname
            let _categoryseoname = result.categoryseoname;
            let _subcategoryseoname = result.subcategoryseoname;
            let _brandseoname = result.brandseoname;

            if (linktype === 'DEPARTMENT') {
              _deptname = this.fnRemoveSpecialCharater(_deptname);
              var _deptpara = _deptid + "-" + _deptname;
              if (_deptseoname) {
                _deptpara = _deptseoname;
              }
              this.router.navigate(['/' + _deptpara]);
            }
            else if (linktype === 'CATEGORY') {

              _deptname = this.fnRemoveSpecialCharater(_deptname);
              var _deptpara = _deptid + "-" + _deptname;
              if (_deptseoname) {
                _deptpara = _deptseoname;
              }

              _categoryname = this.fnRemoveSpecialCharater(_categoryname);
              var _catepara = _categoryid + "-" + _categoryname;
              if (_categoryseoname) {
                _catepara = _categoryseoname;
              }
              this.router.navigate(['/' + _deptpara + "/" + _catepara]);
            }
            else if (linktype === 'SUBCATEGORY') {
              _deptname = this.fnRemoveSpecialCharater(_deptname);
              var _deptpara = _deptid + "-" + _deptname;
              if (_deptseoname) {
                _deptpara = _deptseoname;
              }

              _categoryname = this.fnRemoveSpecialCharater(_categoryname);
              var _catepara = _categoryid + "-" + _categoryname;
              if (_categoryseoname) {
                _catepara = _categoryseoname;
              }


              _subcategoryname = this.fnRemoveSpecialCharater(_subcategoryname);
              var _subcatepara = _subcategoryid + "-" + _subcategoryname;
              if (_subcategoryseoname) {
                _subcatepara = _subcategoryseoname;
              }
              this.router.navigate(['/' + _deptpara + "/" + _catepara + "/" + _subcatepara]);
            }
            else if (linktype === 'BRAND') {
              _brandname = this.fnRemoveSpecialCharater(_brandname);
              var _brandpara = _brandid + "-" + _brandname;
              if (_brandseoname) {
                _brandpara = _brandseoname;
              }
              this.router.navigate(['/' + _brandpara]);
            }


          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }
        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })


  }




  //Search
  fnItemSearch() {
    this.fnHideAutoComplete();

    let itemsearch = "";
    itemsearch = this.itemsearchform.get('itemsearch').value;

    if (itemsearch.length === 0) {
      return;
    }
    this.router.navigate(['/items/'], { queryParams: { q: itemsearch } });
    this.fnInitializeSearchFormGroup();
  }

  fngetItemAutoComplete(event: KeyboardEvent) {

    if (event.keyCode === 27) {
      this.fnHideAutoComplete();
      return;
    }

    let itemsearch = "";
    itemsearch = this.itemsearchform.get('itemsearch').value;
    this.itemautocomplteColl = [];
    this.customersearchautocomplteColl = [];
    this.noncustomersearchautocomplteColl = [];
    this.popularsearchautocomplteColl = [];
    this.popularbrandsearchautocomplteColl = [];
    this.popularcategorysearchautocomplteColl = [];

    if (itemsearch.length < 2) {
      this.fnHideAutoComplete();
      return;
    }

    var data = {
      'customerid': 0,
      'sessionid': '',
      'itemsearch': itemsearch,
    }
    this.headerservice.fnGetAutoComplete(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.fnSetItemAutoCompleteDetailsData(res);
            // this.itemautocomplteColl=res.json().result[0];  

            if (this.itemautocomplteColl.length > 0) {
              this.fnShowAutoComplete();
            } else {
              this.fnHideAutoComplete();
            }

          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })
  }

  fnSetItemAutoCompleteDetailsData(res) {
    var _res = res.json().result;

    if (_res.productinfo) {
      this.itemautocomplteColl = _res.productinfo;
    }

    if (_res.customersearch) {
      this.customersearchautocomplteColl = _res.customersearch;
    }

    if (_res.noncustomersearch) {
      this.noncustomersearchautocomplteColl = _res.noncustomersearch;
    }

    if (_res.popularsearch) {
      this.popularsearchautocomplteColl = _res.popularsearch;
    }

    if (_res.popularbrand) {
      this.popularbrandsearchautocomplteColl = _res.popularbrand;
    }

    if (_res.popularcategory) {
      this.popularcategorysearchautocomplteColl = _res.popularcategory;
    }


  }

  fnClearAutoComplete() {

    this.itemsearchform.patchValue({
      itemsearch: ''
    })
    this.fnHideAutoComplete();
  }

  //Click Event
  fnProductDetailsBtnClick(index) {

    var _productidid = this.itemautocomplteColl[index].skuid;
    var _productidname = this.itemautocomplteColl[index].productname;
    _productidname = this.fnRemoveSpecialCharater(_productidname);
    var _para = _productidid + "-" + _productidname;

    this.router.navigate(['/p/' + _para]);
    this.fnHideAutoComplete();
  }

  fnSearchWordsClick(itemsearch) {
    this.fnHideAutoComplete();


    if (itemsearch.length === 0) {
      return;
    }
    this.router.navigate(['/'], { relativeTo: this.route, queryParams: { q: itemsearch } });
    this.fnInitializeSearchFormGroup();
  }

  private fnBrandWordClick(index) {

    if (index > -1) {
      this.fnHideAutoComplete();

      var _brandid = this.popularbrandsearchautocomplteColl[index].brandid;
      var _brandname = this.popularbrandsearchautocomplteColl[index].brandname;
      _brandname = this.fnRemoveSpecialCharater(_brandname);
      var _para = _brandid + "-" + _brandname;
      this.router.navigate(['/' + _brandname]);
      this.fnInitializeSearchFormGroup();
    }
  }

  private fnCategoryWordClick(index) {
    if (index > -1) {
      this.fnHideAutoComplete();

      var _deptid = this.popularcategorysearchautocomplteColl[index].deptid;
      var _deptname = this.popularcategorysearchautocomplteColl[index].deptname;
      _deptname = this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname = this.deptcategoryColl[index].deptseourlname;

      var _categoryid = this.popularcategorysearchautocomplteColl[index].categoryid;
      var _categoryname = this.popularcategorysearchautocomplteColl[index].categoryname;
      _categoryname = this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname = this.deptcategoryColl[index].categoryseourlname;


      var _deptpara = _deptid + "-" + _deptname;
      var _categorypara = _categoryid + "-" + _categoryname;

      if (_deptseourlname) {
        _deptpara = _deptseourlname;
      }
      if (_categoryseourlname) {
        _categorypara = _categoryseourlname;
      }

      this.router.navigate(['/' + _deptpara + "/" + _categorypara]);

      this.fnInitializeSearchFormGroup();
    }
  }


  //AUTO COMPLTE HIDE VISIBLE
  fnShowAutoComplete() {
    this.autocompleteclass = "lsdropdownshow";
  }

  fnHideAutoComplete() {

    this.itemautocomplteColl = [];
    this.autocompleteclass = "lsdropdownhide";
  }


  @HostListener('document:click', ['$event'])
  fnsearchclickout(event) {
    var container = document.getElementById("searchresult");
    var locationcontainer = document.getElementById("dvlocation");
    var locationmenucontainer = document.getElementById("dvlocationmenu");

    if (container === null) {
      return
    }

    if (container.contains(event.target)) {

    }
    else if(locationcontainer.contains(event.target)) {

    }
    else if(this.ismanualaddress===true){
      this.ismanualaddress=false;
    }
    else if(locationmenucontainer.contains(event.target)) {

      if(this.fnCheckOpenCloseLocation()===true){

        this.fnShowLocation();     
      }
    } 
    else {
      this.fnHideAutoComplete();
      this.fnCheckLocation();
    }

  }


  //City
  fnGetCity() {
    var data = {
      'cityid': 0,
    }

    this.loginservice.fnGetCity(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.cityColl = res.json().result;
           
            this.fnGetCustomerArea();
            // this.fnCheckDefaultArea();
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }
        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })

  }
  fnShowLocation() {
    if( this.locationclass==="ldropdownshow"){
      this.locationclass="ldropdownhide";
   }  
   else{
      this.locationclass="ldropdownshow";
      
   } 


  }

  fnSetLocation() {

    if (localStorage.wh !== undefined) {
      if (localStorage.wh !== this.locationid) {
        let a = this.locationid;
        localStorage.setItem('wh', a.toString());
      }
    }
    else {
      localStorage.setItem('wh', this.locationid.toString());
    }
    this.fnGetCustomerArea();
    this.fnCheckLocation();

    if (this.locationclass === "ldropdownshow") {
      this.locationclass = "ldropdownhide";
    }

    window.location.reload();

  }

  fnCheckDefaultArea() {

    if (localStorage.wh === undefined) {
      for (let wbo of this.cityColl) {
        if (wbo.isdefault) {
          localStorage.setItem('wh', wbo.cityid);
        }
      }
    }
    this.fnGetCustomerArea();
  }

  fnGetCustomerArea() {
    if (localStorage.wh !== undefined) {
      let a = localStorage.getItem('wh');
      this.locationid = Number(a);

      for (let wbo of this.cityColl) {
        if (wbo.cityid === this.locationid) {
          this.locationname = wbo.cityname + "-" + wbo.pincode;
        }
      }
    }
    else {
      if (this.cityColl.length > 0) {      
        this.locationid = Number(this.cityColl[0].cityid);
      }
   
      localStorage.setItem('wh', this.locationid.toString());

      for (let wbo of this.cityColl) {
        if (wbo.cityid === this.locationid) {
          this.locationname = wbo.cityname + "-" + wbo.pincode;
        }
      }
    }

  }

  fnCheckLocation() {
    this.isselectaddress = false;
    let a;
    if (localStorage.wh !== undefined) {
      a = localStorage.getItem('wh');
    }

    this.locationshakeclass = "";
    this.locationstatus = "";

    if (Number(a) > 0) {
      this.isselectaddress = true;
      this.locationclass = "ldropdownhide";
    }
    else {
      this.isselectaddress = true;
      this.locationclass = "ldropdownhide";


      // this.locationclass="ldropdownshow";    
      // this.locationshakeclass="mapshake";
    }
  }


  //--DETECT
  public fnsetCurrentLocation() {
    this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          this.getAddress(latitude, longitude);
        });
      }

    });

  }

  getAddress(latitude, longitude) {
    if (latitude <= 0) {
      return;
    }
    if (longitude <= 0) {
      return;
    }

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          var res = results[0];
          this.currentlocationname = res.formatted_address
          //console.log(res);

          this.fnCheckAvaiableLocation(latitude, longitude);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  fnCheckAvaiableLocation(latitude, longitude) {
    if (latitude <= 0) {
      return;
    }
    if (longitude <= 0) {
      return;
    }


    var data = {
      'latitude': latitude,
      'longitude': longitude,
    }

    this.loginservice.fnGetLocationbyGeocode(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {

            var resut = res.json().result;

            this.locationstatus = "";

            if (resut.statustype === 'DONE') {
              this.locationid = resut.areainfo[0].areaid;
            }
            else {
              this.locationstatus = "Oh No! We are not yet in " + this.currentlocationname
            }

          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }
        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })


    // this.locationstatus="";
    // let _ischeck:boolean=false

    // for(var _abo of this.areaColl){    

    //   if(_abo.latitude>parseFloat(latitude.toString()).toFixed(4) && 
    //     _abo.longitude<parseFloat(longitude.toString()).toFixed(4)){               
    //       this.locationid=_abo.areaid;
    //       _ischeck=true;
    //   }
    // }  

    // if(_ischeck===false){
    //   this.locationstatus="Oh No! We are not yet in "+this.currentlocationname
    // }

  }

  //OPEN CLOSE
  fnCheckOpenCloseLocation() {
    let a;
    let ischeck: boolean = false
    if (localStorage.wh !== undefined) {
      a = localStorage.getItem('wh');
    }

    if (Number(a) > 0) {
      ischeck = true;
    }

    return ischeck;
  }



  //Signup login
  fnSignupLoginBtnClick(type) {
    const modalRef = this.modelService.open(LoginComponent, {
      size: 'lg', backdropClass: 'light-blue-backdrop', centered: true, backdrop: 'static',
      keyboard: false
    });
    if (type === "LOGIN") {
      modalRef.componentInstance.islogin = true;
    } else if (type === "SIGNUP") {
      modalRef.componentInstance.issignup = true;
    }

    modalRef.componentInstance.emitData.subscribe((loginEntry) => {

      this.fnGetLoginCustomer(loginEntry)

    });

  }
  fnGetLoginCustomer(data) {

    localStorage.setItem('token', data);
    this.loginservice.fnGetCustomerList();
    this.fnSetCartLoginCustomer();
    window.location.reload();


    // this.loginservice.fnGetCustomerLogin(data)
    // .subscribe(
    //   (res)=>{                  
    //   if(res.json().status==200){                            

    //    let _customerid=res.json().result[0].customerid;       
    //    localStorage.setItem('token', JSON.stringify(_customerid));
    //    this.loginservice.fnGetCustomerList();
    //     this.fnSetCartLoginCustomer();
    //   }
    //   else if(res.json().status==400){                          
    //     this.fnShowErrorMsg('Error'+res.json().err_field);
    //   }

    // },
    // (err)=>{          
    //   this.fnShowErrorMsg('Error'+ err);
    // })

  }
  fnSetCartLoginCustomer() {
    var data = {
      'customerid': 0,
      'sessionid': ''
    }
    this.loginservice.fnSetCartCustomerLogin(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.cartservice.fnGetCartList();
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })

  }
  //Logout
  fnLogoutBtnClick() {
    localStorage.removeItem('token');
    this.loginservice.fnGetCustomerList();
    this.router.navigate(['/']);
    window.location.reload();
  }
  //My Account
  fnMyAccountBtnClick(type) {
    if (type === "ACCOUNT") {
      this.router.navigate(['/myaccount/orders']);
    }
    else if (type === "ADDRESSES") {
      this.router.navigate(['/myaccount/address']);
    }
    else if (type === "ORDER") {
      this.router.navigate(['/myaccount/orders']);
    }
    else if (type === "FAVOURITES") {
      this.router.navigate(['/myaccount/favourites']);
    }
    else if (type === "WALLET") {
      this.router.navigate(['/myaccount/wallet']);
    }
    else if (type === "REFER") {
      this.router.navigate(['/myaccount/refer']);
    }

  }

  fnShopByCategoryShow() {

    this.fnDepartmentOnHover(0);
    this.deptColl[0].clazz = "wsshoplink-active"
  }

  //Customer Alert Notification
  fnGetCustomerAlertNotification() {
    var data = {
      'customerid': 0,
      'sessionid': ''
    }
    this.loginservice.fnGetCustomerAlertDetails(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.customeralertBO = res.json().result[0];
            console.log(this.customeralertBO)
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }

        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })
  }


  //Slider

  fnOpenSlider() {
    this.cartservice.fnCheckSlide()
  }


  private fnRemoveSpecialCharater(str) {
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "-");
    return str;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex);
  }

}
