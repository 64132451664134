import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};

  customerBO:any={};  
  customerWishlistColl:any=[];     
  customerView:any={};

  constructor(private loginservice: LoginService,private cartservice:CartService,public router: Router) { 
    this.fnServiceChanges();
  }

  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value      
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain;                          
        }      
        // if(this.customerView.customerwishlist){                
        //   this.customerWishlistColl= this.customerView.customerwishlist                
        // }      
       
      
    }); 


    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }

      this.fnUpdateCartChange();
    });    


  }
  
  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
      this.fnGetWishlist();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
   
  }


  fnUpdateCartChange(){

  
   for(let wbo of this.customerWishlistColl){
    const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
    if(index>-1){
      wbo.qty= this.cartColl[index].qty;
    }else{
      wbo.qty=0;
    }    
  }  
   
  
  }

   
  fnGetWishlist(){  
    var data = {  
                
    }

  
    this.loginservice.fnGetCustomerWishlist(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                        
              this.customerWishlistColl=res.json().result;  
              
                                
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fnAddProductWishlistBtnClick(prodBO){
    
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':1      
    }
  
    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
  
          const index = this.customerWishlistColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.customerWishlistColl[index].qty=1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  
  }
  
  fnProductIncrementWishlistBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty+1     
    }
  
    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
  
          const index = this.customerWishlistColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.customerWishlistColl[index].qty=prodBO.qty+1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }
  
  fnProductDecrementWishlistBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty-1     
    }
  
    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
  
          const index = this.customerWishlistColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.customerWishlistColl[index].qty=prodBO.qty-1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  
  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }
  
  
}
