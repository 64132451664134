import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/services/login.service';
import { VirtualTimeScheduler } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('ngOtpInput',{static:false}) ngOtpInputRef:any;
  @Output() emitData = new EventEmitter();
  
  settingsColl:any=[];

  countrycode:string="+971";
  mobilecode:any=[{value:50,displaytxt:50}];

  //Mode Values
  @Input() issignup:boolean=false;
  @Input() islogin:boolean=false;

  //Save Validation
  signupsubmitted:boolean;
  signupsuccessfully:boolean;
  loginsubmitted:boolean;
  loginsuccessfully:boolean;


  constructor(public activeModal: NgbActiveModal,public loginservice: LoginService,public homeservice:HomeService) { }

  
  signupform=new FormGroup({
    firstname:new FormControl('',Validators.required),         
    lastname:new FormControl('',Validators.required),
    email:new FormControl('',[Validators.required, Validators.email]), 
    password:new FormControl(null,Validators.required),                  
  });

  loginform=new FormGroup({
    email:new FormControl(null,[Validators.required, Validators.email]),
    password:new FormControl(null,Validators.required),                
  });

    
  ngOnInit() {
    try{

      this.fnGetSettingsByType();

      this.signupform.reset();
      this.fnInitializeSignupFormGroup(); 

      this.loginform.reset();
      this.fnInitializeLoginFormGroup();
      
            
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
  }

  
  fnInitializeSignupFormGroup(){
    this.signupform.setValue({
      firstname:'',      
      lastname:'',      
      email:'',      
      password:'',       
    })

  }

    
  fnInitializeLoginFormGroup(){
    this.loginform.setValue({    
      email:'',     
      password:'',  
    })
  }

  fnChangeType(type){
    if(type==="LOGIN"){
      this.issignup=false;
      this.islogin=true;
      this.loginsubmitted=false;
      this.loginsuccessfully=false;
     
      this.loginform.reset();
      this.fnInitializeLoginFormGroup();
    }
    else if(type==="SIGNUP"){
      this.issignup=true;
      this.islogin=false;
      this.signupsubmitted=false;
      this.signupsuccessfully=false;
      
      this.signupform.reset();
      this.fnInitializeSignupFormGroup(); 
    }
  }

  
  fnGetSettingsByType() { 
    // var data = {   
    //   'settingstype':'LOGIN'            
    // }
    // this.homeservice.fnGetSettingsByType(data)
    //     .subscribe(
    //       (res)=>{                  
    //         if(res.json().status==200){                          
    //           this.settingsColl=res.json().result;           
              
    //           const index = this.settingsColl.findIndex(a => a.settingsname ==='COUNTRYCODE' );
    //           if(index>-1){
    //             this.countrycode=this.settingsColl[index].settingsvalue;
    //           }

    //           const index1 = this.settingsColl.findIndex(a => a.settingsname ==='MOBILECODE' );
    //           if(index1>-1){
    //             let mcode= this.settingsColl[index1].settingsvalue.split(",");
                
    //             this.mobilecode=[];
    //             if(mcode.length){
    //               for(let wbo of mcode){
    //                 this.mobilecode.push({value:wbo,displaytxt:wbo});
    //               }
    //             }
                
    //           }


    //           this.signupform.reset();
    //           this.fnInitializeSignupFormGroup(); 
        
    //           this.loginform.reset();
    //           this.fnInitializeLoginFormGroup();
              

    //         }
    //         else if(res.json().status==400){                          
    //           this.fnShowErrorMsg('Error'+res.json().err_field);
    //         }
            
    //       },
    //       (err)=>{          
    //         this.fnShowErrorMsg('Error'+ err);
    //       })
  }


  fnSignupBtnClick(){
    try{      
      this.signupsubmitted=true;
      this.signupsuccessfully=false;
      //Validate
      if(this.signupform.valid){
       
        
        var data = {
          'firstname':this.signupform.get('firstname').value,               
          'lastname':this.signupform.get('lastname').value,                            
          'email':this.signupform.get('email').value,     
          'password':this.signupform.get('password').value,              
       }
       this.loginservice.fnCheckCustomerSignup(data)
          .subscribe(
            (res)=>{              
            console.log(res.json())  
            if(res.json().status==200){    
              
           
              
               var _checkresult=res.json().result.checkstatus;
  
               if(_checkresult==="done"){
                this.fnInsertCustomerSignUp(data);
               } 
               else{
                if(_checkresult==="password"){
                  this.signupform.controls['password'].setErrors({'notfound': true});
                }
                else if(_checkresult==="email"){
                  this.signupform.controls['email'].setErrors({'notfound': true});
                }

                 
               }
            }
            else if(res.json().status==400){                          
              //this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })



      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnLoginBtnClick(){
    try{      
      this.loginsubmitted=true;
      this.loginsuccessfully=false;
      //Validate
      if(this.loginform.valid){
       
        var data = {             
          'email':this.loginform.get('email').value,     
          'password':this.loginform.get('password').value,                      
       }
       this.loginservice.fnCheckCustomerLogin(data)
          .subscribe(
            (res)=>{                  
             
            if(res.json().status==200){                
              
              var _checkresult=res.json().result.checkstatus;

              if (_checkresult === 'DONE') {
                this.fnGetCustomerLogin(data);

                // var _otp=res.json().result.otp;              
                // var _cusinfo=res.json().result.customerdata;
               
                // var logindata = {           
                //   'firstname':_cusinfo.firstname,  
                //   'lastname':_cusinfo.lastname,  
                //   'email':_cusinfo.email,                  
                //   'mobile':_cusinfo.mobile,         
                //   'otp':""
                // }
                // this.otpverifytype="LOGIN";
                // this.fnSendOTP(logindata,_otp);
              } 
              else if (_checkresult === 'EMAIL') {
                alert('Emailid Incorrect')
              }
              else if (_checkresult === 'PASSWORD') {
                alert('Password Incorrect')
              }
              else if (_checkresult === 'FAILED') {

                this.fnChangeType("SIGNUP"); 
                this.signupform.patchValue({
                  firstname:'',      
                  lastname: '',      
                  email:this.loginform.get('email').value,        
                  password:'',                                        
                })                
              }
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })



      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

 
  //SignUp Final
  fnInsertCustomerSignUp(data){
  //Validate


       this.loginservice.fnInsertCustomerSignup(data)
          .subscribe(
            (res) => {                  
            if(res.json().status==200){ 
             
              let _checkstatus= res.json().result.checkstatus;
              if(_checkstatus=="done"){

                let _customerid=res.json().result.customerid
                this.emitData.emit(_customerid);  
                this.activeModal.close(true);

              }else{
                alert(_checkstatus)
              }
              
              // console.log(res.json());
              // let _customerid=res.json().result[0].customerid;
              //  var _req={
              //    customerid:_customerid,     
              //    mobile:this.sendotpdata.mobile
              //  }
          
                
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
      
  }

  //Login Final
  fnGetCustomerLogin(data){
   

      this.loginservice.fnGetCustomerLogin(data)
      .subscribe(
        (res)=>{                  
        if(res.json().status==200){ 
          let _checkstatus= res.json().result.checkstatus;
          if(_checkstatus=="done"){

            let _customerid=res.json().result.customerid
            this.emitData.emit(_customerid);  
            this.activeModal.close(true);

          }else{
            alert(_checkstatus)
          }
                   
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }
       
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
;  
    
  }

  fnGetOTPContlValue(data){
    let _result="";
    
    if(data.ctrl_0){
      _result=_result+data.ctrl_0;
    }
    if(data.ctrl_1){
      _result=_result+data.ctrl_1;
    }
    if(data.ctrl_2){
      _result=_result+data.ctrl_2;
    }
    if(data.ctrl_3){
      _result=_result+data.ctrl_3;
    }
    if(data.ctrl_4){
      _result=_result+data.ctrl_4;
    }
    if(data.ctrl_5){
      _result=_result+data.ctrl_5;
    }
    
    return _result;
  }

  private  fnShowErrorMsg(ex){
    console.log('Internal Error', ex);       
  }

   
}
