import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offeritemslider',
  templateUrl: './offeritemslider.component.html',
  styleUrls: ['./offeritemslider.component.css']
})
export class OfferitemsliderComponent implements OnInit {

  checkname:string="";
  OfferItemsColl:any=[];

  cartBO:any={};
  cartColl:any=[];
  cartView:any={};

  constructor(public cartservice: CartService,public loginservice: LoginService,
            public router: Router) { 

    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }
      this.fnUpdateCartChange();
    });    
                  
    this.cartservice.serviceofferitemChange.subscribe((value) => {
     this.fnGetOfferItemsDetails(value);    
    }); 

 }
  ngOnInit() {
  }

  
 fnGetOfferItemsDetails(itemsColl){  
    this.OfferItemsColl=itemsColl;
    this.fnUpdateCartChange();
  }

  fnAddProductBtnClick(itemBO){
    
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': itemBO.productid, 
      'skuid':itemBO.skuid,
      'qty':1      
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })

}



  fnProductIncrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty+1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  fnProductDecrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty-1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  
  fnProductDetailsBtnClick(index) {
  

    var _productidid=this.OfferItemsColl[index].skuid;    
    var _productidname=this.OfferItemsColl[index].productname;
    _productidname= this.fnRemoveSpecialCharater(_productidname);
    var _para=_productidid+"-"+_productidname;

    this.router.navigate(['/p/'+_para]);
    this.cartservice.fnCheckSlideOfferItems([]);
  }


  
  fnUpdateCartChange(){
    for(let wbo of this.OfferItemsColl){
      const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
      if(index>-1){
        wbo.qty= this.cartColl[index].qty;
      }else{
        wbo.qty=0;
      }
      
    }  
   

  }

  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "-");
    return str;
  }
  


  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }


}
