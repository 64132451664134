import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customerservice',
  templateUrl: './customerservice.component.html',
  styleUrls: ['./customerservice.component.css']
})
export class CustomerserviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
