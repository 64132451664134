import { BrowserModule } from '@angular/platform-browser';
import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


//map
import { AgmCoreModule } from '@agm/core';
//Carousel
import { CarouselModule } from 'ngx-owl-carousel-o';
//Range Slider
import { Ng5SliderModule } from 'ng5-slider';
//Sidebar
import { SidebarModule } from 'ng-sidebar';
//Model
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//Select drop down
import { NgSelectModule } from '@ng-select/ng-select';
//Shimmer
import { ContentLoaderModule } from '@ngneat/content-loader';
//Angular Expand
import {MatExpansionModule} from '@angular/material/expansion';
//Search Filter Pipe
import { Ng2SearchPipeModule } from 'ng2-search-filter';
//Zoom Image
import {ImageZoomModule} from 'angular2-image-zoom';
import { NgxImageZoomModule } from 'ngx-image-zoom';
//Rating Bar
import { BarRatingModule } from 'ngx-bar-rating'
//Mat Icon
import {MatButtonModule} from '@angular/material/button';
//Infinity Scoll 
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
//OTP
import { NgOtpInputModule } from  'ng-otp-input';
//Date Pikker
import { BsDatepickerModule  } from 'ngx-bootstrap';
//Card Payment
import { NgPaymentCardModule } from 'ng-payment-card';
// //Toast Service
// import { ToastrModule } from 'ngx-toastr';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ItemlistComponent } from './components/itemlist/itemlist.component';
import { ItemcartComponent } from './components/itemcart/itemcart.component';
import { CartsliderComponent } from './components/cartslider/cartslider.component';
import { LoginComponent } from './components/login/login.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CustomeraddressComponent } from './components/customeraddress/customeraddress.component';
import { OrdersComponent } from './components/account/orders/orders.component';
import { OrdersdetailsComponent } from './components/account/ordersdetails/ordersdetails.component';
import { AddressComponent } from './components/account/address/address.component';
import { OffersComponent } from './components/offers/offers.component';
import { DealsComponent } from './components/deals/deals.component';
import { ItemdetailsComponent } from './components/itemdetails/itemdetails.component';
import { SmartbasketComponent } from './components/smartbasket/smartbasket.component';
import { AboutComponent } from './components/others/about/about.component';
import { ContactComponent } from './components/others/contact/contact.component';
import { TermsComponent } from './components/others/terms/terms.component';
import { PrivacypolicyComponent } from './components/others/privacypolicy/privacypolicy.component';
import { FaqComponent } from './components/others/faq/faq.component';
import { ReturnpolicyComponent } from './components/others/returnpolicy/returnpolicy.component';
import { CareersComponent } from './components/others/careers/careers.component';
import { SitemapComponent } from './components/others/sitemap/sitemap.component';
import { FilterPipePipe } from './filter-pipe.pipe';
import { ReferComponent } from './components/account/refer/refer.component';
import { WalletComponent } from './components/account/wallet/wallet.component';
import { GiftcardsComponent } from './components/account/giftcards/giftcards.component';
import { NotificationsComponent } from './components/account/notifications/notifications.component';
import { AlllistComponent } from './components/alllist/alllist.component';
import { NewarrivalsComponent } from './components/newarrivals/newarrivals.component';
import { AccountmenuComponent } from './components/account/accountmenu/accountmenu.component';
import { RewardsComponent } from './components/account/rewards/rewards.component';
import { ItembrandlistComponent } from './components/itembrandlist/itembrandlist.component';
import { CustomerserviceComponent } from './components/account/customerservice/customerservice.component';
import { NotfoundComponent } from './components/others/notfound/notfound.component';
import { MycurrencyPipePipe } from './mycurrency-pipe.pipe';
import { CmsComponent } from './components/others/cms/cms.component';
import { WishlistComponent } from './components/account/wishlist/wishlist.component';
import { DateFormatPipe } from './date-format.pipe';
import { HighlightDirective } from './highlight.directive';
import { ProfileComponent } from './components/account/profile/profile.component';
import { LocationStrategy,Location, HashLocationStrategy, DatePipe } from '@angular/common';
import { MobsearchComponent } from './components/account/mobsearch/mobsearch.component';
import { PageComponent } from './components/page/page.component';
import { OfferitemsliderComponent } from './components/offeritemslider/offeritemslider.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CouponsliderComponent } from './components/couponslider/couponslider.component';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { CouponComponent } from './components/account/coupon/coupon.component';
import { CartavailablityComponent } from './components/cartavailablity/cartavailablity.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { MobcartComponent } from './components/account/mobcart/mobcart.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ItemlistComponent,
    ItemcartComponent,
    CartsliderComponent,
    LoginComponent,
    CheckoutComponent,
    CustomeraddressComponent,
    OrdersComponent,
    OrdersdetailsComponent,
    AddressComponent,
    OffersComponent,
    DealsComponent,
    ItemdetailsComponent,
    SmartbasketComponent,
    AboutComponent,
    ContactComponent,
    TermsComponent,
    PrivacypolicyComponent,

    FaqComponent,
    ReturnpolicyComponent,
    CareersComponent,
    SitemapComponent,
    FilterPipePipe,
    ReferComponent,
    WalletComponent,
    GiftcardsComponent,
    NotificationsComponent,
    AlllistComponent,
    NewarrivalsComponent,
    AccountmenuComponent,
    RewardsComponent,
    ItembrandlistComponent,
    CustomerserviceComponent,
    NotfoundComponent,
    MycurrencyPipePipe,
    CmsComponent,
    WishlistComponent,
    DateFormatPipe,
    HighlightDirective,
    ProfileComponent,
    MobsearchComponent,
    PageComponent,
    OfferitemsliderComponent,
    LoadingComponent,
    CouponsliderComponent,
    DatetimeFormatPipe,
    CouponComponent,
    CartavailablityComponent,
    ConfirmationDialogComponent,
    SanitizeHtmlPipe,
    MobcartComponent,
  ],
  imports: [
    BrowserModule,
    Ng5SliderModule,
    AppRoutingModule,    
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,    
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,   
    SidebarModule.forRoot(),
    NgbModule, 
    NgSelectModule, 
    ContentLoaderModule,
    MatExpansionModule,
    Ng2SearchPipeModule,
    ImageZoomModule,
    NgxImageZoomModule.forRoot(),
    BarRatingModule,
    MatButtonModule,
    InfiniteScrollModule,  
    NgOtpInputModule,
    NgPaymentCardModule,
    BsDatepickerModule.forRoot(), 
    AgmCoreModule.forRoot({
      //apiKey: "AIzaSyClUICP4-qGf2r4SSCZF5MzbSFXT6mIbvM",
      apiKey: "AIzaSyCUreeeYOumHHYh90WbM2kxNrfyYfGn3V0",
      libraries: ["places"] 
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [ DatePipe, Location,{ provide: LocationStrategy, useClass: HashLocationStrategy },],
  bootstrap: [AppComponent],
  entryComponents: [ItemcartComponent,CartsliderComponent,LoginComponent,
    CustomeraddressComponent,OrdersdetailsComponent,CartavailablityComponent,ConfirmationDialogComponent]
})
export class AppModule {
  constructor() {
    if (localStorage.session === undefined || localStorage.session === '' || localStorage.session === null) {
        this.randomkey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        localStorage.setItem('session', this.randomkey)
    }

  
  }
    randomkey;
 }
