import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-giftcards',
  templateUrl: './giftcards.component.html',
  styleUrls: ['./giftcards.component.css']
})
export class GiftcardsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
