import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { DatePipe } from '@angular/common';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  settingsColl:any=[];

  countrycode:string="+971";
  mobilecode:any=[{value:50,displaytxt:50}];

  customerBO:any={};   
  customerOrderMainColl:any=[];
  customerOrderDetailsColl:any=[];
  customerView:any={};

  commlanguageColl:any=[];


  customersubmitted:boolean;
  customersuccessfully:boolean;
    
  customerform=new FormGroup({
    firstname:new FormControl('',Validators.required),         
    lastname:new FormControl('',Validators.required),
    email:new FormControl('',[Validators.required,, Validators.email]), 
    mobile:new FormControl(null),               
    prefixmobile:new FormControl(''),
    commlanguageid:new FormControl(''),
    dob:new FormControl(''),
    gender:new FormControl(''),
  });
  isedit:boolean=false;

  constructor(public loginservice: LoginService,private datePipe: DatePipe,public homeservice:HomeService) {
    this.fnServiceChanges();
  }

    
  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                
        }      
        if(this.customerView.ordermain){                
          this.customerOrderMainColl= this.customerView.ordermain   
         
        }
       
        if(this.customerView.orderdetails){                
          this.customerOrderDetailsColl= this.customerView.orderdetails   
        }

        this.fnGetSettingsByType();

        this.customerform.setValue({
          firstname: this.customerBO.firstname,      
          lastname:this.customerBO.lastname,      
          email:this.customerBO.email,
          mobile:this.customerBO.altmobile,    
          commlanguageid:this.customerBO.commlanguageid,
          dob: this.fnConvertDatetime(this.fnFormatDatetime(this.customerBO.dob)),
          gender:this.customerBO.gender,
          prefixmobile:this.customerBO.altmobile, 
        })


    }); 
  }

  ngOnInit() {
    try{
      this.fnGetSettingsByType();

      this.loginservice.fnGetCustomerList();
      this.fnGetCommunicationLanguage();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  
    
fnInitializeCustomerFormGroup(){
  this.customerform.setValue({
    firstname:'',      
    lastname:'',      
    email:'',      
    mobile:'',
    commlanguageid:null,  
    dob:'', 
    gender:'',     
    prefixmobile:this.fnGetDefaultMobileCode(),  
  })
}



  
fnGetSettingsByType() { 
  var data = {   
    'settingstype':'LOGIN'            
  }
  this.homeservice.fnGetSettingsByType(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.settingsColl=res.json().result;           
            
            const index = this.settingsColl.findIndex(a => a.settingsname ==='COUNTRYCODE' );
            if(index>-1){
              this.countrycode=this.settingsColl[index].settingsvalue;
            }

            const index1 = this.settingsColl.findIndex(a => a.settingsname ==='MOBILECODE' );
            if(index1>-1){
              let mcode= this.settingsColl[index1].settingsvalue.split(",");
              
              this.mobilecode=[];
              if(mcode.length){
                for(let wbo of mcode){
                  this.mobilecode.push({value:wbo,displaytxt:wbo});
                }
              }
              
            }



          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }
          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
}

fnGetDefaultMobileCode(){

  if(this.mobilecode.length){
    let a=this.mobilecode[0].value;     
    return a;
  }else{
    return "";
  }
}




fnSetCustomerBtnClick(){
  try{      
    this.customersubmitted=true;
    this.customersuccessfully=false;
    //Validate
    if(this.customerform.valid){

      let mobile=this.customerform.get('prefixmobile').value + this.customerform.get('mobile').value;
      var data = {
        'firstname':this.customerform.get('firstname').value,               
        'lastname':this.customerform.get('lastname').value,               
        'altmobile':mobile,               
        'email':this.customerform.get('email').value,  
        'commlanguageid':this.customerform.get('commlanguageid').value,  
        'dob':this.fnFormatDatetime(this.customerform.get('dob').value),  
        'gender':this.customerform.get('gender').value,     
        'otp':''            
     }
     this.loginservice.fnSetCustomer(data)
        .subscribe(
          (res)=>{                               
          if(res.json().status==200){    
            this.isedit=false;
            this.loginservice.fnGetCustomerList();
            
          }
          else if(res.json().status==400){                          
            //this.fnShowErrorMsg('Error'+res.json().err_field);
          }         
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
    }
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}


fnGetCommunicationLanguage(){
  try{          
      var data = {
                  
     }
     this.loginservice.fnGetCommunicationLanguage(data)
        .subscribe(
          (res)=>{                               
          if(res.json().status==200){    
            this.commlanguageColl=res.json().result;
            
          }
          else if(res.json().status==400){                          
            //this.fnShowErrorMsg('Error'+res.json().err_field);
          }         
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
    
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}


fnchangeEditMode(){
  this.isedit=!this.isedit;

  this.fnInitializeCustomerFormGroup();

  this.loginservice.fnGetCustomerList();
  

  // if(this.customerBO){
  //   this.customerform.setValue({
  //     firstname: this.customerBO.firstname,      
  //     lastname:this.customerBO.lastname,      
  //     email:this.customerBO.email,
  //     mobile:this.customerBO.altmobile,
  //     commlanguageid:this.customerBO.commlanguageid,
  //     dob:this.customerBO.dob,
  //     gender:this.customerBO.gender,
  //   })
  // }
  
}

fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}



private  fnShowErrorMsg(ex){
  console.log('Internal Error', ex);       
}


}
