import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css']
})
export class ReferComponent implements OnInit {
  customerBO:any={};   
  customerView:any={};

  constructor(private loginservice: LoginService,private cartservice:CartService,
    private modelService: NgbModal,public router: Router) { 
    this.fnServiceChanges();
  }

  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                
        }              
    }); 
  }
  

  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }
  
}
