import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { Router } from '@angular/router';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { CartService } from 'src/app/services/cart.service';
import { CheckoutService } from 'src/app/services/checkout.service';
declare var $ :any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,AfterViewInit {
  
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};
    
  mainbannerColl:any=[];
  dealofthedayColl:any=[];
  pageColl:any=[];
  pageid:number=0;

 
 
  sliderOptions: OwlOptions= {};
  productOptions: OwlOptions= {};
  
  activeSlides: SlidesOutputData;
  activeSlidesindex:number=0;


  @ViewChild('owlBanner',{static:true}) sliderElement: ElementRef;

  ismainbannerload:boolean=false;
  isload:boolean=false;

  constructor(private homeservice: HomeService,private cartservice:CartService,private checkoutservice: CheckoutService,
    public router: Router) {
      this.fnServiceChanges();
      checkoutservice.ischeckoutloaded=false;
  }

  fnServiceChanges(){
    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }
      this.fnUpdateCartChange();
    });    
    
  }
  
  ngOnInit() {
    try{
      this.fnSetCaroselOption();          
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  ngAfterViewInit() {
    try{
      this.fngetMainBanner();    
      //this.fngetDashboard();  
      this.fnGetDealoftheday();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  fngetMainBanner() { 
     var data = {
          'id': 0,            
    }
    this.homeservice.fnGetMainBanner(data)
        .subscribe(
          (res)=>{                  
            this.fnHideMBProcess();
            if(res.json().status==200){  
                           
              if(res.json().result){
                if(res.json().result.mainbanner){
                  this.mainbannerColl=res.json().result.mainbanner;      
                }

                if(res.json().result.dashboardpage){
                  this.pageid=res.json().result.dashboardpage.pageid;
                }
                
                this.fngetPageList();
                
              
              }            
              

            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fnGetDealoftheday(){
  var data = {
    'id': 0,            
  }
  this.homeservice.fnGetDealoftheday(data)
    .subscribe(
      (res)=>{                  
      
        if(res.json().status==200){   
          this.fnHideProcess();                       
          this.dealofthedayColl=res.json().result;      
        
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }
      
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }
   
  fnSetCaroselOption(){
    this.sliderOptions= { 
      rtl:this.fnGetRightAlignCarosel(),
      loop: true, 
      dots: false,   
      autoplay: true,
      mouseDrag: true,
      touchDrag: true, 
      pullDrag: true,
      navSpeed: 700,
      lazyLoad: true,
      nav: false,
      items:1,
      navText: ['<','>'],    
      responsive:{ 0:{ items:1 }, 600:{ items:1 }, 1000:{ items:1 } },
    };
    
    // deal of the day
    this.productOptions= { 
      rtl:this.fnGetRightAlignCarosel(),
      loop: false, 
      margin:1,
      dots: false,   
      autoplay: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      navSpeed: 700,
      lazyLoad: true,
      nav: true,
      navText:['<img src="assets/images/prev.png"/>', '<img src="assets/images/next.png"/>'], 
      items:5,      
      responsive:{ 0:{ items:2 }, 560:{ items:3}, 820:{ items:4}, 1000:{ items:5 }, 1280:{ items:5 }  } };
     
   

  }

  fnMainBannerCarouselChange(data: SlidesOutputData){
  this.activeSlides = data;
    //console.log(this.activeSlides.startPosition);   
   this.activeSlidesindex=this.activeSlides.startPosition;

 
    if(this.activeSlidesindex>-1){
      let bannerid =this.mainbannerColl[this.activeSlidesindex].bannerid;

      for(let wbo of this.mainbannerColl){
          if(wbo.bannerid===bannerid){
            wbo.nxclass="active";
          }else{
            wbo.nxclass="";
          }
      }

    }
    
  }

  fnMainBannerCarouselChangeClick(index){
  
   this.activeSlidesindex=index;
 
    if(this.activeSlidesindex>-1){
      let bannerid =this.mainbannerColl[this.activeSlidesindex].bannerid;

      for(let wbo of this.mainbannerColl){
          if(wbo.bannerid===bannerid){
            wbo.nxclass="active";
          }else{
            wbo.nxclass="";
          }
      }

    }
    
  }

  fnGetSelectedMainBanner(mi){
    return this.mainbannerColl[mi].nxclass;
  }

  //GetPage
  fngetPageList() { 
    
    var data = {
      'pageid':this.pageid,            
    }

    this.homeservice.fnGetPage(data)
      .subscribe(
        (res)=>{          
          
          if(res.json().status==200){    
          
            let _pageColl=res.json().result.pageinfo;         
            let _pagelayout=res.json().result.pagelayout; 
            let _pagedetails=res.json().result.pagedetails; 

            this.pageColl=[];

            if (_pageColl.length===1){
              for(let pbo of _pagelayout){
                var pagedetails = []
                for(let mbo of _pagedetails){
                  if(mbo.pagelayoutid===pbo.pagelayoutid){
                    pagedetails.push(mbo)
                  }
                }            
                pbo.pagedetails=pagedetails;  
                this.pageColl.push(pbo);
            }

            }
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{                  
          this.fnShowErrorMsg('Error'+ err);
        })
  }

  fnPageBannerHyperlinkClick(index,di){
      if(index<0){
        return;
      }
      if(di<0){
        return;
      }

      let linktype='';
      let linktargetid='';
    
      linktype=this.pageColl[index].pagedetails[di].linktype;
      linktargetid=this.pageColl[index].pagedetails[di].linktargetid;

    
      if (linktype===null){
        return;
      }
      if (linktargetid ===null){
        return;
      }
      
      //Get Url Details In Id...
      var data = {
        'linktype':linktype,            
        'linktargetid': linktargetid,                    
      }
    
    
    this.homeservice.fnGetTargetDetails(data)
        .subscribe(
          (res)=>{                  
          
            if(res.json().status==200){  
                                    
              var result=res.json().result[0];
            
              let _deptid=result.deptid;
              let _categoryid=result.categoryid;
              let _subcategoryid=result.subcategoryid;
              let _brandid=result.brandid;
              let _skuid=result.skuid;

              let _deptname=result.deptname;
              let _categoryname=result.categoryname;
              let _subcategoryname=result.subcategoryname;
              let _brandname=result.brandname;
              let _productname=result.productname;
              let _vendorname=result.vendorname;
              let _pagename=result.pagename;


              let _deptseoname=result.deptseoname
              let _categoryseoname=result.categoryseoname;
              let _subcategoryseoname=result.subcategoryseoname;
              let _brandseoname=result.brandseoname;

              if(linktype==='DEPARTMENT'){
                _deptname= this.fnRemoveSpecialCharater(_deptname);
                var _deptpara=_deptid+"-"+_deptname;        
                if (_deptseoname){
                  _deptpara=_deptseoname;
                }             
                this.router.navigate(['/'+_deptpara]);    
              }
              else if(linktype==='CATEGORY'){

                _deptname= this.fnRemoveSpecialCharater(_deptname);
                var _deptpara=_deptid+"-"+_deptname;        
                if (_deptseoname){
                  _deptpara=_deptseoname;
                }

                _categoryname= this.fnRemoveSpecialCharater(_categoryname);
                var _catepara=_categoryid+"-"+_categoryname;        
                if (_categoryseoname){
                  _catepara=_categoryseoname;
                }             
                this.router.navigate(['/'+_deptpara+"/"+_catepara]);    
              }
              else if(linktype==='SUBCATEGORY'){
                _deptname= this.fnRemoveSpecialCharater(_deptname);
                var _deptpara=_deptid+"-"+_deptname;        
                if (_deptseoname){
                  _deptpara=_deptseoname;
                }

                _categoryname= this.fnRemoveSpecialCharater(_categoryname);
                var _catepara=_categoryid+"-"+_categoryname;        
                if (_categoryseoname){
                  _catepara=_categoryseoname;
                }         


                _subcategoryname= this.fnRemoveSpecialCharater(_subcategoryname);
                var _subcatepara=_subcategoryid+"-"+_subcategoryname;        
                if (_subcategoryseoname){
                  _subcatepara=_subcategoryseoname;
                }             
                this.router.navigate(['/'+_deptpara+"/"+_catepara+"/"+_subcatepara]);                  
              }
              else if(linktype==='BRAND'){
                _brandname= this.fnRemoveSpecialCharater(_brandname);
                var _brandpara=_brandid+"-"+_brandname;        
                if (_brandseoname){
                  _brandpara=_brandseoname;
                }             
                this.router.navigate(['/'+_brandpara]);    
              }
              else if(linktype==='PRODUCT'){
                  _productname= this.fnRemoveSpecialCharater(_productname);
                  var _productpara=_skuid+"-"+_productname;        
                      
                  this.router.navigate(['/p/'+_productpara]);    
              }
              else if(linktype==='VENDOR'){
                  var _vendorpara= this.fnRemoveSpecialCharater(_vendorname);

                  this.router.navigate(['/'+_vendorpara]);    
             }
             else if(linktype==='PAGE'){
                  this.router.navigate(['/'+_pagename]);    
             }                              
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
    })


  }

  fnPageBannerHeaderHyperlinkClick(linktype,linktargetid){
 

  if (linktype===null){
    return;
  }
  if (linktargetid ===null){
    return;
  }
  
   //Get Url Details In Id...
   var data = {
    'linktype':linktype,            
    'linktargetid': linktargetid,                    
  }
 
 
this.homeservice.fnGetTargetDetails(data)
    .subscribe(
      (res)=>{                  
       
        if(res.json().status==200){  
                                
          var result=res.json().result[0];
         
          let _deptid=result.deptid;
          let _categoryid=result.categoryid;
          let _subcategoryid=result.subcategoryid;
          let _brandid=result.brandid;
          let _skuid=result.skuid;
          
          let _deptname=result.deptname;
          let _categoryname=result.categoryname;
          let _subcategoryname=result.subcategoryname;
          let _brandname=result.brandname;
          let _productname=result.productname;
          let _vendorname=result.vendorname;
          let _pagename=result.pagename;

          let _deptseoname=result.deptseoname
          let _categoryseoname=result.categoryseoname;
          let _subcategoryseoname=result.subcategoryseoname;
          let _brandseoname=result.brandseoname;

          if(linktype==='DEPARTMENT'){
            _deptname= this.fnRemoveSpecialCharater(_deptname);
            var _deptpara=_deptid+"-"+_deptname;        
            if (_deptseoname){
              _deptpara=_deptseoname;
            }             
            this.router.navigate(['/'+_deptpara]);    
          }
          else if(linktype==='CATEGORY'){

            _deptname= this.fnRemoveSpecialCharater(_deptname);
            var _deptpara=_deptid+"-"+_deptname;        
            if (_deptseoname){
              _deptpara=_deptseoname;
            }

            _categoryname= this.fnRemoveSpecialCharater(_categoryname);
            var _catepara=_categoryid+"-"+_categoryname;        
            if (_categoryseoname){
              _catepara=_categoryseoname;
            }             
            this.router.navigate(['/'+_deptpara+"/"+_catepara]);    
          }
          else if(linktype==='SUBCATEGORY'){
            _deptname= this.fnRemoveSpecialCharater(_deptname);
            var _deptpara=_deptid+"-"+_deptname;        
            if (_deptseoname){
              _deptpara=_deptseoname;
            }

            _categoryname= this.fnRemoveSpecialCharater(_categoryname);
            var _catepara=_categoryid+"-"+_categoryname;        
            if (_categoryseoname){
              _catepara=_categoryseoname;
            }         


            _subcategoryname= this.fnRemoveSpecialCharater(_subcategoryname);
            var _subcatepara=_subcategoryid+"-"+_subcategoryname;        
            if (_subcategoryseoname){
              _subcatepara=_subcategoryseoname;
            }             
            this.router.navigate(['/'+_deptpara+"/"+_catepara+"/"+_subcatepara]);    
          }
          else if(linktype==='BRAND'){
              _brandname= this.fnRemoveSpecialCharater(_brandname);
              var _brandpara=_brandid+"-"+_brandname;        
              if (_brandseoname){
                _brandpara=_brandseoname;
              }             
              this.router.navigate(['/'+_brandpara]);    
          }
          else if(linktype==='PRODUCT'){
            _productname= this.fnRemoveSpecialCharater(_productname);
            var _productpara=_skuid+"-"+_productname;        
                
            this.router.navigate(['/p/'+_productpara]);    
          }
          else if(linktype==='VENDOR'){
              var _vendorpara= this.fnRemoveSpecialCharater(_vendorname);

              this.router.navigate(['/'+_vendorpara]);    
          }
          else if(linktype==='PAGE'){
                this.router.navigate(['/'+_pagename]);    
          }  
            

                          
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
 })


  }


  //Update Cart Changes
  fnUpdateCartChange(){
    for(let wbo of this.dealofthedayColl){
      const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
      if(index>-1){
        wbo.qty= this.cartColl[index].qty;
      }else{
        wbo.qty=0;
      }    
    }  
 

    for(let wbo of this.pageColl){
      if(wbo.layouttype==="ITEMCAROSAL"){
        for(let pbo of wbo.pagedetails){
          const index = this.cartColl.findIndex(a => a.productid === pbo.productid && a.skuid === pbo.skuid );
          if(index>-1){
            pbo.qty= this.cartColl[index].qty;
          }else{
            pbo.qty=0;
          }
        }
      }
    }

  }

  //Product Adapter1
  fnAddProductA1BtnClick(prodBO){
      
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':1      
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.dealofthedayColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.dealofthedayColl[index].qty=1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })

  }

  fnProductIncrementA1BtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty+1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.dealofthedayColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.dealofthedayColl[index].qty=prodBO.qty+1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  fnProductDecrementA1BtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty-1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.dealofthedayColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.dealofthedayColl[index].qty=prodBO.qty-1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

 


  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "");
    return str;
  }

  private fnShowProcess(){
    this.isload=false;
  }
  private fnHideProcess(){
    this.isload=true;
  }

  
  private fnShowMBProcess(){
    this.ismainbannerload=false;
  }
  private fnHideMBProcess(){
    this.ismainbannerload=true;
  }


  
fnGetRightAlignCarosel(){
  let _ischeck=false;

  if (localStorage.lan === undefined) {
    _ischeck=false;
  }else{
    if (localStorage.lan === 'ar') {
      _ischeck=true;
    }else{
      _ischeck=false;
    }
  }
  return _ischeck;
}


  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }


}
