import { Component, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from 'src/app/services/home.service';
import { CartService } from 'src/app/services/cart.service';
import { LoginService } from 'src/app/services/login.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements  OnInit,AfterViewInit,OnDestroy {

  cartBO:any={};
  cartColl:any=[];
  cartView:any={};
  @Input() pageColl:any[]=[];
  @Input() productcount:number=5;
  //Settings Option
  productOptions: OwlOptions= {};
  banner1x1Options: OwlOptions= {};

  

constructor(private homeservice: HomeService,private cartservice: CartService,private loginservice: LoginService,private checkoutservice: CheckoutService,
  public router: Router,private cuurenctRoute:ActivatedRoute) {
    this.fnServiceChanges();
    checkoutservice.ischeckoutloaded=false;

   }

   
fnServiceChanges(){
  this.cartView = this.cartservice.cartView; 
  this.cartservice.servicecartChange.subscribe((value) => {
    this.cartView = value
    if(this.cartView.cartmain){
      this.cartBO= this.cartView.cartmain
    }
    if(this.cartView.cartdetails){
      this.cartColl= this.cartView.cartdetails
    }
    this.fnUpdateCartChange();
  });    


  this.loginservice.servicecustomerChange.subscribe((value) => {
    this.cartservice.fnGetCartList();  
  }); 

  
}
 

ngOnInit() {
    try{
      this.fnSetCaroselOption();
    
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
}

ngAfterViewInit() {
    try{
    
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
}

ngOnDestroy() {    
  
}


  
fnSetCaroselOption(){
  this.productOptions= { 
    rtl:this.fnGetRightAlignCarosel(),    
    loop: false, 
    margin:1,
    dots: false,   
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    lazyLoad: true,
    nav: true,
    navText:['<img src="assets/images/prev.png"/>', '<img src="assets/images/next.png"/>'],        
    items:this.productcount,      
    responsive:{ 0:{ items:2 }, 560:{ items:3}, 820:{ items:4}, 1000:{ items:4 }, 1280:{ items:this.productcount }  } };

    this.banner1x1Options= { 
      rtl:this.fnGetRightAlignCarosel(),
      loop: true, 
      dots: false,   
      autoplay: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      navSpeed: 700,
      lazyLoad: true,
      nav: true,
      navText:['<img src="assets/images/prev.png"/>', '<img src="assets/images/next.png"/>'],        
      items:1,         
      responsive:{ 0:{ items:1 }, 600:{ items:1 }, 1000:{ items:1 } } };
      
      
}


fnUpdateCartChange(){

    for(let wbo of this.pageColl){
      if(wbo.layouttype==="ITEMCAROSAL"){
        for(let pbo of wbo.pagedetails){
          const index = this.cartColl.findIndex(a => a.productid === pbo.productid && a.skuid === pbo.skuid );
          if(index>-1){
            pbo.qty= this.cartColl[index].qty;
          }else{
            pbo.qty=0;
          }
        }
      }
    }


}


fnPageBannerHyperlinkClick(index,di){
    if(index<0){
      return;
    }
    if(di<0){
      return;
    }

    let linktype='';
    let linktargetid='';
    let linktargetfilter='';

    linktype=this.pageColl[index].pagedetails[di].linktype;
    linktargetid=this.pageColl[index].pagedetails[di].linktargetid;
    linktargetfilter=this.pageColl[index].pagedetails[di].linktargetfilter;


    if (linktype===null){
      return;
    }
    // if (linktype!=='WEBURL' && linktype!=='WEBURL' && linktype!=='WEBURL' && linktype!=='WEBURL' && linktargetid ===null){
    //   return;
    // }

  this.fnPageBannerHeaderHyperlinkClick(linktype,linktargetid,linktargetfilter);



}


fnPageBannerHeaderHyperlinkClick(linktype,linktargetid,linktargetfilter){
 


  if (linktype===null){
    return;
  }


  if (linktype!=='WEBURL' && linktype!=='OFFERS' && linktype!=='NEWARRIVALS' && linktype!=='DEALOFTHEDAY' && linktargetid ===null){
    return;
  }


  
 //Get Url Details In Id...
 var data = {
  'linktype':linktype,            
  'linktargetid': linktargetid,                    
}


this.homeservice.fnGetTargetDetails(data)
  .subscribe(
    (res)=>{                  
     
      if(res.json().status==200){  
                              
        var result=res.json().result[0];
       
       

        let _deptid=result.deptid;
        let _categoryid=result.categoryid;
        let _subcategoryid=result.subcategoryid;
        let _brandid=result.brandid;
        let _skuid=result.skuid;

        let _deptname=result.deptname;
        let _categoryname=result.categoryname;
        let _subcategoryname=result.subcategoryname;
        let _brandname=result.brandname;
        let _productname=result.productname;
        let _vendorname=result.vendorname;
        let _offername=result.offername;
        let _pagename=result.pagename;
        let _contentname=result.contentname;


        let _deptseoname=result.deptseoname
        let _categoryseoname=result.categoryseoname;
        let _subcategoryseoname=result.subcategoryseoname;
        let _brandseoname=result.brandseoname;

        let _filter={}
        let newurllink="";

        if(linktargetfilter){         
          newurllink= linktargetfilter;
          _filter=this.fnReturnQueryParams(linktargetfilter);
          
        }
    

        if(linktype==='DEPARTMENT'){
          _deptname= this.fnRemoveSpecialCharater(_deptname);
          var _deptpara=_deptid+"-"+_deptname;        
          if (_deptseoname){
            _deptpara=_deptseoname;
          }             
          this.router.navigate(['/'+_deptpara],{ queryParams:_filter});    
        }
        else if(linktype==='CATEGORY'){

          _deptname= this.fnRemoveSpecialCharater(_deptname);
          var _deptpara=_deptid+"-"+_deptname;        
          if (_deptseoname){
            _deptpara=_deptseoname;
          }

          _categoryname= this.fnRemoveSpecialCharater(_categoryname);
          var _catepara=_categoryid+"-"+_categoryname;        
          if (_categoryseoname){
            _catepara=_categoryseoname;
          }             
          this.router.navigate(['/'+_deptpara+"/"+_catepara],{ queryParams:_filter});    
        }
        else if(linktype==='SUBCATEGORY'){
          _deptname= this.fnRemoveSpecialCharater(_deptname);
          var _deptpara=_deptid+"-"+_deptname;        
          if (_deptseoname){
            _deptpara=_deptseoname;
          }

          _categoryname= this.fnRemoveSpecialCharater(_categoryname);
          var _catepara=_categoryid+"-"+_categoryname;        
          if (_categoryseoname){
            _catepara=_categoryseoname;
          }         


          _subcategoryname= this.fnRemoveSpecialCharater(_subcategoryname);
          var _subcatepara=_subcategoryid+"-"+_subcategoryname;        
          if (_subcategoryseoname){
            _subcatepara=_subcategoryseoname;
          }             
          this.router.navigate(['/'+_deptpara+"/"+_catepara+"/"+_subcatepara],{ queryParams:_filter});                  
        }
        else if(linktype==='BRAND'){
          _brandname= this.fnRemoveSpecialCharater(_brandname);
          var _brandpara=_brandid+"-"+_brandname;        
          if (_brandseoname){
            _brandpara=_brandseoname;
          }             
          this.router.navigate(['/'+_brandpara],{ queryParams:_filter});    
        }
        else if(linktype==='PRODUCT'){
          _productname= this.fnRemoveSpecialCharater(_productname);
          var _productpara=_skuid+"-"+_productname;        
               
          this.router.navigate(['/p/'+_productpara],{ queryParams:_filter});    
        }
        else if(linktype==='VENDOR'){
          var _vendorpara= this.fnRemoveSpecialCharater(_vendorname);

          this.router.navigate(['/'+_vendorpara],{ queryParams:_filter});    
        }
        else if(linktype==='OFFER'){
          var _offerpara= this.fnRemoveSpecialCharater(_offername);

          this.router.navigate(['/'+_offerpara],{ queryParams:_filter});    
        }   
        else if(linktype==='PAGE'){
          this.router.navigate(['/'+_pagename],{ queryParams:_filter});    
        }
        else if(linktype==='HTML'){         
          this.router.navigate(['/c/'+_contentname]);
        }
        else if(linktype==='WEBURL'){                   
          window.open(newurllink, "_blank");      
        }
        else if(linktype==='OFFERS'){         
          this.router.navigate(['/OFFERS']);
        }
        else if(linktype==='NEWARRIVALS'){         
          this.router.navigate(['/NEWARRIVALS']);
        }
        else if(linktype==='DEALOFTHEDAY'){         
          this.router.navigate(['/DEALOFTHEDAY']);
        }
              
      
                        
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
})

}



//Product
fnAddProductBtnClick(prodBO){
  
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':1      
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })

}

fnProductIncrementBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty+1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

      
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnProductDecrementBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty-1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

       
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

private fnReturnQueryParams(inquery){
  if(!inquery){
    return {};
  }
 
  let _sortlist="";
  let  _brandlist="";
  let  _vendorlist="";
  let  _itemsearchlist="";
  let _minPrice=0;
  let _maxPrice=0;


  let splitquery=inquery.split("&");
  for(let str of splitquery){
    if(str.includes("sortby") ===true){
      _sortlist=str.substr(7,str.length-1)
    }
    else if(str.includes("brand")===true){     
      _brandlist=str.substr(6,str.length-1)
    
    }
    else if(str.includes("minprice") ===true){
      _minPrice=str.substr(9,str.length-1)
    }
    else if(str.includes("maxprice")===true){
      _maxPrice=str.substr(9,str.length-1)
    }
    else if(str.includes("q") ===true){
      _itemsearchlist=str.substr(2,str.length-1)
    }
    else if(str.includes("vendor") ===true){
      _vendorlist=str.substr(7,str.length-1)
    }
  }



  var obj = { };
  let data=['sortby', 'brand', 'minprice','maxprice','q','vendor'];
  
  for (let column of data){   
    if(column==='sortby'){
      if(_sortlist){
        obj[column] = _sortlist;
      }        
    }
    else if(column==='brand'){

      if(_brandlist){     
        obj[column] = _brandlist;
      }    
    }
    else if(column==='minprice'){
      if(_minPrice){
        obj[column] = _minPrice;
      }    
    }
    else if(column==='maxprice'){
      if(_maxPrice){
        obj[column] = _maxPrice;
      }       
    }
    else if(column==='q'){
      if(_itemsearchlist){
        obj[column] = _itemsearchlist;
      }    
    }
    else if(column==='vendor'){
      if(_vendorlist){
        obj[column] = _vendorlist;
      }    
    }
   
  
  }

  return obj;
}

fnGetRightAlignCarosel(){
  let _ischeck=false;

  if (localStorage.lan === undefined) {
    _ischeck=false;
  }else{
    if (localStorage.lan === 'ar') {
      _ischeck=true;
    }else{
      _ischeck=false;
    }
  }
  return _ischeck;
}

private fnRemoveSpecialCharater(str){
  str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  str = str.toString().replace(/ /g, "-");
  return str;
}

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }



}
