import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ItemlistComponent } from './components/itemlist/itemlist.component';
import { CartsliderComponent } from './components/cartslider/cartslider.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { OrdersComponent } from './components/account/orders/orders.component';
import { AddressComponent } from './components/account/address/address.component';
import { ItemdetailsComponent } from './components/itemdetails/itemdetails.component';
import { OffersComponent } from './components/offers/offers.component';
import { DealsComponent } from './components/deals/deals.component';
import { SmartbasketComponent } from './components/smartbasket/smartbasket.component';
import { AboutComponent } from './components/others/about/about.component';
import { ContactComponent } from './components/others/contact/contact.component';
import { TermsComponent } from './components/others/terms/terms.component';
import { PrivacypolicyComponent } from './components/others/privacypolicy/privacypolicy.component';
import { FaqComponent } from './components/others/faq/faq.component';
import { ReturnpolicyComponent } from './components/others/returnpolicy/returnpolicy.component';
import { CareersComponent } from './components/others/careers/careers.component';
import { SitemapComponent } from './components/others/sitemap/sitemap.component';
import { AlllistComponent } from './components/alllist/alllist.component';
import { NewarrivalsComponent } from './components/newarrivals/newarrivals.component';
import { WalletComponent } from './components/account/wallet/wallet.component';
import { GiftcardsComponent } from './components/account/giftcards/giftcards.component';
import { ReferComponent } from './components/account/refer/refer.component';
import { NotificationsComponent } from './components/account/notifications/notifications.component';
import { RewardsComponent } from './components/account/rewards/rewards.component';
import { ItembrandlistComponent } from './components/itembrandlist/itembrandlist.component';
import { CustomerserviceComponent } from './components/account/customerservice/customerservice.component';
import { NotfoundComponent } from './components/others/notfound/notfound.component';
import { CmsComponent } from './components/others/cms/cms.component';
import { WishlistComponent } from './components/account/wishlist/wishlist.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { MobsearchComponent } from './components/account/mobsearch/mobsearch.component';
import { CouponComponent } from './components/account/coupon/coupon.component';
import { MobcartComponent } from './components/account/mobcart/mobcart.component';




const routes: Routes = [
  {path:'', component:HomeComponent},  
  {path:'Home', component:HomeComponent},
  // {path:'items', component:ItemlistComponent},
  {path:'d/offers', component:OffersComponent},  
  {path:'d/dealoftheday', component:DealsComponent},    
  {path:'d/smartbasket', component:SmartbasketComponent},  
  {path:'d/newarrivals', component:NewarrivalsComponent}, 
  {path:'p/:productname', component:ItemdetailsComponent},  
  {path:'brands/:brandname', component:ItembrandlistComponent},
  {path:'checkout', component:CheckoutComponent},    
  {path:'contactus', component:ContactComponent},
  {path:'returnpolicy', component:ReturnpolicyComponent},       
  {path:'myaccount',children:[
    {path:'',component:OrdersComponent},
    {path:'profile',component:ProfileComponent},
    {path:'address',component:AddressComponent},
    {path:'favourites',component:WishlistComponent},
    {path:'customerservice', component:CustomerserviceComponent},
    {path:'giftcards', component:GiftcardsComponent},
    {path:'refer', component:ReferComponent},
    {path:'notifications', component:NotificationsComponent},
    {path:'rewards', component:RewardsComponent},
    {path:'notfound', component:NotfoundComponent},
    {path:'wallet', component:WalletComponent},
    {path:'search', component:MobsearchComponent},
    {path:'cart', component:MobcartComponent},
    {path:'orders', component:OrdersComponent},
    {path:'coupon', component:CouponComponent}
  ]},

  {path:'c/:cmsname', component:CmsComponent}, 
  {path:'all/:type', component:AlllistComponent},
  {path:':deptname', component:ItemlistComponent,  runGuardsAndResolvers: 'always',},
  {path:':deptname/:catename', component:ItemlistComponent},
  {path:':deptname/:catename/:scname', component:ItemlistComponent},
];

@NgModule({
   imports: [RouterModule.forRoot(routes,{ useHash: true })], //
  exports: [RouterModule]
})

// {onSameUrlNavigation: 'reload'}
export class AppRoutingModule { }
