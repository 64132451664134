import { Component, OnInit, Input } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { VirtualTimeScheduler } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cartslider',
  templateUrl: './cartslider.component.html',
  styleUrls: ['./cartslider.component.css']
})
export class CartsliderComponent implements OnInit {

  @Input() ischeckout:boolean=false;
  
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};
  tipContent:string="dd";
  constructor(public cartservice: CartService,public loginservice: LoginService,
            public router: Router) {
    this.fnServiceChanges();  
  }

  fnServiceChanges(){
    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }
    });    

    this.loginservice.servicecustomerChange.subscribe((value) => {
      this.cartservice.fnGetCartList();  
    }); 
  }


  ngOnInit() {
  }

  fnProductIncrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty+1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  fnProductDecrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty-1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  
  fnProductDetailsBtnClick(index) {
  

    var _productidid=this.cartColl[index].skuid;    
    var _productidname=this.cartColl[index].productname;
    _productidname= this.fnRemoveSpecialCharater(_productidname);
    var _para=_productidid+"-"+_productidname;

    this.router.navigate(['/p/'+_para]);
    this.cartservice.fnCheckSlide();
  }




  fnContinueShopingBtnClick(){
    this.router.navigate(['/']);
    this.cartservice.fnCheckSlide()
  }

  fnCheckBtnClick(){
    this.router.navigate(['/checkout/']);
    this.cartservice.fnCheckSlide()
  }

  
  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "");
    return str;
  }
  

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
