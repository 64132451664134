import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';
import { CartService } from 'src/app/services/cart.service';
import { LoginService } from 'src/app/services/login.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/internal/operators';
import { Options } from 'ng5-slider/options';

@Component({
  selector: 'app-itembrandlist',
  templateUrl: './itembrandlist.component.html',
  styleUrls: ['./itembrandlist.component.css']
})
export class ItembrandlistComponent implements OnInit ,AfterViewInit,OnDestroy {

  navigationSubscription;  

  public destroyed = new Subject<any>();
  
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};
  itemColl:any=[];


  //Filters
  _deptid:number=0;
  _categoryid:number=0;
  _subcategoryid:number=0;
  _brandid:number=0;
  _minPrice: number = 0;
  _maxPrice: number = 200;
  _itemsearch :String="";
  _itemsort :String="";
  _priceoptions: Options = {floor: 0,ceil: 200};

  _deptColl:any=[];
  _categoryColl:any=[];    
  _subcategoryColl:any=[];
  _deptgrpColl:any=[];
  _brandColl:any=[];
  searchBrandValue:String="";
  _priceColl:any=[];
  _filterheaderColl:any=[];

  _currenturlpath:string="";

  //Para Filters
  _parabrandColl:any=[];
  _parapriceColl:any={minprice:0,maxprice:0};
  _paraitemsearch:string="";
  _parasortby:string="";


  _filterheader:String="";

  constructor(private homeservice: HomeService,private cartservice: CartService,private loginservice: LoginService,private checkoutservice: CheckoutService,
    public router: Router,private cuurenctRoute:ActivatedRoute) 
  {
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.fnGetUrlParameters();
   });
    
   this.fnServiceChanges();
   checkoutservice.ischeckoutloaded=false;
  }

fnServiceChanges(){
  this.cartView = this.cartservice.cartView; 
  this.cartservice.servicecartChange.subscribe((value) => {
    this.cartView = value
    if(this.cartView.cartmain){
      this.cartBO= this.cartView.cartmain
    }
    if(this.cartView.cartdetails){
      this.cartColl= this.cartView.cartdetails
    }
    this.fnUpdateCartChange();
  });    


  this.loginservice.servicecustomerChange.subscribe((value) => {
    this.cartservice.fnGetCartList();  
  }); 

  
}
 

ngOnInit() {
    try{
    
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
}

ngAfterViewInit() {
    try{
    
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
}

ngOnDestroy() {    
    this.destroyed.next();
    this.destroyed.complete();
}

//Url ParameterFilter
fnGetUrlParameters(){

    let _deptname="";
    let _catename="";
    let _scname="";

    let _brandname=this.cuurenctRoute.snapshot.paramMap.get('brandname');

    if (_deptname){
    }
    if (_catename){      
    }
    if (_scname){

    }

    if (_brandname){
      let splitstr=_brandname.split("-");
      if (splitstr.length>0){
        this._brandid=Number(splitstr[0]);
      }  
    }

    this._currenturlpath=this.router.url;
    let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));

    if(url!=""){
      this._currenturlpath=url;
    }
    
        
   this.fnGetFilterParameters();  
    this.fngetItemList();  
    this.fngetItemListFilter();    
}

fnGetFilterParameters(){
  this.cuurenctRoute.queryParams.forEach((urlParams) => {
    let brand=  urlParams['brand'];
    let price=  urlParams['price'];
    let sortby=  urlParams['sortby'];
    let itemsearch=  urlParams['q'];


    if (brand){
      this._parabrandColl=JSON.parse(brand);
    }

    if (price){
      this._parapriceColl=JSON.parse(price);
    }

    if (sortby){
      this._parasortby=sortby;
      this._itemsort=sortby;
    }
    if(this._itemsort===""){
      this._itemsort="P";
    }


    if (itemsearch){
      this._paraitemsearch=itemsearch;
      this._itemsearch=itemsearch;
    }

    //Brand
    for(let wbo of this._brandColl){
      const index = this._parabrandColl.findIndex(a => a.brandid === wbo.brandid ); 
      if(index>-1){
        wbo.ischeck=true;
      }
    }

    //Price
    this. _minPrice =this._parapriceColl.minprice;
    this. _maxPrice=  this._parapriceColl.maxprice;
  });
}

fnReLoadItemList(){

  let _sortlist="";
  let  _brandlist="";
  let  _itemsearchlist="";


  if(this._parabrandColl.length>0){
    _brandlist=JSON.stringify(this._parabrandColl);    
  }
    
  let  _pricelist=JSON.stringify(this._parapriceColl);    

  _sortlist=this._parasortby;
 
  _itemsearchlist=this._paraitemsearch;


      
  this.router.navigate([this._currenturlpath],{ queryParams:{sortby:_sortlist,brand:_brandlist,price:_pricelist,q:_itemsearchlist} }); 


}

//Get Item
fngetItemList() { 
    var data = {
      'deptid':this._deptid,            
      'categoryid': this._categoryid,            
      'subcategoryid': this._subcategoryid, 
      'brandid':this.fnConvertBrandFilterString(),
      'minprice':this._minPrice,
      'maxprice':this._maxPrice,
      'customerid':0,
      'sessionid':'',
      'itemserach':this._itemsearch,
      'itemsort':this._itemsort,
      'mbrandid':this._brandid,
    }

  
  this.homeservice.fnGetItemList(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.itemColl=res.json().result[0];                   
          
              let el = document.getElementById("scrollfcus");
              el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

           // document.getElementById('scrollfcus').scrollTop = 1500;
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
}

fngetItemListFilter() { 
  
  var data = {
    'deptid':this._deptid,            
    'categoryid': this._categoryid,            
    'subcategoryid': this._subcategoryid,
    'itemserach':this._itemsearch,  
    'brandid':this._brandid,       
  }

this.homeservice.fnGetItemListFilter(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){                          
        
          this._deptColl=res.json().result[0];                   
          this._categoryColl=res.json().result[1];                   
          this._subcategoryColl=res.json().result[2];                   
          this._brandColl=res.json().result[3];   
          this._priceColl=res.json().result[4];   
          this._filterheaderColl=res.json().result[5];   

          this.fnSetDepartmentGroup();   
          this.fnGetFilterParameters();  
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
}

private fnSetDepartmentGroup(){
  this._deptgrpColl=[];

  for(let wbo of this._deptColl){
    let _tcategoryColl:any=[];    
    let _tsubcategoryColl:any=[];
   

    for(let cbo of this._categoryColl){
      if(wbo.deptid===cbo.deptid){
        _tcategoryColl.push(cbo)  

        _tsubcategoryColl=[];

        for(let scbo of this._subcategoryColl){
          if(cbo.categoryid===scbo.categoryid){
            _tsubcategoryColl.push(scbo)
          }
        }                         
      }
      
    }
    wbo.categoryColl=_tcategoryColl;
    wbo.subcategoryColl_tsubcategoryColl;

    this._deptgrpColl.push(wbo);
  }

  //Price Range
  let _pricevalue= this._priceColl[0];

  this._priceoptions={
    floor: _pricevalue.minprice,ceil: _pricevalue.maxprice
  }
  this. _minPrice =_pricevalue.minprice;
  this. _maxPrice=  _pricevalue.maxprice;

  this._parapriceColl={minprice:this._minPrice,maxprice:this._maxPrice}

  //Header
  for(let hbo of this._filterheaderColl){

      if(hbo.subcategoryid>0){
        this._filterheader=hbo.subcategoryname;
      }
      else if(hbo.categoryid>0){
        this._filterheader=hbo.categoryname;
      }
      else if(hbo.deptid>0){
        this._filterheader=hbo.deptname;
      }
      else   if(hbo.brandid>0){
        this._filterheader=hbo.brandname;
      }
      else{
        this._filterheader=this._itemsearch;
      }
  }


}

//Click Events
private fnDeptClick(index){
  
  if(index>-1){
    var _deptid=this._deptColl[index].deptid;    
    var _deptname=this._deptColl[index].deptname;
    _deptname= this.fnRemoveSpecialCharater(_deptname);
    var _para=_deptid+"-"+_deptname;

    this.router.navigate(['/'+_para]);
  }
}

private fnCategoryClick(index,caindex){
  if(index>-1){
    var _deptid=this._deptColl[index].deptid;    
    var _deptname=this._deptColl[index].deptname;
    _deptname= this.fnRemoveSpecialCharater(_deptname);

    var _categoryid=this._deptColl[index].categoryColl[caindex].categoryid;    
    var _categoryname=this._deptColl[index].categoryColl[caindex].categoryname;
    _categoryname= this.fnRemoveSpecialCharater(_categoryname);

    var _deptpara=_deptid+"-"+_deptname;
    var _categorypara=_categoryid+"-"+_categoryname;

    this.router.navigate(['/'+_deptpara+"/"+_categorypara]);
  }    
}

private fnHeaderClick(type){
  if(type==="HOME"){
    this.router.navigate(['/']);
  }
  else if(type==="ALLBRAND"){
    this.router.navigate(['/alllist/Brand']);
  }
}

public fnSortChange(event){

  this._parasortby=event.target.value;
  this._itemsort=event.target.value;

  this.fnReLoadItemList();

}


fnBrandCheckValue(values:any,id){

  const index = this._brandColl.findIndex(a => a.brandid ===id);
  if(index>-1){
    this._brandColl[index].ischeck=values.currentTarget.checked;    
  }
  

  this._parabrandColl=[]
  for(let wbo of this._brandColl){
    if(wbo.ischeck){          
      var _a={
        brandid:wbo.brandid
      }    
      this._parabrandColl.push(_a);
    }
  }

 
  this.fnReLoadItemList();

}

fnPriceChangeEvent(){    
  this._parapriceColl={minprice:this._minPrice,maxprice:this._maxPrice}
  this.fnReLoadItemList();
}

//Product
fnAddProductBtnClick(prodBO){
  
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':1      
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemColl[index].qty=1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })

}

fnProductIncrementBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty+1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemColl[index].qty=prodBO.qty+1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnProductDecrementBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty-1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemColl[index].qty=prodBO.qty-1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnProductDetailsBtnClick(prodBO){
    
  var _productidid=prodBO.productid;    
  var _productidname=prodBO.productname;
  _productidname= this.fnRemoveSpecialCharater(_productidname);
  var _para=_productidid+"-"+_productidname;

  this.router.navigate(['/itemdetails/'+_para]);
 
}


fnUpdateCartChange(){
  for(let wbo of this.itemColl){
    const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
    if(index>-1){
      wbo.qty= this.cartColl[index].qty;
    }else{
      wbo.qty=0;
    }
    
  }    
}

//Convert 

fnConvertBrandFilterString(){
  let _filterstr="";
  this._parabrandColl.forEach(element => {        
    if(_filterstr==""){
      _filterstr=element.brandid.toString()
    }
    else{
      _filterstr=_filterstr+","+element.brandid.toString()
    }                
  });

return _filterstr;
}

fnConvertMinPriceFilterString(){
let _filterstr=0;

_filterstr= this._parapriceColl.minprice;

if(_filterstr===undefined){
  _filterstr=0;
}

return _filterstr;
}

fnConvertMaxPriceFilterString(){
let _filterstr=0;

_filterstr= this._parapriceColl.maxprice;

if(_filterstr===undefined){
  _filterstr=0;
}

}

private fnRemoveSpecialCharater(str){
str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
str = str.toString().replace(/ /g, "");
return str;
}

private  fnShowErrorMsg(ex){
  console.log(ex);       
}




}
