import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-cartavailablity',
  templateUrl: './cartavailablity.component.html',
  styleUrls: ['./cartavailablity.component.css']
})
export class CartavailablityComponent implements OnInit {

  @Output() emitData = new EventEmitter();
  
  @Input() cartproductColl:any=[];

  constructor(public activeModal: NgbActiveModal,public checkoutservice: CheckoutService) { }

  ngOnInit() {
  }

  fnSetCartAvailability(){
    var data = {
      'customerid':0
    }
  
  
    this.checkoutservice.fnSetCartAvilablity(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){  
            this.emitData.emit("done");                        
            this.fnCloseCart();            
            
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }

  fnCloseCart(){
    
    this.activeModal.close(true);  
  }


  private  fnShowErrorMsg(ex){
    console.log('Internal Error', ex);       
  }

}
