import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../constants/contants';
import { Observable, EMPTY } from 'rxjs';
import { retry, catchError, shareReplay } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

   
  constructor(private http: Http, public router: Router, private translate: TranslateService) { }


  fnpostInputParams(url, params) {
    const headers = new Headers({
        //'Content-Type': "application/x-www-form-urlencoded",
        //'Content-Type':  "application/JSON",
        'token': (localStorage.token === undefined) ? '' : localStorage.token,
        'Session_id': localStorage.session,
        'whid': (localStorage.wh === undefined) ? '' : localStorage.wh,
        'terminal':"web",
        'authkey':AppSettings.authKey,
        'lan': (localStorage.lan === undefined) ? '' : localStorage.lan
    });
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }

  //MainBanner
  fnGetMainBanner(params){
    return this.fnpostInputParams(AppSettings.getMainBannerUrl,params);
  }

  //UrlDetails
  fnGetUrlDetails(params){    
    return this.fnpostInputParams(AppSettings.geturldetails,params);
  }

  //TargetDetails
  fnGetTargetDetails(params){    
    return this.fnpostInputParams(AppSettings.gettargetdetails,params);
  }

  
  //Page
  fnGetPage(params){    
    return this.fnpostInputParams(AppSettings.getpage,params);
  }

  //Dashbord
  fnGetDashbord(params) {
    return this.fnpostInputParams(AppSettings.getDashboardUrl,params).pipe(
      retry(5),
      catchError(()=>{
        return EMPTY;
      }),
      shareReplay());
  }

  fnGetDealoftheday(params) {
    return this.fnpostInputParams(AppSettings.getDealofthedayUrl,params).pipe(
      retry(5),
      catchError(()=>{
        return EMPTY;
      }),
      shareReplay());
  }

  //ItemList
  fnGetItemList(params){    
    return this.fnpostInputParams(AppSettings.getItemListUrl,params);
  }

   //ItemListFilter
   fnGetItemListFilter(params){    
    return this.fnpostInputParams(AppSettings.getItemListFilterUrl,params);
  }

  //ItemDetails
  fngetItemDetailsUrl(params){    
      return this.fnpostInputParams(AppSettings.getItemDetailsUrl,params);
  }

  
  //ItemList Section
  fnGetItemSectionList(params){    
    return this.fnpostInputParams(AppSettings.getItemSectionListUrl,params);
  }

   //ItemListFilter Section
   fnGetItemSectionListFilter(params){    
    return this.fnpostInputParams(AppSettings.getItemSectionListFilterUrl,params);
  }




  //-----------------------Others---------------------------------//

   //
   fnGetContentManagement(params){    
    return this.fnpostInputParams(AppSettings.getcontentmanagement,params);
  }

  fnGetSettingsByType(params){    
    return this.fnpostInputParams(AppSettings.getsettingsbytype,params);
  }



}
