import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomeraddressComponent } from '../../customeraddress/customeraddress.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  customerBO:any={};   
  customerOrderMainColl:any=[];
  customerOrderDetailsColl:any=[];
  customerAddressColl:any=[];
  customerView:any={};


  constructor(private loginservice: LoginService,private cartservice:CartService,
    private modelService: NgbModal,public router: Router,private confirmationDialogService: ConfirmationDialogService) { 
    this.fnServiceChanges();
  }

  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                
        }      
        if(this.customerView.ordermain){                
          this.customerOrderMainColl= this.customerView.ordermain   
        }
       
        if(this.customerView.orderdetails){                
          this.customerOrderDetailsColl= this.customerView.orderdetails   
        }
        if(this.customerView.customeraddress){                
          this.customerAddressColl= this.customerView.customeraddress   
        }

    }); 
  }
  

  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }


   //My Order
   fnMyOrdersBtnClick(){    
    this.router.navigate(['/myaccount/orders']);
  }

   //My Address
   fnMyOrdersAddressBtnClick(){    
    this.router.navigate(['/myaccount/address']);
  }


   //Add Address
   fnAddAddressBtnClick(){
    const modalRef = this.modelService.open(CustomeraddressComponent ,{ size: 'lg',backdropClass: 'light-blue-backdrop',centered: true ,backdrop : 'static',
                        keyboard : false});
   
    modalRef.componentInstance.emitData.subscribe((loginEntry) => {
      this.fnGetCustomerAddress();
    });

  }

  fnEditAddressBtnClick(index){

    const modalRef = this.modelService.open(CustomeraddressComponent ,{ size: 'lg',backdropClass: 'light-blue-backdrop',centered: true ,backdrop : 'static',
                        keyboard : false});
    
    modalRef.componentInstance.fnGetCustomerAddress(this.customerAddressColl[index].addressid);

    modalRef.componentInstance.emitData.subscribe((loginEntry) => {
      this.fnGetCustomerAddress();
    });

  }


  fnGetCustomerAddress(){
    var data = {
      'areaid':0,
      'customerid':0,            
    }

    this.loginservice.fnGetCustomerAddress(data)
    .subscribe(
      (res)=>{                  
      if(res.json().status==200){                                        
        this.customerAddressColl=res.json().result;  
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }           
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
    
  }


  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Address?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteCustomerAddress(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }
  
  
  fnDeleteCustomerAddress(addressid){
    var data = {
      'addressid':addressid,         
    }

    this.loginservice.fnDeleteCustomerAddress(data)
    .subscribe(
      (res)=>{                  
      if(res.json().status==200){                
       this.loginservice.fnGetCustomerList();                                      
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }           
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
    
  }

  
  



  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
