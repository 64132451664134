import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};


  customerBO:any={};   
  customerOrderMainColl:any=[];
  customerOrderDetailsColl:any=[];

  customerView:any={};

  _selecttabset:string="";

  constructor(private loginservice: LoginService,private cartservice:CartService,public router: Router) { 
    this.fnServiceChanges();
  }

  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain;                
          this.fnGetOrder('ACTIVE');
        }      

        
        // if(this.customerView.ordermain){                
        //   this.customerOrderMainColl= this.customerView.ordermain           
        // }
        // for(let wbo of this.customerOrderMainColl){
        //   wbo.productdetailitem=this.fnSetTotalItemsString(wbo.orderid);
        // }

        // if(this.customerView.orderdetails){                
        //   this.customerOrderDetailsColl= this.customerView.orderdetails   
        // }
       // this.fnSetPastAndCurrentOrder();

    }); 


    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }

      this.fnUpdateCartChange();
    });  

  }
  
  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  fnGetOrder(otype){
    this._selecttabset=otype;
    var data = {
      'ordertype':otype                     
   }
   this.loginservice.fnGetOrderInCustomer(data)
      .subscribe(
        (res)=>{         
          console.log(res);                      
        if(res.json().status==200){    
          let _result=res.json().result;  
         
          this.customerOrderMainColl=_result.ordermain;
          this.customerOrderDetailsColl=_result.orderdetails;          

          for(let wbo of this.customerOrderMainColl){
            wbo.productdetailitem=this.fnSetTotalItemsString(wbo.orderid);
          }
        }        
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }
  



  fnUpdateCartChange(){

    for(let wbo of this.customerOrderDetailsColl){
     const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
     if(index>-1){
       wbo.qty= this.cartColl[index].qty;
     }else{
       wbo.qty=0;
     }    
   }  
  }
 
 
   fnAddProductBtnClick(prodBO){
     
     var data = {
       'customerid':0,            
       'sessionid': '',            
       'productid': prodBO.productid, 
       'skuid':prodBO.skuid,
       'qty':1      
     }
   
     this.cartservice.fnInsertCartProduct(data)
     .subscribe(
       (res)=>{                  
         if(res.json().status==200){     
   
           const index = this.customerOrderDetailsColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
           if(index>-1){
             this.customerOrderDetailsColl[index].qty=1;
           }
           this.cartservice.fnGetCartList();
         }
         else if(res.json().status==400){                          
           this.fnShowErrorMsg('Error'+res.json().err_field);
         }          
       },
       (err)=>{          
         this.fnShowErrorMsg('Error'+ err);
       })
   
   }
   
   fnProductIncrementBtnClick(prodBO){
     var data = {
       'customerid':0,            
       'sessionid': '',            
       'productid': prodBO.productid, 
       'skuid':prodBO.skuid,
       'qty':prodBO.qty+1     
     }
   
     this.cartservice.fnInsertCartProduct(data)
     .subscribe(
       (res)=>{                  
         if(res.json().status==200){     
   
           const index = this.customerOrderDetailsColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
           if(index>-1){
             this.customerOrderDetailsColl[index].qty=prodBO.qty+1;
           }
           this.cartservice.fnGetCartList();
         }
         else if(res.json().status==400){                          
           this.fnShowErrorMsg('Error'+res.json().err_field);
         }          
       },
       (err)=>{          
         this.fnShowErrorMsg('Error'+ err);
       })
   }
   
   fnProductDecrementBtnClick(prodBO){
     var data = {
       'customerid':0,            
       'sessionid': '',            
       'productid': prodBO.productid, 
       'skuid':prodBO.skuid,
       'qty':prodBO.qty-1     
     }
   
     this.cartservice.fnInsertCartProduct(data)
     .subscribe(
       (res)=>{                  
         if(res.json().status==200){     
   
           const index = this.customerOrderDetailsColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
           if(index>-1){
             this.customerOrderDetailsColl[index].qty=prodBO.qty-1;
           }
           this.cartservice.fnGetCartList();
         }
         else if(res.json().status==400){                          
           this.fnShowErrorMsg('Error'+res.json().err_field);
         }          
       },
       (err)=>{          
         this.fnShowErrorMsg('Error'+ err);
       })
   }









  //Order Slider
  fnOpenOrderSlider(orderid){
    this.cartservice.fnCheckSlideOrder(orderid)
  }


  //My Order
  fnMyOrdersAddressBtnClick(){    
    this.router.navigate(['/myaccount/address']);
  }



  fnSetTotalItemsString(orderid){
    let _s="";
    let a=0;
    for(let wbo of this.customerOrderDetailsColl){
      if(wbo.orderid===orderid){
        if(a===2){
          break;
        }
        if(_s===""){
          _s=wbo.productname;
        }
        else{
          _s=_s+","+wbo.productname;
        }
        a+=1;
      }     
    }
    return _s;
  }

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
