import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { CartService } from 'src/app/services/cart.service';
import { LoginService } from 'src/app/services/login.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/internal/operators';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {

  
  _currenturlpath:string="";
  _cmslinkurl:string="";

  contentColl:any[]=[];
  contentBO:any={};

  constructor(private homeservice: HomeService,private cartservice: CartService,private loginservice: LoginService,private checkoutservice: CheckoutService,
    public router: Router,private cuurenctRoute:ActivatedRoute) {
      
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)).subscribe(() => {
          this.fnGetUrlParameters();
     });
      
    
     checkoutservice.ischeckoutloaded=false;
   }

  ngOnInit() {
  }

  fnGetUrlParameters(){

  

    let _url=this.cuurenctRoute.snapshot.paramMap.get('cmsname');

 
    if (_url){
     this._cmslinkurl=_url;
    }

    this._currenturlpath=this.router.url;
    let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));

    if(url!=""){
      this._currenturlpath=url;
    }

    this.fngetContentManagement();
       
}

fngetContentManagement() { 
  var data = {
    'contentid':0,
    'contenttype':''            
  }
 this.homeservice.fnGetContentManagement(data)
     .subscribe(
       (res)=>{                  
         if(res.json().status==200){                          
           this.contentColl=res.json().result;      
                 
           if(this.contentColl.length>0){

              for(let wbo of this.contentColl){
                if(wbo.linkurl===this._cmslinkurl){
                  this.contentBO=wbo;
                }
              }


           }
         }
         else if(res.json().status==400){                          
           this.fnShowErrorMsg('Error'+res.json().err_field);
         }
        
       },
       (err)=>{          
         this.fnShowErrorMsg('Error'+ err);
       })
}

private  fnShowErrorMsg(ex){
  console.log(ex);       
}


}
