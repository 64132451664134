import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CheckoutService } from 'src/app/services/checkout.service';
import { HeaderService } from 'src/app/services/header.service';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';

declare var $ :any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  
  isScrolltoTopShow: boolean=false;
  ScrollClass:string="img-hidden";

  categoryColl:any=[];
  contentColl:any=[];
  settingsColl:any=[];

  
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};
  
  newsletterform=new FormGroup({
    email:new FormControl(null,[Validators.required, Validators.email]),                 
  });

  submitted:boolean;
  successfully:boolean;



  constructor(public checkoutservice: CheckoutService, public headerservice: HeaderService,
    public router: Router, public homeservice: HomeService, public cartservice: CartService) {
      this.fnServiceChanges();  
     }

  
     fnServiceChanges(){
      this.cartView = this.cartservice.cartView; 
      this.cartservice.servicecartChange.subscribe((value) => {
        this.cartView = value
        if(this.cartView.cartmain){
          this.cartBO= this.cartView.cartmain
        }
        if(this.cartView.cartdetails){
          this.cartColl= this.cartView.cartdetails
        }
      });    
  
     }
  
  ngOnInit() {
    try {
      this.newsletterform.reset();
      this.fnInitializeFormGroup();

      this.fngetCategory();
      this.fngetContentManagement();
      this.fnGetSettingsByType();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }


  fnInitializeFormGroup(){
    this.newsletterform.setValue({    
      email:'',       
    })
  }


  fngetCategory() {   
    var data = {
      'categoryid': 0,            
      'deptid':0,    
      'filter':'FOOTERDISPLAY',          
    }
    this.headerservice.fnGetCategory(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this.categoryColl=res.json().result;     
                 
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  } 


  private fnCategoryClick(index){
    if(index>-1){
      var _deptid=this.categoryColl[index].deptid;    
      var _deptname=this.categoryColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname=this.categoryColl[index].deptseourlname;

      var _categoryid=this.categoryColl[index].categoryid;    
      var _categoryname=this.categoryColl[index].categoryname;
      _categoryname= this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname=this.categoryColl[index].categoryseourlname;

      var _deptpara=_deptid+"-"+_deptname;
      var _categorypara=_categoryid+"-"+_categoryname;

      if (_deptseourlname){
        _deptpara=_deptseourlname;
      }
      if (_categoryseourlname){
        _categorypara=_categoryseourlname;
      }

      this.router.navigate(['/'+_deptpara+"/"+_categorypara]);
    }    
  }
  
  


  fngetContentManagement() { 
    var data = {
      'contentid':0,
      'contenttype':''            
    }
   this.homeservice.fnGetContentManagement(data)
       .subscribe(
         (res)=>{                  
           if(res.json().status==200){                          
             this.contentColl=res.json().result;                
           }
           else if(res.json().status==400){                          
             this.fnShowErrorMsg('Error'+res.json().err_field);
           }
          
         },
         (err)=>{          
           this.fnShowErrorMsg('Error'+ err);
         })
 }

 fnContentManagementClick(index){
  let _url="";
  _url=this.contentColl[index].linkurl;

  this.router.navigate(['c/'+_url]);

 }

 
 fnGetSettingsByType() { 
    var data = {   
      'settingstype':'MYACCOUNT'            
    }
    this.homeservice.fnGetSettingsByType(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this.settingsColl=res.json().result;    
                
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
            
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }


  fnShareLinkClik(type){

    const index = this.settingsColl.findIndex(a => a.settingsname ===type );
    if(index>-1){

      let url=this.settingsColl[index].settingsvalue;
      
      if(url){
        window.open(url, "_blank");  
      }

      
    }
    

  }

  fnNewsLetterBtnClick() {
    try{      
      this.submitted=true;
      this.successfully=false;
      //Validate
      if (this.newsletterform.valid) {
        alert('Add News Letter');
        this.submitted = false;
        this.successfully = false;
        this.newsletterform.reset();
        this.fnInitializeFormGroup();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }


  fnOpenSlider(){
    this.cartservice.fnCheckSlide()
}


  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "");
    return str;
  }
  

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
