import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../constants/contants';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  servicelocationChange: Subject<boolean> = new Subject<boolean>();

  constructor(private http: Http, public router: Router, private translate: TranslateService) { }


  fnpostInputParams(url, params) {
    const headers = new Headers({
        //'Content-Type': "application/x-www-form-urlencoded",
        //'Content-Type':  "application/JSON",
        'token': (localStorage.token === undefined) ? '' : localStorage.token,
        'Session_id': localStorage.session,
        'whid': (localStorage.wh === undefined) ? '' : localStorage.wh,
        'authkey':AppSettings.authKey
    });
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }

  //Department
  fnGetDeparment(params){
    return this.fnpostInputParams(AppSettings.getDepartmentUrl,params);
  }

  //Category
  fnGetCategory(params){
    return this.fnpostInputParams(AppSettings.getCategoryUrl,params);
  }

  //Sub Category
  fnGetSubCategory(params){
  return this.fnpostInputParams(AppSettings.getSubCategoryUrl,params);
  }

   //Brand
   fnGetBrand(params){
    return this.fnpostInputParams(AppSettings.getBrandUrl,params);
    }

  //AutoComplete
  fnGetAutoComplete(params){
    return this.fnpostInputParams(AppSettings.getItemAutoCompleteUrl,params);
  }

}
