  import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
  import { HomeService } from 'src/app/services/home.service';
  import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
  import { Subject } from 'rxjs';
  import { filter, takeUntil } from 'rxjs/internal/operators';
  import { Options, LabelType } from 'ng5-slider';
import { CartService } from 'src/app/services/cart.service';
import { LoginService } from 'src/app/services/login.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
 var $ : any;
  @Component({
    selector: 'app-itemlist',
    templateUrl: './itemlist.component.html',
    styleUrls: ['./itemlist.component.css']
  })
  export class ItemlistComponent implements OnInit,AfterViewInit,OnDestroy {
    
    navigationSubscription;  

    public destroyed = new Subject<any>();
    
    cartBO:any={};
    cartColl:any=[];
    cartView:any={};
    itemColl:any[]=[];
    itemAllColl:any[]=[];
    pageColl:any[]=[];

    itemsum = 65;
    throttle = 300;
    scrollDistance = 1;
    scrollUpDistance =2;


    //Filters
    _deptid:number=0;
    _categoryid:number=0;
    _subcategoryid:number=0;
    _pageid:number=0;
    _brandid:number=0;
    _vendorid:number=0;
    _offerid:number=0;
    _minPrice: number = 0;
    _maxPrice: number = 0;
    _rawminPrice: number = 0;
    _rawmaxPrice: number = 0;
    _txtminPrice: number = 0;
    _txtmaxPrice: number = 0;
    _itemsearch :String="";
    _itemsection :String="";
    _itemsort :String="";
    _priceoptions: Options = {floor: 0,ceil: 200};

    _deptColl:any=[];
    _categoryColl:any=[];    
    _subcategoryColl:any=[];
    _deptgrpColl:any=[];
    _brandColl:any=[];
    _vendorColl:any=[];
    searchBrandValue:String="";
    searchVendorValue:String="";
    _priceColl:any=[];    
    _filterheaderColl:any=[];

    _currenturlpath:string="";

    //Para Filters
    _parabrandColl:any=[];
    _parabrandString:string="";
    _paravendorColl:any=[];
    _paravendorString:string="";
    _parapriceColl:any={minprice:0,maxprice:0};    
    _paraitemsearch:string="";
    _parasortby:string="";

    _filterheader:String="";

    isload:boolean=false;
    isempty:boolean=false;
    
    isdefaultpage:boolean=false;
    iscustompage:boolean=false;
    isdualpage:boolean=false;

    constructor(private homeservice: HomeService,private cartservice: CartService,private loginservice: LoginService,private checkoutservice: CheckoutService,
      public router: Router,private cuurenctRoute:ActivatedRoute) 
    {
      
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)).subscribe(() => {
          this.isempty=false;
          this.isdefaultpage=false;
          this.iscustompage=false;
          this.isdualpage=false;
          this.fnGetUrlParameters();
     });
      
     this.fnServiceChanges();
     checkoutservice.ischeckoutloaded=false;
    }

  fnServiceChanges(){
    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }
      this.fnUpdateCartChange();
    });    


    this.loginservice.servicecustomerChange.subscribe((value) => {
      this.cartservice.fnGetCartList();  
    }); 

    
  }
   

  ngOnInit() {
      try{
        
        window.addEventListener('scroll', this.fnScrollItemListEvent, true);
      }catch(ex){
        this.fnShowErrorMsg('Internal Error'+ex);
      }
  }

  ngAfterViewInit() {
      try{
      
      }catch(ex){
        this.fnShowErrorMsg('Internal Error'+ex);
      }
  }

  ngOnDestroy() {    
      this.destroyed.next();
      this.destroyed.complete();
      window.removeEventListener('scroll', this.fnScrollItemListEvent, true);
  }
  

  //Url ParameterFilter
  fnGetUrlParameters(){
      this.fnShowProcess();

      let _deptname=this.cuurenctRoute.snapshot.paramMap.get('deptname');
      let _catename=this.cuurenctRoute.snapshot.paramMap.get('catename');
      let _scname=this.cuurenctRoute.snapshot.paramMap.get('scname');
    
      
      this._currenturlpath=this.router.url;
      let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));

      if(url!=""){
        this._currenturlpath=url;
      }
      
      //Price Update 
      this._priceColl=[{minprice:0,maxprice:0}]      
          
      //Get Url Details In Id...
      var data = {
        'deptname':_deptname,            
        'categoryname': _catename,            
        'subcategoryname': _scname,
        'currenturl':this._currenturlpath,        
      }
      console.log(data)

    this.homeservice.fnGetUrlDetails(data)
        .subscribe(
          (res)=>{                  
           
            if(res.json().status==200){                          
              var result=res.json().result[0];
              

              console.log(res.json().result)

              this._deptid=result.deptid;
              this._categoryid=result.categoryid;
              this._subcategoryid=result.subcategoryid;
              this._brandid=result.brandid;
              this._vendorid=result.vendorid;
              this._offerid=result.offerid;
              this._pageid=result.pageid;
              let iscompletepage=result.iscompletepage;
              this._itemsection=result.itemsection;
            
              
              if(this._pageid===0 ){
                this.isdefaultpage=true;
                this.fnGetFilterParameters();  
                this.fngetItemList();  
                this.fngetItemListFilter();  
              }
              else if(this._pageid>0 && iscompletepage ===0){
                this.isdefaultpage=true;
                this.isdualpage=true;
                this.fngetPageList();
                this.fnGetFilterParameters();  
                this.fngetItemList();  
                this.fngetItemListFilter();  
              }
              else{                
                this.iscustompage=true;
                this.fngetPageList();
              }
               
              
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
     })


  
  }


  fnGetFilterParameters(){
    this.cuurenctRoute.queryParams.forEach((urlParams) => {
      let brand=  urlParams['brand'];      
      let minprice=  urlParams['minprice'];
      let maxprice=  urlParams['maxprice'];
      let sortby=  urlParams['sortby'];
      let itemsearch=  urlParams['q'];
      let vendor=  urlParams['vendor'];  

      if (brand){
        //this._parabrandColl=JSON.parse(brand);
        this._parabrandString=brand;
        this._parabrandColl= this.fnGetBrandFilterUrlString(brand)
     
      }else{
        this._parabrandString="";
        this._parabrandColl=[];
      }

      if (vendor){
        //this._parabrandColl=JSON.parse(brand);
        this._paravendorString=vendor;
        this._paravendorColl= this.fnGetVendorFilterUrlString(vendor)
     
      }else{
        this._paravendorString="";
        this._paravendorColl=[];
      }
    

      if (minprice){        
        this._minPrice=minprice;        
      }
      else{
         if(this._priceColl.length>0){
           let _pricevalue= this._priceColl[0];         
           this._minPrice=_pricevalue.minprice;                               
          }
          else{
            this._minPrice=0;
          }
      }

      if (maxprice){       
        this._maxPrice=maxprice;
      }
      else{
        if(this._priceColl.length>0){
          let _pricevalue= this._priceColl[0];         
          this._maxPrice=_pricevalue.maxprice;                    
         }
         else{
          this._maxPrice=0;
          }
     }

      if (sortby){
        this._parasortby=sortby;
        this._itemsort=sortby;
      }

      if(this._itemsort===""){
        this._itemsort="P";
      }


      if (itemsearch){
        this._paraitemsearch=itemsearch;
        this._itemsearch=itemsearch;
      }
      else{
        this._paraitemsearch="";
        this._itemsearch="";
      }

      //Brand
      for(let wbo of this._brandColl){
        const index = this._parabrandColl.findIndex(a => a.brandid === wbo.brandid ); 
        if(index>-1){
          wbo.ischeck=true;
        }
      }

      //Vendor
      for(let wbo of this._vendorColl){
        const index = this._paravendorColl.findIndex(a => a.vendorid === wbo.vendorid ); 
        if(index>-1){
          wbo.ischeck=true;
        }
      }

      //Price
      this. _txtminPrice =this._minPrice;
      this. _txtmaxPrice=  this._maxPrice;

     
    });
  }
 
  fnReLoadItemList(){
  
    let _sortlist="";
    let  _brandlist="";
    let  _vendorlist="";
    let  _itemsearchlist="";
   

    if(this._parabrandColl.length>0){   
      _brandlist= this.fnConvertBrandFilterUrlString()
    }      
    if(this._paravendorColl.length>0){   
      _vendorlist= this.fnConvertVendorFilterUrlString()
    }      
    _sortlist=this._parasortby;   
    _itemsearchlist=this._paraitemsearch;

   

    var obj = { };
    let data=['sortby', 'brand', 'minprice','maxprice','q','vendor'];
    for (let column of data){
      if(column==='sortby'){
        if(_sortlist){
          obj[column] = _sortlist;
        }        
      }
      else if(column==='brand'){
        if(_brandlist){
          obj[column] = _brandlist;
        }    
      }
      else if(column==='minprice'){
        if(this._rawminPrice!== this._minPrice){
          obj[column] = this._minPrice;
        }    
      }
      else if(column==='maxprice'){
        if(this._rawmaxPrice!== this._maxPrice){
          obj[column] = this._maxPrice;
        }       
      }
      else if(column==='q'){
        if(_itemsearchlist){
          obj[column] = _itemsearchlist;
        }    
      }
      else if(column==='vendor'){
        if(_vendorlist){
          obj[column] = _vendorlist;
        }    
      }
     
    }

    
    //this.router.navigate([this._currenturlpath],{ queryParams:{sortby:_sortlist,brand:_brandlist,minprice:this._minPrice,maxprice:this._maxPrice,q:_itemsearchlist} }); 
    this.router.navigate([this._currenturlpath],{ queryParams:obj}); 


  }

  //Get Item
  fngetItemList() { 
   

      this.isempty=false;
      var data = {
        'deptid':this._deptid,            
        'categoryid': this._categoryid,            
        'subcategoryid': this._subcategoryid,         
        'brandid':this._parabrandString,
        'minprice':this._minPrice,
        'maxprice':this._maxPrice,
        'customerid':0,
        'sessionid':'',
        'itemsearch':this._itemsearch,
        'itemsort':this._itemsort,
        'mbrandid':this._brandid,
        'itemsection':this._itemsection,
        'vendorid':this._paravendorString,
        'mvendorid':this._vendorid,
        'offerid':this._offerid,
      }
     
    this.homeservice.fnGetItemList(data)
        .subscribe(
          (res)=>{          
            this.fnHideProcess();                
            if(res.json().status==200){    

             
              let _res=res.json().result;
              console.log(_res)
              this.itemColl=[];
              this.itemAllColl=_res.itemlist;                                 
              this.fnResetScrollItems()
            
              if(this.itemAllColl.length===0){
                this.isempty=true;
              }

              this.fnScrollAddItems(0, this.itemsum,'push')
              
                let el = document.getElementById("scrollfcus");
                el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

             // document.getElementById('scrollfcus').scrollTop = 1500;
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnHideProcess();
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fngetItemListFilter() { 
    
    var data = {
      'deptid':this._deptid,            
      'categoryid': this._categoryid,            
      'subcategoryid': this._subcategoryid,
      'itemserach':this._itemsearch,
      'brandid':this._brandid,   
      'itemsection':this._itemsection,      
      'vendorid':this._vendorid,   
      'offerid':this._offerid,
    }

  this.homeservice.fnGetItemListFilter(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            var _result=res.json().result;
            this._deptColl=_result.departmentColl;                   
            this._categoryColl=_result.categoryColl;                   
            this._subcategoryColl=_result.subcategoryColl;                   
            this._brandColl=_result.brandColl;   
            this._priceColl=_result.priceColl;   
            this._vendorColl=_result.vendorColl;   
            this._filterheaderColl=_result.filterhearderColl;   

            this.fnSetDepartmentGroup();   
            this.fnGetFilterParameters();  
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }

  private fnSetDepartmentGroup(){
    this._deptgrpColl=[];

    for(let wbo of this._deptColl){
      let _tcategoryColl:any=[];    
     
    

      for(let cbo of this._categoryColl){
        if(wbo.deptid===cbo.deptid){

          let _tsubcategoryColl:any=[];
      
          for(let scbo of this._subcategoryColl){
            if(cbo.categoryid===scbo.categoryid){
              _tsubcategoryColl.push(scbo)
            }
          }  
          cbo.subcategoryColl=_tsubcategoryColl;

          _tcategoryColl.push(cbo)  
                                      
        }
        
      }
      wbo.categoryColl=_tcategoryColl;

      this._deptgrpColl.push(wbo);
    }


    // for(let wbo of this._deptColl){
    //   let _tcategoryColl:any=[];    
    //   let _tsubcategoryColl:any=[];
     

    //   for(let cbo of this._categoryColl){
    //     if(wbo.deptid===cbo.deptid){


    //       _tcategoryColl.push(cbo)  

    //       _tsubcategoryColl=[];

    //       for(let scbo of this._subcategoryColl){
    //         if(cbo.categoryid===scbo.categoryid){
    //           _tsubcategoryColl.push(scbo)
    //         }
    //       }                         
    //     }
        
    //   }
    //   wbo.categoryColl=_tcategoryColl;
    //   wbo.subcategoryColl=_tsubcategoryColl;

    //   this._deptgrpColl.push(wbo);
    // }

    //Price Range
    let _pricevalue= this._priceColl[0];

    this. _rawminPrice =_pricevalue.minprice;
    this. _rawmaxPrice=  _pricevalue.maxprice;



    //Header
    for(let hbo of this._filterheaderColl){
        if(hbo.subcategoryid>0){
          this._filterheader=hbo.subcategoryname;
        }
        else if(hbo.categoryid>0){
          this._filterheader=hbo.categoryname;
        }
        else if(hbo.deptid>0){
          this._filterheader=hbo.deptname;
        }
        else if(hbo.brandid>0){
          this._filterheader=hbo.brandname;
        }
        else if(hbo.vendorid>0){
          this._filterheader=hbo.vendorname;
        }
        else if(hbo.offerid>0){
          this._filterheader=hbo.offername;
        }
        else if(this._itemsection!==''){
          this._filterheader=this._itemsection;
        }
        else{
          this._filterheader=this._itemsearch;
        }
    }


  }

  //Scroll Items Event
  fnResetScrollItems(){
    this.itemsum = 65;
    this.throttle = 300;
    this.scrollDistance = 1;
    this.scrollUpDistance =2;
    
  }

  fnScrollAddItems(startIndex, endIndex, _method) {

    if(endIndex>this.itemAllColl.length){
      endIndex=this.itemAllColl.length
    }
  
    for (let i = startIndex; i < endIndex; ++i) {
      let a=this.itemAllColl[i]
      this.itemColl.push(a)
    }
  }

  fnScrollItemListEvent = (event: any): void => {
      let pos = (event.srcElement.scrollTop || document.body.scrollTop) + event.srcElement.offsetHeight;
      let max = event.srcElement.scrollHeight;
      max = Math.floor(Number(max))-500
      if(Number(pos) > Number(max) ){
        if(this.isload)
        { 
          if(this.itemsum >= this.itemAllColl.length){
              return
          }

          const start = this.itemsum;
          this.itemsum += 20;        
          this.fnScrollAddItems(start, this.itemsum,"");
        }
      }
  }


  //Click Events
  private fnDeptClick(index){
    
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname=this._deptColl[index].deptseourlname;

      var _para=_deptid+"-"+_deptname;

      
      if (_deptseourlname){
        _para=_deptseourlname;
      }

      this.router.navigate(['/'+_para]);
    }
  }

  private fnCategoryClick(index,caindex){
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname=this._deptColl[index].deptseourlname;

      var _categoryid=this._deptColl[index].categoryColl[caindex].categoryid;    
      var _categoryname=this._deptColl[index].categoryColl[caindex].categoryname;
      _categoryname= this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname=this._deptColl[index].categoryColl[caindex].categoryseourlname;

      var _deptpara=_deptid+"-"+_deptname;
      var _categorypara=_categoryid+"-"+_categoryname;

      if (_deptseourlname){
        _deptpara=_deptseourlname;
      }
      if (_categoryseourlname){
        _categorypara=_categoryseourlname;
      }

     

      this.router.navigate(['/'+_deptpara+"/"+_categorypara]);
    }    
  }

  private fnSubCategoryClick(index,caindex,scindex){
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname=this._deptColl[index].deptseourlname;

      var _categoryid=this._deptColl[index].categoryColl[caindex].categoryid;    
      var _categoryname=this._deptColl[index].categoryColl[caindex].categoryname;
      _categoryname= this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname=this._deptColl[index].categoryColl[caindex].categoryseourlname;

      var _subcategoryid=this._deptColl[index].categoryColl[caindex].subcategoryColl[scindex].subcategoryid;    
      var _subcategoryname=this._deptColl[index].categoryColl[caindex].subcategoryColl[scindex].subcategoryname;
      _subcategoryname= this.fnRemoveSpecialCharater(_subcategoryname);
      var _subcategoryseourlname=this._deptColl[index].categoryColl[caindex].subcategoryColl[scindex].subcategoryseourlname;

      var _deptpara=_deptid+"-"+_deptname;
      var _categorypara=_categoryid+"-"+_categoryname;
      var _subcategorypara=_subcategoryid+"-"+_subcategoryname;

      if (_deptseourlname){
        _deptpara=_deptseourlname;
      }
      if (_categoryseourlname){
        _categorypara=_categoryseourlname;
      }

      if (_subcategoryseourlname){
        _subcategorypara=_subcategoryseourlname;
      }

      this.router.navigate(['/'+_deptpara+"/"+_categorypara+"/"+_subcategorypara]);
    }    
  }



  private fnHeaderClick(type){

    
      if(this._filterheaderColl.length>0){
        if(type==="DEPARTMENT"){
          var _deptid=this._filterheaderColl[0].deptid;    
          var _deptname=this._filterheaderColl[0].deptname;
          _deptname= this.fnRemoveSpecialCharater(_deptname);
          var _para=_deptid+"-"+_deptname;

          var _deptseourlname=this._filterheaderColl[0].deptseourlname;
          if (_deptseourlname){
            _para=_deptseourlname;
          }


    
          this.router.navigate(['/'+_para]);  
        }
        else  if(type==="CATEGORY"){
          var _deptid=this._filterheaderColl[0].deptid;    
          var _deptname=this._filterheaderColl[0].deptname;
          _deptname= this.fnRemoveSpecialCharater(_deptname);
          var _deptseourlname=this._filterheaderColl[0].deptseourlname;

          var _categoryid=this._filterheaderColl[0].categoryid;    
          var _categoryname=this._filterheaderColl[0].categoryname;
          _categoryname= this.fnRemoveSpecialCharater(_categoryname);
          var _categoryseourlname=this._filterheaderColl[0].categoryseourlname;

          var _deptpara=_deptid+"-"+_deptname;
          var _categorypara=_categoryid+"-"+_categoryname;

          
          if (_deptseourlname){
            _deptpara=_deptseourlname;
          }
          if (_categoryseourlname){
            _categorypara=_categoryseourlname;
          }
        
          
          this.router.navigate(['/'+_deptpara+"/"+_categorypara]);
        }
        else if(type==="HOME"){
          this.router.navigate(['/']);
        }
        else if(type==="ALLBRAND"){
         
          this.router.navigate(['/all/Brand']);
        }
    }
  }

  private fnSortChange(event){

    this._parasortby=event.target.value;
    this._itemsort=event.target.value;

    this.fnReLoadItemList();

  }

  
  fnBrandCheckValue(values:any,id){

    const index = this._brandColl.findIndex(a => a.brandid ===id);
    if(index>-1){
      this._brandColl[index].ischeck=values.currentTarget.checked;    
    }
    

    this._parabrandColl=[]
    for(let wbo of this._brandColl){
      if(wbo.ischeck){          
        var _a={
          brandid:wbo.brandid,
          brandname:wbo.brandname
        }    
        this._parabrandColl.push(_a);
      }
    }
  
   
    this.fnReLoadItemList();

  }

  fnVendorCheckValue(values:any,id){

    const index = this._vendorColl.findIndex(a => a.vendorid ===id);
    if(index>-1){
      this._vendorColl[index].ischeck=values.currentTarget.checked;    
    }
    

    this._paravendorColl=[]
    for(let wbo of this._vendorColl){
      if(wbo.ischeck){          
        var _a={
          vendorid:wbo.vendorid,
          vendorname:wbo.vendorname
        }    
        this._paravendorColl.push(_a);
      }
    }
  
   
    this.fnReLoadItemList();

  }


  fnPriceChangeBtnClick(){    
    this._minPrice=this._txtminPrice;
    this._maxPrice=this._txtmaxPrice;
    this.fnReLoadItemList();
  }


  //Product
  fnAddProductBtnClick(prodBO){
    
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':1      
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     



          const index = this.itemColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.itemColl[index].qty=1;
          }
          this.cartservice.fnGetCartList();


          
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })

  }

  fnProductIncrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty+1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.itemColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.itemColl[index].qty=prodBO.qty+1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  fnProductDecrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty-1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.itemColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
          if(index>-1){
            this.itemColl[index].qty=prodBO.qty-1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  fnProductDetailsBtnClick(prodBO){
      
    var _productidid=prodBO.productid;    
    var _productidname=prodBO.productname;
    _productidname= this.fnRemoveSpecialCharater(_productidname);
    var _para=_productidid+"-"+_productidname;

    this.router.navigate(['/itemdetails/'+_para]);
   
  }

  fnUpdateCartChange(){
    for(let wbo of this.itemColl){
      const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
      if(index>-1){
        wbo.qty= this.cartColl[index].qty;
      }else{
        wbo.qty=0;
      }
      
    }  
    
    if(this.iscustompage===true){
      for(let wbo of this.pageColl){
        if(wbo.layouttype==="ITEMCAROSAL"){
          for(let pbo of wbo.pagedetails){
            const index = this.cartColl.findIndex(a => a.productid === pbo.productid && a.skuid === pbo.skuid );
            if(index>-1){
              pbo.qty= this.cartColl[index].qty;
            }else{
              pbo.qty=0;
            }
          }
        }
      }


    }
    

  }

 //Convert 

  fnConvertBrandFilterString(){
      let _filterstr="";

      this._parabrandColl.forEach(element => {        
        if(_filterstr==""){
          _filterstr=element.brandid.toString()
        }
        else{
          _filterstr=_filterstr+","+element.brandid.toString()
        }                
      });
    
    return _filterstr;
  }

  fnConvertMinPriceFilterString(){
    let _filterstr=0;

    _filterstr= this._parapriceColl.minprice;

    if(_filterstr===undefined){
      _filterstr=0;
    }

    return _filterstr;
  }

  fnConvertMaxPriceFilterString(){
    let _filterstr=0;

    _filterstr= this._parapriceColl.maxprice;

    if(_filterstr===undefined){
      _filterstr=0;
    }

  }

  fnConvertBrandFilterUrlString(){

      let _filterstr="";
      this._parabrandColl.forEach(element => {        
        if(_filterstr==""){
          _filterstr= this.fnRemoveSpecialCharater(element.brandname.toString())
        }
        else{
          _filterstr=_filterstr+","+ this.fnRemoveSpecialCharater(element.brandname.toString())
        }                
      });

    return _filterstr;
  
    
  }

  
  fnConvertVendorFilterUrlString(){

    let _filterstr="";
    this._paravendorColl.forEach(element => {        
      if(_filterstr==""){
        _filterstr= this.fnRemoveSpecialCharater(element.vendorname.toString())
      }
      else{
        _filterstr=_filterstr+","+ this.fnRemoveSpecialCharater(element.vendorname.toString())
      }                
    });

  return _filterstr;

  
}


  fnGetBrandFilterUrlString(brandurl){
    let splitstr=brandurl.split(",");
    
    let _brColl=[];

      for(let wbo of splitstr){
        const index = this._brandColl.findIndex(a => this.fnRemoveSpecialCharater(a.brandname) ===wbo.toString());
        if(index>-1){
          _brColl.push
          ({brandid:this._brandColl[index].brandid,
              brandname:this._brandColl[index].brandname})
        }    
      }

    return _brColl;

  }

  fnGetVendorFilterUrlString(vendorurl){
    if(!vendorurl){
      vendorurl='';
    }
    let splitstr=vendorurl.split(",");
    
    let _brColl=[];

      for(let wbo of splitstr){
        const index = this._vendorColl.findIndex(a => this.fnRemoveSpecialCharater(a.vendorname) ===wbo.toString());
        if(index>-1){
          _brColl.push
          ({vendorid:this._vendorColl[index].vendorid,
            vendorname:this._vendorColl[index].vendorname})
        }    
      }

    return _brColl;

  }


  //Filter Link
  fnRemoveBrand(index){
 
    if(index>-1){
        

      const index1 = this._brandColl.findIndex(a => a.brandid === this._parabrandColl[index].brandid);
      if(index1>-1){
        this._brandColl[index].ischeck=false;    
      }
      this._parabrandColl.splice(index, 1);       


      this.fnReLoadItemList();

    }


  }

  fnRemoveVendor(index){
 
    if(index>-1){
        

      const index1 = this._vendorColl.findIndex(a => a.vendorid === this._paravendorColl[index].vendorid);
      if(index1>-1){
        this._vendorColl[index].ischeck=false;    
      }
      this._paravendorColl.splice(index, 1);       


      this.fnReLoadItemList();

    }


  }


  fnRemoveMinPrice(){
    this._minPrice=this._rawminPrice;    
    this.fnReLoadItemList();


  }

  fnRemoveMaxPrice(){
    this._maxPrice=this._rawmaxPrice;    
    this.fnReLoadItemList();
  }

  fnRemoveAll(){
    this._parabrandColl=[];
    this._paravendorColl=[];
    this._minPrice=this._rawminPrice;    
    this._maxPrice=this._rawmaxPrice;   
    this.fnReLoadItemList();
  }


  //Custom Page--------------
  //Get  Page
  fngetPageList() { 
    

    
    this.isempty=false;
    var data = {
      'pageid':this._pageid,            
    }

    this.homeservice.fnGetPage(data)
      .subscribe(
        (res)=>{          
          this.fnHideProcess();                
          if(res.json().status==200){    
          
            let _pageColl=res.json().result.pageinfo;         
            let _pagelayout=res.json().result.pagelayout; 
            let _pagedetails=res.json().result.pagedetails; 

            this.pageColl=[];

            if (_pageColl.length===1){
              for(let pbo of _pagelayout){
                var pagedetails = []
                for(let mbo of _pagedetails){
                  if(mbo.pagelayoutid===pbo.pagelayoutid){
                    pagedetails.push(mbo)
                  }
                }            
                pbo.pagedetails=pagedetails;  
                this.pageColl.push(pbo);
            }

            }

        

          
          
              let el = document.getElementById("scrollfcus");
              el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

          
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnHideProcess();
          this.fnShowErrorMsg('Error'+ err);
        })
  }

  fnPageBannerHyperlinkClick(index,di){
    if(index<0){
      return;
    }
    if(di<0){
      return;
    }

    let linktype='';
    let linktargetid='';

    linktype=this.pageColl[index].pagedetails[di].linktype;
    linktargetid=this.pageColl[index].pagedetails[di].linktargetid;

  
    if (linktype===null){
      return;
    }
    if (linktargetid ===null){
      return;
    }
    
    //Get Url Details In Id...
    var data = {
      'linktype':linktype,            
      'linktargetid': linktargetid,                    
    }
  
  
  this.homeservice.fnGetTargetDetails(data)
      .subscribe(
        (res)=>{                  
        
          if(res.json().status==200){  
                                  
            var result=res.json().result[0];
          
            let _deptid=result.deptid;
            let _categoryid=result.categoryid;
            let _subcategoryid=result.subcategoryid;
            let _brandid=result.brandid;

            let _deptname=result.deptname;
            let _categoryname=result.categoryname;
            let _subcategoryname=result.subcategoryname;
            let _brandname=result.brandname;

            let _deptseoname=result.deptseoname
            let _categoryseoname=result.categoryseoname;
            let _subcategoryseoname=result.subcategoryseoname;
            let _brandseoname=result.brandseoname;

            if(linktype==='DEPARTMENT'){
              _deptname= this.fnRemoveSpecialCharater(_deptname);
              var _deptpara=_deptid+"-"+_deptname;        
              if (_deptseoname){
                _deptpara=_deptseoname;
              }             
              this.router.navigate(['/'+_deptpara]);    
            }
            else if(linktype==='CATEGORY'){

              _deptname= this.fnRemoveSpecialCharater(_deptname);
              var _deptpara=_deptid+"-"+_deptname;        
              if (_deptseoname){
                _deptpara=_deptseoname;
              }

              _categoryname= this.fnRemoveSpecialCharater(_categoryname);
              var _catepara=_categoryid+"-"+_categoryname;        
              if (_categoryseoname){
                _catepara=_categoryseoname;
              }             
              this.router.navigate(['/'+_deptpara+"/"+_catepara]);    
            }
            else if(linktype==='SUBCATEGORY'){
              _deptname= this.fnRemoveSpecialCharater(_deptname);
              var _deptpara=_deptid+"-"+_deptname;        
              if (_deptseoname){
                _deptpara=_deptseoname;
              }

              _categoryname= this.fnRemoveSpecialCharater(_categoryname);
              var _catepara=_categoryid+"-"+_categoryname;        
              if (_categoryseoname){
                _catepara=_categoryseoname;
              }         


              _subcategoryname= this.fnRemoveSpecialCharater(_subcategoryname);
              var _subcatepara=_subcategoryid+"-"+_subcategoryname;        
              if (_subcategoryseoname){
                _subcatepara=_subcategoryseoname;
              }             
              this.router.navigate(['/'+_deptpara+"/"+_catepara+"/"+_subcatepara]);                  
            }
            else if(linktype==='BRAND'){
              _brandname= this.fnRemoveSpecialCharater(_brandname);
              var _brandpara=_brandid+"-"+_brandname;        
              if (_brandseoname){
                _brandpara=_brandseoname;
              }             
              this.router.navigate(['/'+_brandpara]);    
            }


                            
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
  })


  }

  private fnShowProcess(){
    this.isload=false;
  }
  private fnHideProcess(){
    this.isload=true;
  }

  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "");
    return str;
  }

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }


}
