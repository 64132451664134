

export const AppSettings = {
   
   // base_url: 'http://200.1.50.108:8500/',
   //  base_url: 'http://13.127.71.165:7100/',
    base_url: 'https://apinativelovers.renoinfo.in/',

    //base_url:  'http://192.168.0.181:8500/',
    
   
    authKey:'WZvRKjZ8xnXhwEQKEMJvHg==',

    getDepartmentUrl:'dashboard/getdepartment',
    getCategoryUrl:'dashboard/getcategory',
    getSubCategoryUrl:'dashboard/getsubcategory',
    getBrandUrl:'dashboard/getbrand',
    getpage:'dashboard/getpage',
    geturldetails:'dashboard/geturldetails',
    gettargetdetails:'dashboard/gettargetdetails',

    getMainBannerUrl:'dashboard/getmainbanner',
    getDashboardUrl:'dashboard/getdashboard',
    getDealofthedayUrl:'dashboard/getdealsoftheday',

    getItemListUrl:'dashboard/getitemlist',
    getItemListFilterUrl:'dashboard/getitemlistfilter',
    getItemDetailsUrl:'dashboard/getitemdetails',
    getItemSectionListUrl:'dashboard/getitemsectionlist',
    getItemSectionListFilterUrl:'dashboard/getitemsectionlistfilter',
    getItemAutoCompleteUrl:'dashboard/getitemautocomplete',

    insertCartProductUrl:'dashboard/insertcartproduct',
    getCartListUrl:'dashboard/getcart',    
    setcustomerlogin:'dashboard/setcartcustomerlogin',

    getCartAvailablityUrl:'dashboard/getcartavailability',    
    SetCartAvailablityUrl:'dashboard/setcartavailability',    

    getorderbycustomer:'customer/getorderbycustomer',
    getcustomer:'customer/getcustomer',
    insertcustomer:'customer/insertcustomer',
    setcustomer:'customer/setcustomer',
    checkcustomersignup:'customer/checkcustomersignup',
    checkcustomerlogin:'customer/checkcustomerlogin',
    sendotp:'customer/sendotp',
    getcustomerlogin:'customer/getcustomerlogin',
    getcommlanguage:'customer/getcommlanguage',
    getcustomeralertdetails:'customer/getcustomeralertdetails',

    
    insertcustomeraddress:'customer/insertcustomeraddress',
    setcustomeraddress:'customer/setcustomeraddress',
    getcustomeraddress:'customer/getcustomeraddress',
    deletecustomeraddress:'customer/deletecustomeraddress',
    getCityUrl:  'city/getcity',
    getlocationbygeocode:'dashboard/getlocationbygeocode',

    setcustomerwishlist:'customer/setwishlist',
    setcustomernotifyme:'customer/setnotifyme',
    
    insertcustomerreviews:'dashboard/insertcustomerreviews',

    insertorder:'dashboard/insertorder',
    getorder:'dashboard/getorder',
    setcartaddress:'dashboard/setcartaddress',    
    setcancelorder:'dashboard/setcancelorder',
    
    getinvoicebyorder:'dashboard/getinvoicebyorderid',

    getcouponbyorder:'dashboard/getcouponbyorder',
    setcouponbyorder:'dashboard/setcouponbyorder',

    getpaymodebyorder:'dashboard/getpaymodebyorder',
    getnetbanking:'dashboard/getnetbanking',

    getcustomerwallet:'customer/getcustomerwallet',
    setredeempoints:'customer/setredeempoints',    
    getcustomerloyalty:'customer/getcustomerloyalty',
    getcustomerwishlist:'customer/getcustomerwishlist',


    getcontentmanagement:'contentmanagement/getcontentmanagement',

    getsettingsbytype:'dashboard/getsettingsbytype',


    getdeliverytypedash:'dashboard/getdeliverytypedash',


    paycardpayment:'dashboard/paycardpayment',

}