import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  walletColl:any=[];

  walletsummaryamt=0;

  fromdate:string="";
  todate:string="";

  
  customerBO:any={};  
  customerView:any={};

  constructor(public loginservice: LoginService,private datePipe: DatePipe) { this.fnServiceChanges();}

  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value      
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain;                          
        }      

      
    }); 



  }


  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
      this.fnGetWallet();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
    
  }

  
  fnGetWallet(){  
    var data = {  
      'fromdate':this.fnFormatDatetime(this.fromdate),
      'todate':this.fnFormatDatetime(this.todate)        
    }

  
    this.loginservice.fnGetWalletInCustomer(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                        
              this.walletColl=res.json().result;  
              this.fnCalcWalletAmount();
                                
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }


fnCalcWalletAmount(){
  for(let abo of  this.walletColl){
    this.walletsummaryamt=this.walletsummaryamt+(abo.cramt-abo.dramt);
  }
}
  

fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }
}
