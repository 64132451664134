import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../constants/contants';
import { Observable, EMPTY, Subject } from 'rxjs';
import { retry, catchError, shareReplay } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public _opened: boolean = false;
  public _orderopened: boolean = false;
  public _offeritemsopened: boolean = false;
  public _couponopened: boolean = false;

  public _bottomcustomeropened: boolean = false;

  


  servicecartChange: Subject<boolean> = new Subject<boolean>();
  serviceorderChange: Subject<boolean> = new Subject<boolean>();
  serviceofferitemChange: Subject<boolean> = new Subject<boolean>();
  servicecouponChange: Subject<boolean> = new Subject<boolean>();

  cartBO:any={};
  cartColl:any=[];
  cartView:any={};

  constructor(private http: Http, public router: Router, private translate: TranslateService) {
   // this.fnGetCartList();
  }



  fnpostInputParams(url, params) {
    const headers = new Headers({
        //'Content-Type': "application/x-www-form-urlencoded",
        //'Content-Type':  "application/JSON",
        'token': (localStorage.token === undefined) ? '' : localStorage.token,
        'Session_id': localStorage.session,
        'terminal':"web",
        'whid': (localStorage.wh === undefined) ? '' : localStorage.wh,
        'authkey':AppSettings.authKey
    });
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }


  //InsertCart
  fnInsertCartProduct(params){    
    return this.fnpostInputParams(AppSettings.insertCartProductUrl,params);
  }
  
  //Get Cart
  fnGetCartList(){    
    var data = {
      'customerid':0,            
      'sessionid': '',            
    }

    this.fnpostInputParams(AppSettings.getCartListUrl,data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){  
          
          let _res=res.json().result;     

          //Main
          let _cartColl=_res.cartmain;     
          if(_cartColl.length>0){
            this.cartBO=_cartColl[0];  
          }else{
            this.cartBO={};
          }
          //Details                          
          this.cartColl=_res.cartdetails;      
          

          this.cartView={
            "cartmain":this.cartBO,
            "cartdetails":this.cartColl
          }
          
          this.servicecartChange.next(this.cartView);
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })


  }




 //Set Customer Notify Me 
 fnSetCustomerNotifyMe(params){    
  return this.fnpostInputParams(AppSettings.setcustomernotifyme,params);
}


  public fnCheckSlide(){

    this.fnGetCartList();
    this._opened = !this._opened;
  }

  public fnCheckSlideOrder(orderid){       
    this.serviceorderChange.next(orderid);
    this._orderopened = !this._orderopened;
  }


  public fnCheckSlideOfferItems(offeritemsColl){       
    this.serviceofferitemChange.next(offeritemsColl);
    this._offeritemsopened = !this._offeritemsopened;
  }

  public fnCheckSlideCoupon(){       
   this.servicecouponChange.next();
    this._couponopened = !this._couponopened;
  }




  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }


}
