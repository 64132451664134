import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../constants/contants';
import { Observable, EMPTY, Subject } from 'rxjs';
import { retry, catchError, shareReplay } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  servicecustomerChange: Subject<boolean> = new Subject<boolean>();
  serviceloginChange: Subject<boolean> = new Subject<boolean>();

  customerBO:any={};
  customerOrderMainColl:any=[];
  customerOrderDetailsColl:any=[];
  customerAddressColl:any=[];
  customerWishlistColl:any=[];
  customerLoyaltyColl:any=[];
  customerWalletColl:any=[];
  customerSummaryColl:any=[];
  customerView:any={};

  //public _loginopened: boolean = false;

  constructor(private http: Http, public router: Router) {
    this.fnGetCustomerList();
  }


  fnOpenLogin(){
    this.serviceloginChange.next(true);    
  }

  
  fnpostInputParams(url, params) {
    const headers = new Headers({
        //'Content-Type': "application/x-www-form-urlencoded",
       // 'Content-Type':  "application/JSON",
        'token': (localStorage.token === undefined) ? '' : localStorage.token,
        'Session_id': localStorage.session,
        'terminal':"web",
        'whid': (localStorage.wh === undefined) ? '' : localStorage.wh,
        'authkey':AppSettings.authKey
    });
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }


  
  //Get Customer List
  fnGetCustomerList(){    
    var data = {
      'customerid':0,                          
    }

    this.fnpostInputParams(AppSettings.getcustomer,data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){  
       
          //Main
          this.fnSetCustomerDetailsData(res);

          // let _cartColl=res.json().result[0];     
          // if(_cartColl.length>0){
          //   this.customerBO=_cartColl[0];  
          // }   
          // else{
          //   this.customerBO={}
          // }         

          // this.customerOrderMainColl=res.json().result[1];
          // this.customerOrderDetailsColl=res.json().result[2];
          // this.customerAddressColl=res.json().result[3];
          // this.customerWishlistColl=res.json().result[4];
          // this.customerLoyaltyColl=res.json().result[5];

          this.customerView={
            "customermain":this.customerBO, 
            "ordermain":this.customerOrderMainColl,
            "orderdetails":this.customerOrderDetailsColl,
            "customeraddress":this.customerAddressColl,
            "customerwishlist":this.customerWishlistColl,
            "customerloyalty":this.customerLoyaltyColl,
            "customerwallet":this.customerWalletColl,
          }
         

          this.servicecustomerChange.next(this.customerView);
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })

  }

  fnSetCustomerDetailsData(res){
    var _res =res.json().result; 



    let _cartColl=_res.customerinfo;     
    if(_cartColl.length>0){
      this.customerBO=_cartColl[0];  
    }   
    else{
      this.customerBO={}
    }     

    if(_res.ordermain){
      this.customerOrderMainColl=_res.ordermain;
    }
    if(_res.orderdetails){
      this.customerOrderDetailsColl=_res.orderdetails;
    }
    if(_res.address){
      this.customerAddressColl=_res.address;
    }
    if(_res.favourite){
      this.customerWishlistColl=_res.favourite;
    }
    if(_res.loyalty){
      this.customerLoyaltyColl=_res.loyalty;
    }
    
    if(_res.wallet){
      this.customerWalletColl=_res.wallet;
    }
    if(_res.summary){
      this.customerSummaryColl=_res.summary;
      //Wallet Calculate Summary
      let walletsummaryamt=0;
      for(let abo of   this.customerSummaryColl){
        walletsummaryamt=abo.walletsummary;
      }

      if(this.customerBO){
          this.customerBO.walletsummaryamt=walletsummaryamt;
      }


    }

    
      

  }

  //Check Sign Up
  fnCheckCustomerSignup(params){    
    return this.fnpostInputParams(AppSettings.checkcustomersignup,params);
  }

  
  //Check Login
  fnCheckCustomerLogin(params){    
    return this.fnpostInputParams(AppSettings.checkcustomerlogin,params);
  }

  //InsertCustomerSignup
  fnInsertCustomerSignup(params){    
    return this.fnpostInputParams(AppSettings.insertcustomer,params);
  }

  fnSetCustomer(params){    
    return this.fnpostInputParams(AppSettings.setcustomer,params);
  }


  //SendOtp
  fnSendOtp(params){    
    return this.fnpostInputParams(AppSettings.sendotp,params);
  }


  //Get Customer Login
  fnGetCustomerLogin(params){    
    return this.fnpostInputParams(AppSettings.getcustomerlogin,params);
  }

   //Set Cart Customer Login
   fnSetCartCustomerLogin(params){    
    return this.fnpostInputParams(AppSettings.setcustomerlogin,params);
  }

    //Get Customer AlertDetails
  fnGetCustomerAlertDetails(params){    
    return this.fnpostInputParams(AppSettings.getcustomeralertdetails,params);
  }



//Customer Address------------------------------

  //Insert Customer Address 
  fnInsertCustomerAddress(params){    
    return this.fnpostInputParams(AppSettings.insertcustomeraddress,params);
  }
  
  //Set Customer Address 
  fnSetCustomerAddress(params){    
    return this.fnpostInputParams(AppSettings.setcustomeraddress,params);
  }

  //Get Customer Address 
  fnGetCustomerAddress(params){    
    return this.fnpostInputParams(AppSettings.getcustomeraddress,params);
  }

   //Delete Customer Address 
  fnDeleteCustomerAddress(params){    
    return this.fnpostInputParams(AppSettings.deletecustomeraddress,params);
  }

  //Get City
  fnGetCity(params){    
    return this.fnpostInputParams(AppSettings.getCityUrl,params);
  }

   //Get Area
   fnGetLocationbyGeocode(params){    
    return this.fnpostInputParams(AppSettings.getlocationbygeocode,params);
  }


//Customer Wishlist------------------------------

 //Get Customer Wishlist 
 fnGetCustomerWishlist(params){    
  return this.fnpostInputParams(AppSettings.getcustomerwishlist,params);
  }


 //Set Customer Wishlist 
 fnSetCustomerWishlist(params){    
  return this.fnpostInputParams(AppSettings.setcustomerwishlist,params);
  }


fnInsertCustomerReviews(params){    
    return this.fnpostInputParams(AppSettings.insertcustomerreviews,params);
}
  
  

  //Get Order in Customer
  fnGetOrderInCustomer(params){    
    return this.fnpostInputParams(AppSettings.getorderbycustomer,params);
  }


  
//Cancel Order in Customer
fnSetCancelOrder(params){    
  return this.fnpostInputParams(AppSettings.setcancelorder,params);
}

//Get Order in Invoice
fnGetInvoiceByOrder(params){    
    return this.fnpostInputParams(AppSettings.getinvoicebyorder,params);
}







//Get Communication Language
fnGetCommunicationLanguage(params){    
  return this.fnpostInputParams(AppSettings.getcommlanguage,params);
}

  //Get Wallet in Customer
  fnGetWalletInCustomer(params){    
    return this.fnpostInputParams(AppSettings.getcustomerwallet,params);
  }

  
  //Set Redeem Points In Customer
  fnSetRedeemPointstInCustomer(params){    
    return this.fnpostInputParams(AppSettings.setredeempoints,params);
  }

   
 //Get Loyalty in Customer
 fnGetLoyaltyInCustomer(params){    
  return this.fnpostInputParams(AppSettings.getcustomerloyalty,params);
}


    
 private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
