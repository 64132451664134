import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-itemcart',
  templateUrl: './itemcart.component.html',
  styleUrls: ['./itemcart.component.css']
})
export class ItemcartComponent implements OnInit {


  @Input() productid = 0;
  @Input() skuid = 0;
  @Input() index = 0;
  @Input() productname = '';
  @Input() picurl = '';
  @Input() uomqty = '';
  @Input() uom = '';
  @Input() mrp = 0;
  @Input() sellingprice =0;  
  @Input() qty:number = 0;
  @Input() isnew:boolean = false;
  @Input() certificatepic = '';
  @Input() foodsymbolpic = '';
  @Input() offertxt = '';
  @Input() savingtxt = '';
  @Input() isoutofstock:boolean = false;
  @Input() minqtybyorder =0;  
  @Input() maxqtybyorder =0;  
  @Input() itemtype = '';
  
  @Output() productaddChanged = new EventEmitter<number>();
  @Output() productincrementChanged = new EventEmitter<number>();
  @Output() productdecrementChanged = new EventEmitter<number>();
  @Output() productdetailsChanged = new EventEmitter<number>();


 
  
  constructor(public router: Router,public cartservice:CartService) { }

  ngOnInit() {
    try{
  
            
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  fnAddProductBtnClick() {
    var productBO:any={
        productid:this.productid,
        skuid:this.skuid,
        index:this.index,
        qty:this.qty
    }
    this.productaddChanged.emit(productBO);
  }

  fnProductIncrementBtnClick() {
  
    
    var productBO:any={
      productid:this.productid,
      skuid:this.skuid,
      index:this.index,
      qty:this.qty
   }
    this.productincrementChanged.emit(productBO);
  }

  fnProductDecrementBtnClick() {

    var productBO:any={
      productid:this.productid,
      skuid:this.skuid,
      index:this.index,
      qty:this.qty
   }

    this.productdecrementChanged.emit(productBO);
  }
 

  
  fnProductDetailsBtnClick() {


    var _productidid=this.skuid;    
    var _productidname=this.productname;
    _productidname= this.fnRemoveSpecialCharater(_productidname);
    var _para=_productidid+"-"+_productidname;

    this.router.navigate(['/p/'+_para]);

  }

  fnNotifyMeProductClick(){

      var data = {
        'customerid':0,            
        'sessionid': '',            
        'productid': this.productid, 
        'skuid':this.skuid,          
      }

      this.cartservice.fnSetCustomerNotifyMe(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){     
          
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
    
  }


  


  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "-");
    return str;
  }
  
  
  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }
}
