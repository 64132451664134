import { Component, OnInit, Input } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-ordersdetails',
  templateUrl: './ordersdetails.component.html',
  styleUrls: ['./ordersdetails.component.css']
})
export class OrdersdetailsComponent implements OnInit {

  checkname:string="";
  OrderMainColl:any=[];
  OrderMainBO:any={};
  OrderDetailsColl:any=[];
  OrderStatusColl:any=[];
  OrderPayColl:any=[];

  isratings:boolean=false;
  
  ratingsform=new FormGroup({
    productid:new FormControl(null), 
    skuid:new FormControl(null), 
    ratings:new FormControl(0),       
    reviewtext:new FormControl('',Validators.required),         
    reviewtitle:new FormControl(''),
    productname:new FormControl(''),
    picurl:new FormControl('')                       
  });

  iscancelorder:boolean=false;

  cancelorderform=new FormGroup({
    orderid:new FormControl(null), 
    canceldesc:new FormControl('',Validators.required),            
  });

  //Cancel Validation
  cancelsubmitted:boolean;
  cancelsuccessfully:boolean;


  //Save Validation
  submitted:boolean;
  successfully:boolean;

  constructor(public cartservice: CartService,public checkoutservice: CheckoutService,public loginservice:LoginService) { 


     this.cartservice.serviceorderChange.subscribe((value) => {
      this.fnGetOrderDetails(value);    
     }); 

  }

  ngOnInit() {
       this.fnInitializeReviewsFormGroup();
  }

  
  fnInitializeReviewsFormGroup(){
    this.ratingsform.setValue({
      productid:null,
      skuid:null,
      ratings:0,
      reviewtext:'',
      reviewtitle:'',
      productname:'',
      picurl:''
    })
  }

  
  
  fnInitializeCancelOrderFormGroup(){
    this.cancelorderform.setValue({
      orderid:null,
      canceldesc:'',
    })
  }

  fnGetOrderDetails(orderid){
      var data = {
        'orderid':orderid
      }

    
    this.checkoutservice.fnGetOrder(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){              
              this.isratings=false;
              let _result=res.json().result;
              this.OrderMainColl=_result.ordermain;     
                    
              if(this.OrderMainColl.length>0){            
                this.OrderMainBO=this.OrderMainColl[0];
              }            
                        
              this.OrderDetailsColl=_result.orderdetails;  
              this.OrderPayColl=_result.orderpay;            
              this.OrderStatusColl=_result.orderstatus;                   
              
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }
  

  fnRatingsBtnClick(index){

      if(index<0){
        return;
      }

      this.fnInitializeReviewsFormGroup();

      this.ratingsform.patchValue({
        productid:this.OrderDetailsColl[index].productid,
        skuid:this.OrderDetailsColl[index].skuid,
        ratings:this.OrderDetailsColl[index].reviewratings,
        reviewtext:this.OrderDetailsColl[index].reviewtext,
        reviewtitle:this.OrderDetailsColl[index].reviewtitle,  
        productname:this.OrderDetailsColl[index].productname,   
        picurl:this.OrderDetailsColl[index].picurl,                   
      })

      this.isratings=true;
  }

  fnSubmitReviews(){
    try{      
      this.submitted=true;
      this.successfully=false;
      //Validate
      if(this.ratingsform.valid){
        var data = {
          'productid':this.ratingsform.get('productid').value,
          'customerid':0,
          'skuid':this.ratingsform.get('skuid').value,
          'ratings':this.ratingsform.get('ratings').value,               
          'reviewtitle':this.ratingsform.get('reviewtitle').value,               
          'reviewtext':this.ratingsform.get('reviewtext').value,                  
      }

      
            this.loginservice.fnInsertCustomerReviews(data)
            .subscribe(
              (res)=>{                  
              if(res.json().status==200){        
                this.isratings=false;               
                this.fnInitializeReviewsFormGroup();
                this.cartservice.fnCheckSlideOrder(0);
              }
              else if(res.json().status==400){                          
                this.fnShowErrorMsg('Error'+res.json().err_field);
              }           
            },
            (err)=>{          
              this.fnShowErrorMsg('Error'+ err);
            })
      


      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnClose(){
    this.isratings=false;               
    this.fnInitializeReviewsFormGroup();
    this.cartservice.fnCheckSlideOrder(0);
  }

  
  /////----------------------------------------------Cancel Order////-----------------
  fnCancelOrderBtnClick(){
    this.fnInitializeCancelOrderFormGroup();
    this.cancelorderform.patchValue({
      orderid:this.OrderMainBO.orderid,
      canceldesc:"",      
    })

    this.iscancelorder=true;
  }

  fnSubmitCancelOrder(){
    try{      
      this.cancelsubmitted=true;
      this.cancelsuccessfully=false;
      //Validate
      if(this.cancelorderform.valid){
        var data = {
          'orderid':this.cancelorderform.get('orderid').value,
          'canceldesc':this.cancelorderform.get('canceldesc').value,                 
        }

      
            this.loginservice.fnSetCancelOrder(data)
            .subscribe(
              (res)=>{                  
              if(res.json().status==200){        
                this.iscancelorder=false;               
                this.fnInitializeCancelOrderFormGroup();
                this.cartservice.fnCheckSlideOrder(0);
                this.loginservice.fnGetCustomerList();
              }
              else if(res.json().status==400){                          
                this.fnShowErrorMsg('Error'+res.json().err_field);
              }           
            },
            (err)=>{          
              this.fnShowErrorMsg('Error'+ err);
            })
      


      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnCancelOrderClose(){
    this.iscancelorder=false;               
    this.fnInitializeCancelOrderFormGroup();
    this.cartservice.fnCheckSlideOrder(0);
  }


  fngetInvoiceByOrder(){
  
        var data = {
          'orderid':this.OrderMainBO.orderid,                 
        }

      
        this.loginservice.fnGetInvoiceByOrder(data)
          .subscribe(
            (res)=>{                  
              if(res.json().status==200){     
                let url=res.json().result.downloadurl;
                window.open(url, "_blank");    
          
              }
              else if(res.json().status==400){                          
                this.fnShowErrorMsg('Error'+res.json().err_field);
              }           
            },
            (err)=>{          
              this.fnShowErrorMsg('Error'+ err);
          })
      


     
  }


  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }


}
