import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  contentColl:any=[];
  contentBO:any={}


  constructor(private homeservice: HomeService) { }

  ngOnInit() {

    this.fngetContentManagement();
  }

  fngetContentManagement() { 
    var data = {
      'contentid':0,
      'contenttype':'TERMSANDUSE'            
    }
   this.homeservice.fnGetContentManagement(data)
       .subscribe(
         (res)=>{                  
           if(res.json().status==200){                          
             this.contentColl=res.json().result;      
           
             if(this.contentColl.length>0){
               this.contentBO=this.contentColl[0];
             }
           }
           else if(res.json().status==400){                          
             this.fnShowErrorMsg('Error'+res.json().err_field);
           }
          
         },
         (err)=>{          
           this.fnShowErrorMsg('Error'+ err);
         })
 }


 private  fnShowErrorMsg(ex){
  console.log(ex);       
}

}
