import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobsearch',
  templateUrl: './mobsearch.component.html',
  styleUrls: ['./mobsearch.component.css']
})
export class MobsearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
