import { Component, OnInit } from '@angular/core';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {

  couponColl:any=[];

  constructor(public checkoutservice: CheckoutService) { }

  ngOnInit() {
    this.fnGetCoupon();
  }

  
  fnGetCoupon(){  
    var data = {
      'couponcode':''
    }

  
    this.checkoutservice.fnGetCoupon(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                        
              this.couponColl=res.json().result;  
                                
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }



}
