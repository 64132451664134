import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CheckoutService } from 'src/app/services/checkout.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomeraddressComponent } from '../customeraddress/customeraddress.component';
import { Router } from '@angular/router';
import { CartavailablityComponent } from '../cartavailablity/cartavailablity.component';
import { HomeService } from 'src/app/services/home.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoginComponent } from '../login/login.component';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
    @ViewChild('ngOtpInput',{static:false}) ngOtpInputRef:any;

    @ViewChild('dataContainer',{static:false}) dataContainer: ElementRef;

    settingsColl:any=[];

    countrycode:string="+971";
    mobilecode:any=[{value:50,displaytxt:50}];

    cartBO:any={};
    cartColl:any=[];
    cartView:any={};

    customerBO:any={};  
    customerView:any={};

    //Accordin Control
    activeIds: string[] = [];

    //Stage Variables
    isaccountstagevalid:boolean=false;
    isaddressstagevalid:boolean=false;
    isdeliverytypestagevalid: boolean = false;
  
    //Mode Values
    isloginorsignup:boolean=false;
    issignup:boolean=false;
    islogin:boolean=false;
    isotpverification:boolean=false;  
    isordersuccess:boolean=false;
  

    //OTP verification
    otpverifytype:string="";
    sendotpdata:any={}
    otpverificationno:string="";    

    //Save Validation
    signupsubmitted:boolean;
    signupsuccessfully:boolean;
    loginsubmitted:boolean;
    loginsuccessfully:boolean;

    cardsubmitted:boolean;
    cardsuccessfully:boolean;

    addressColl:any=[]; 
    addressBO: any = {}; 
  
    deliverytypeColl:any=[]; 
    deliverytypeBO: any = {}; 
  
    paymodeColl:any=[];
    selectpaymodemode:string=""
    netbankingColl:any=[];
    selectnetbanking:string=""
    walletamt:number=0;

    successorderno:string=""

    
    otpscreen: SafeHtml;

  constructor(private checkoutservice: CheckoutService,private loginservice: LoginService,
    private cartservice: CartService,private modelService: NgbModal,public router: Router,public homeservice:HomeService,
    private _sanitizer:DomSanitizer) {
    checkoutservice.ischeckoutloaded=true;
    this.fnServiceChanges();
  }

  fnServiceChanges(){

    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain       
        this.fnCheckInCheckOut();
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }
    });    


    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value    
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                   
        }      
      this.fnCheckInCheckOut();
      
      this.fnGetDeliveryType();
    }); 
  }


  signupform=new FormGroup({
    firstname:new FormControl('',Validators.required),         
    lastname:new FormControl('',Validators.required),
    email:new FormControl('',[Validators.required, Validators.email]), 
    mobile:new FormControl('',[Validators.required,Validators.pattern("^((\\+971-?)|0)?[0-9]{7}$")]),
    prefixmobile:new FormControl(''),                
  });

  loginform=new FormGroup({
    mobile:new FormControl('',[Validators.required,Validators.pattern("^((\\+971-?)|0)?[0-9]{7}$")]),    
    prefixmobile:new FormControl(''),            
  });
  
  cardform=new FormGroup({
    cardnumber:new FormControl('',Validators.required),         
    expirydt:new FormControl('',Validators.required),
    cvvnumber:new FormControl('',Validators.required), 
    cardcustomername:new FormControl('',Validators.required),                
  });


  ngOnInit() {
    try{      
      this.fnGetSettingsByType();

      this.signupform.reset();
      this.fnInitializeSignupFormGroup(); 

      this.loginform.reset();
      this.fnInitializeLoginFormGroup();
                    
      this.loginservice.fnGetCustomerList();
      this.fnGetPaymentModeByOrder();
      this.fnGetNetbanking();
    

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      //this.fnCheckCartAvilability('PAGELOAD')
    }, 5000);
   // 
  }

  //---------------------GET MY IP
 



  ///--------------------------------------------CHECK OUT CHECK-----------------------------------//
  fnCheckInCheckOut(){
   
    this.isaccountstagevalid=false;
    this.activeIds = ['panel-1'];
    if(this.customerBO.customerid && !this.addressBO.addressid && this.cartBO){
      this.activeIds = ['panel-2']; 

      this.fnGetCustomerAddress();     
      this.isaccountstagevalid=true;
    }
    else if(this.addressBO.addressid && !this.deliverytypeBO.deliverytypeid ){
      this.activeIds = ['panel-3'];       
      this.isaddressstagevalid=true;    
    }    
    else if (this.deliverytypeBO.deliverytypeid) {
      
      this.activeIds = ['panel-4'];       
      this.isdeliverytypestagevalid=true;
      this.selectpaymodemode='CASH';
    }
   
  }
  ///-----------------------------------------------------CART--------------------------------------//
  
  fnProductIncrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty+1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }

  fnProductDecrementBtnClick(prodBO){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': prodBO.productid, 
      'skuid':prodBO.skuid,
      'qty':prodBO.qty-1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
  }



  ///------------------------------------------------------STEP 1-----------------------------------//
  fnInitializeSignupFormGroup(){
    this.signupform.setValue({
      firstname:'',      
      lastname:'',      
      email:'',      
      mobile:'',   
      prefixmobile:this.fnGetDefaultMobileCode(),       
    })
  }

  fnInitializeLoginFormGroup(){
    this.loginform.setValue({    
      mobile:'',    
      prefixmobile:this.fnGetDefaultMobileCode(),      
    })
  }
  
  fnInitializeCardFormGroup(){
    this.cardform.setValue({    
      cardnumber:'',        
      expirydt:'',        
      cvvnumber:'',        
      cardcustomername:'',        
    })
  }

  
  fnGetSettingsByType() { 
    var data = {   
      'settingstype':'LOGIN'            
    }
    this.homeservice.fnGetSettingsByType(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this.settingsColl=res.json().result;           
              
              const index = this.settingsColl.findIndex(a => a.settingsname ==='COUNTRYCODE' );
              if(index>-1){
                this.countrycode=this.settingsColl[index].settingsvalue;
              }

              const index1 = this.settingsColl.findIndex(a => a.settingsname ==='MOBILECODE' );
              if(index1>-1){
                let mcode= this.settingsColl[index1].settingsvalue.split(",");
                
                this.mobilecode=[];
                if(mcode.length){
                  for(let wbo of mcode){
                    this.mobilecode.push({value:wbo,displaytxt:wbo});
                  }
                }
                
              }

            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
            
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fnGetDefaultMobileCode(){

    if(this.mobilecode.length){
      let a=this.mobilecode[0].value;     
      return a;
    }else{
      return "";
    }
  }




  fnChangeType(type){
    // this.isloginorsignup=true;
    // if(type==="LOGIN"){
    //   this.issignup=false;
    //   this.islogin=true;
    //   this.loginsubmitted=false;
    //   this.loginsuccessfully=false;
    //   this.isotpverification=false;
    //   this.loginform.reset();
    //   this.fnInitializeLoginFormGroup();
    // }
    // else if(type==="SIGNUP"){
    //   this.issignup=true;
    //   this.islogin=false;
    //   this.signupsubmitted=false;
    //   this.signupsuccessfully=false;
    //   this.isotpverification=false;
    //   this.signupform.reset();
    //   this.fnInitializeSignupFormGroup(); 
    // }

    const modalRef = this.modelService.open(LoginComponent ,{ size: 'lg',backdropClass: 'light-blue-backdrop',centered: true ,backdrop : 'static',
                        keyboard : false});
    if(type==="LOGIN"){
      modalRef.componentInstance.islogin =true;
    }else if(type==="SIGNUP"){
      modalRef.componentInstance.issignup =true;
    }

    modalRef.componentInstance.emitData.subscribe((loginEntry) => {
     
      this.fnGetLoginCustomer(loginEntry)
      
    });
    
  }

  fnSignupBtnClick(){
    try{      
      this.signupsubmitted=true;
      this.signupsuccessfully=false;
      //Validate
      if(this.signupform.valid){
        let mobile=this.signupform.get('prefixmobile').value + this.signupform.get('mobile').value;

        var data = {
          'firstname':this.signupform.get('firstname').value,               
          'lastname':this.signupform.get('lastname').value,               
          'mobile':mobile,               
          'email':this.signupform.get('email').value,     
          'otp':'',
          'countrycode':this.countrycode
       }
       this.loginservice.fnCheckCustomerSignup(data)
          .subscribe(
            (res)=>{             
                
            if(res.json().status==200){                
              var _checkresult=res.json().result.checkstatus;
              var _otp=res.json().result.otp;
             
              if(_checkresult==="done"){
                
                this.otpverifytype="SIGNUP";
                this.fnSendOTP(data,_otp);
              } 
              else{
             
                if(_checkresult==="mobile"){
                  this.signupform.controls['mobile'].setErrors({'notfound': true});
                }
                else if(_checkresult==="email"){
                  this.signupform.controls['email'].setErrors({'notfound': true});
                }
              }
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })



      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnLoginBtnClick(){
    try{      
      this.loginsubmitted=true;
      this.loginsuccessfully=false;
      //Validate
      if(this.loginform.valid){
        let mobile=this.loginform.get('prefixmobile').value + this.loginform.get('mobile').value;
        var data = {             
          'mobile':mobile,               
       }

       this.loginservice.fnCheckCustomerLogin(data)
          .subscribe(
            (res)=>{                  
            if(res.json().status==200){                

              var _checkresult=res.json().result.checkstatus;

              if(_checkresult==='done'){
                var _otp=res.json().result.otp;              
                var _cusinfo=res.json().result.customerdata;
                
                var logindata = {           
                  'firstname':_cusinfo.firstname,  
                  'lastname':_cusinfo.lastname,  
                  'email':_cusinfo.email,                  
                  'mobile':_cusinfo.mobile,         
                  'otp':""
                }
                this.otpverifytype="LOGIN";
                this.fnSendOTP(logindata,_otp);
              } 
              else{
                this.fnChangeType("SIGNUP"); 
                this.signupform.patchValue({
                  firstname:'',      
                  lastname:'',      
                  email:'',      
                  mobile:this.loginform.get('mobile').value,        
                  prefixmobile:this.loginform.get('prefixmobile').value ,        
                })         
              }            
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })



      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  //OTP Verification
  fnSendOTP(data,otp){
       
        if(this.otpverifytype==="SIGNUP"){
          this.issignup=false;
        }else if(this.otpverifytype==="LOGIN"){
          this.islogin=false;
        }       
        this.isotpverification=true;         
        this.sendotpdata=data;
        this.otpverificationno="";
        this.sendotpdata.otp=otp;       

  }

  
  fnResendOtp(){

    this.loginservice.fnSendOtp(this.sendotpdata)
    .subscribe(
      (res)=>{                  
      if(res.json().status==200){        
        if(this.otpverifytype==="SIGNUP"){
          this.issignup=false;
        }else if(this.otpverifytype==="LOGIN"){
          this.islogin=false;
        }       
        this.isotpverification=true;              
        this.otpverificationno="";
        //this.isrequestotp=false;
        this.sendotpdata.otp=res.json().otp;       

        //this.startTimer();

      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }
     
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
  }

  fnOtpVerificationBackClick(){
    try{
      if(this.otpverifytype==="SIGNUP"){
        this.fnChangeType("SIGNUP"); 
      }
      else if(this.otpverifytype==="LOGIN"){
        this.fnChangeType("LOGIN"); 
      }
      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  //Final
  fnVerifyOTP(){
    this.otpverificationno=this.fnGetOTPContlValue(this.ngOtpInputRef.otpForm.value);
    if(this.otpverifytype==="SIGNUP"){
      this.fnInsertCustomerSignUp();
    }else if(this.otpverifytype==="LOGIN"){
      this.fnGetCustomerLogin();
    }

  }

  //SignUp Final
  fnInsertCustomerSignUp(){
  //Validate
      if(this.sendotpdata.otp!==this.otpverificationno){
          alert('Incorrect Otp');
      }else{
        
       this.loginservice.fnInsertCustomerSignup(this.sendotpdata)
          .subscribe(
            (res)=>{                  
            if(res.json().status==200){    
              
              let _checkstatus= res.json().result.checkstatus;
              if(_checkstatus=="done"){

                let _customerid=res.json().result.customerid
                this.fnGetLoginCustomer(_customerid)

              }else{
                alert(_checkstatus)
              }


        
            //  let _customerid=res.json().result[0].customerid;
            //    var _req={
            //      customerid:_customerid,     
            //      mobile:this.sendotpdata.mobile
            //    }
            //    this.fnGetLoginCustomer(_req);
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
      }
  }
  //Login Final
  fnGetCustomerLogin(){
  
    if(this.sendotpdata.otp!==this.otpverificationno){
      alert('Incorrect Otp');
    }else{

      this.loginservice.fnGetCustomerLogin(this.sendotpdata)
      .subscribe(
        (res)=>{                  
        if(res.json().status==200){                            
         
          let _checkstatus= res.json().result.checkstatus;
          if(_checkstatus=="done"){

            let _customerid=res.json().result.customerid
           
            this.fnGetLoginCustomer(_customerid);
          }else{
            alert(_checkstatus)
          }

        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }
       
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })      
       
    }
  }

  fnGetLoginCustomer(data){

    let _customerid=data;       
       localStorage.setItem('token', _customerid);
       this.loginservice.fnGetCustomerList();
       this.fnSetCartLoginCustomer();
       this.fnCheckInCheckOut();
       this.fnGetDeliveryType()
 

  }

  fnSetCartLoginCustomer(){
    var data = {
      'customerid': 0,            
      'sessionid':''
    }
    this.loginservice.fnSetCartCustomerLogin(data)
    .subscribe(
      (res)=>{                  
      if(res.json().status==200){                            
        this.cartservice.fnGetCartList();

      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }
     
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })

  }

  fnGetOTPContlValue(data){
    let _result="";
    
    if(data.ctrl_0){
      _result=_result+data.ctrl_0;
    }
    if(data.ctrl_1){
      _result=_result+data.ctrl_1;
    }
    if(data.ctrl_2){
      _result=_result+data.ctrl_2;
    }
    if(data.ctrl_3){
      _result=_result+data.ctrl_3;
    }
    if(data.ctrl_4){
      _result=_result+data.ctrl_4;
    }
    if(data.ctrl_5){
      _result=_result+data.ctrl_5;
    }
    
    return _result;
  }



///------------------------------------------------------STEP 2-----------------------------------//



  //Add Address
  fnAddAddressBtnClick(){
    const modalRef = this.modelService.open(CustomeraddressComponent ,{ size: 'lg',backdropClass: 'light-blue-backdrop',centered: true ,backdrop : 'static',
                        keyboard : false});
   
    modalRef.componentInstance.emitData.subscribe((loginEntry) => {
      this.fnGetCustomerAddress();
    });

  }

  fnEditAddressBtnClick(index){

    const modalRef = this.modelService.open(CustomeraddressComponent ,{ size: 'lg',backdropClass: 'light-blue-backdrop',centered: true ,backdrop : 'static',
                        keyboard : false});
    
    modalRef.componentInstance.fnGetCustomerAddress(this.addressColl[index].addressid);

    modalRef.componentInstance.emitData.subscribe((loginEntry) => {
      this.fnGetCustomerAddress();
    });

  }


  fnGetCustomerAddress(){
    var data = {
      'areaid':0,
      'customerid':0,            
    }

    this.loginservice.fnGetCustomerAddress(data)
    .subscribe(
      (res)=>{                  
      if(res.json().status==200){                                        
        this.addressColl=res.json().result; 
        
        if(this.cartBO.addressid){
          for(let wbo of this.addressColl){            
            if(wbo.addressid===this.cartBO.addressid){
              this.fnSetAddressByCart(wbo);
            }            
          }
        }
        
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }           
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
    
  }

  fnSelectCustomerAddress(index){
    //this.addressBO= this.addressColl[index];
    this.fnSetAddressByCart(this.addressColl[index]);    
  }

  fnChangeAddress(){
    this.cartBO.addressid=0;
    this.addressBO={};
    this.fnCheckInCheckOut()
  }
 
  fnSetAddressByCart(addBO){
    var data = {
      'addressid':addBO.addressid
    }
  
  
  this.checkoutservice.fnSetCartAddress(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){       
            this.addressBO=addBO;
            this.fnCheckInCheckOut()
            this.cartservice.fnGetCartList()
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }


  ///------------------------------------------------------STEP 3-----------------------------------//
  fnGetDeliveryType(){
    var data = {
      'customerid': 0,
      'sessionid':''
    }
  
  
  this.checkoutservice.fnGetDeliverytypeDash(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){             
            this.deliverytypeColl = res.json().result; 
                                                              
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }

  fnSelectDeliveryType(index){

    this.deliverytypeBO = this.deliverytypeColl[index];
    
   
    for (let abo of this.deliverytypeColl) {
      if (abo.deliverytypeid === this.deliverytypeColl[index].deliverytypeid) {
        abo.isselect=true
      }
      else {
        abo.isselect=false
      }
    }
    
    
    this.fnCheckInCheckOut()

  }


  fnChangeDeliveryType(){
    this.cartBO.deliverytypeid=0;
    this.deliverytypeBO={};
    this.fnCheckInCheckOut()
  }
  ///------------------------------------------------------STEP 4-----------------------------------//

  fnGetPaymentModeByOrder(){
    var data = {
      'paymodeid':0
    }
  
  
  this.checkoutservice.fnGetPaymentMode(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){             
            this.paymodeColl=res.json().result; 
            for(let wbo of this.paymodeColl){ 
              if(wbo.paytype==='WALLETS'){
                this.walletamt=wbo.walletamt;
              }
            }

                                                  
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }


  fnGetNetbanking(){
    var data = {     
    }
  
  
  this.checkoutservice.fnGetNetBanking(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){             
            this.netbankingColl=res.json().result;           
              
            for(let wbo of this.netbankingColl){            
                wbo.selectclazz="netbanking";             
            }
    
            
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }
    

    
  fnCashOnPay(){
    this.fnCheckCartAvilability('CASHPAY')
  }

  fnCardOnPay(){
    this.cardsubmitted=true;
    this.cardsuccessfully=false;
    //Validate
    if(this.cardform.valid){
      this.fnSettleOrder('CARD');
    }
  }

  fnWalletsOnPay(){
    this.fnSettleOrder('WALLETS');
  }

   fnNetBankingOnPay(){
    this.fnSettleOrder('NETBANKING');
  }


  fnSettleOrder(type){
    var data = {
      'sessionid':'',
      'customerid':0,            
      'addressid':this.addressBO.addressid,
      'paymodeid':this.fnGetPaymodeidByType(type),
      'cardnumber':this.cardform.get('cardnumber').value,  
      'expirydt':this.cardform.get('expirydt').value,  
      'cvvnumber':this.cardform.get('cvvnumber').value,  
      'cardcustomername':this.cardform.get('cardcustomername').value,  
      'ipaddress':this.checkoutservice.ipAddress
    }
  
    this.checkoutservice.fnInsertOrder(data)
    .subscribe(
      (res)=>{                  
      if(res.json().status==200){    
       
        
        if(res.json().result[0].length){
          this.successorderno=res.json().result[0][0].orderno
        }
        

        this.cartservice.fnGetCartList();

       
        //this.router.navigate(['/']);
        this.isordersuccess=true;
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }           
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })


    // var data = {

    //   'cardname':'Murughanantham',
    //   'cardnumber':'5260396958086007',  
    //   'cvv':'394',  
    //   'exmonth':'06',        
    //   'exyear':'24'
    // }
  
    // this.checkoutservice.fnPayCardPayment(data)
    // .subscribe(
    //   (res)=>{                  
    //   if(res.json().status==200){    
       
    //     console.log(res.json())

    //     var authentication=res.json().result.authentication;

    //     this.otpscreen=this._sanitizer.bypassSecurityTrustHtml(authentication.redirectHtml);

    //    // this.dataContainer.nativeElement.innerHTML = authentication.redirectHtml;
    //   }
    //   else if(res.json().status==400){                          
    //     this.fnShowErrorMsg('Error'+res.json().err_field);
    //   }           
    // },
    // (err)=>{          
    //   this.fnShowErrorMsg('Error'+ err);
    // })

  }

  fnGetPaymodeidByType(type){
    let id:number=0;
    for(let abo of this.paymodeColl){
      if(abo.paytype===type){
        id=abo.paymodeid;
      }
    }
    return id;
  }

  fnPaymodeBtnClick(type){
    this.selectpaymodemode=type;  
    this.fnInitializeCardFormGroup();
  }


  fnNetBankingBtnClick(ibo){
    

    for(let wbo of this.netbankingColl){
      if(wbo.netbankingid===ibo.netbankingid){
        wbo.selectclazz="selectnetbanking";
      }
      else{
        wbo.selectclazz="netbanking";
      }
    }

    this.selectnetbanking=ibo.netbanking;  
    
  }

///-------------------------------------------------------COUPON-----------------------------------//
fnSetCouponByOrder(couponid){
  var data = {
    'couponid':couponid
  }


this.checkoutservice.fnSetCoupon(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){                        
          
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
}


////-------------------------------------------------------Cart Availability-----------------------//
fnCheckCartAvilability(typeaction){
  var data = {
    'customerid':0
  }


  this.checkoutservice.fnGetCartAvilablity(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){                        
          var _res=res.json().result;
        
          if(_res.cartinfo){
            var statusmsg=_res.cartinfo[0].statusmsg
            var showmsg=_res.cartinfo[0].showmsg
           

            if(statusmsg==='DONE'){
              if(typeaction==='CASHPAY'){
                this.fnSettleOrder('CASH');
              }
            }
            else if(statusmsg==='CARTCHANGES'){
              let prodColl=res.json().result;
              this.fnShowCartAvilablity(prodColl)
            }
            else if(statusmsg==='FAILED'){
              alert(showmsg)
            }
            
          }
          }        
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
}

fnShowCartAvilablity(productColl){  
  const modalRef1 = this.modelService.open(CartavailablityComponent ,{ size: 'lg',backdropClass: 'light-blue-backdrop',centered: true ,backdrop : 'static',
  keyboard : false});
  
  modalRef1.componentInstance.cartproductColl=productColl;

  modalRef1.componentInstance.emitData.subscribe((loginEntry) => {
    if(loginEntry==='done'){
      this.cartservice.fnGetCartList()
    }
  });
}



  private  fnShowErrorMsg(ex){
    console.log('Internal Error', ex);       
  }


}
