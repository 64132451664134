import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../constants/contants';
import { Observable, EMPTY, Subject } from 'rxjs';
import { retry, catchError, shareReplay } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  public ischeckoutloaded:boolean=false;
  public ipAddress:string="";

  constructor(private http: Http, public router: Router) {
    this.fnGetIPAddress();
  }

  
  fnpostInputParams(url, params) {
    const headers = new Headers({
        //'Content-Type': "application/x-www-form-urlencoded",
        //'Content-Type':  "application/JSON",
        'token': (localStorage.token === undefined) ? '' : localStorage.token,
        'Session_id': localStorage.session,
        'terminal':"web",
        'whid': (localStorage.wh === undefined) ? '' : localStorage.wh,
        'authkey':AppSettings.authKey
    });
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }

  
  fnGetIPAddress()  
  {  
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{  
      this.ipAddress=res.json().ip;        
    });  
  }  

  
  //InsertOrder
  fnPayCardPayment(params){    
    return this.fnpostInputParams(AppSettings.paycardpayment,params);
  }

  


  //InsertOrder
  fnInsertOrder(params){    
    return this.fnpostInputParams(AppSettings.insertorder,params);
  }

  
  //GetOrder
  fnGetOrder(params){    
    return this.fnpostInputParams(AppSettings.getorder,params);
  }

  //GetCoupon
  fnGetCoupon(params){    
    return this.fnpostInputParams(AppSettings.getcouponbyorder,params);
  }

  //SetCoupon
  fnSetCoupon(params){    
    return this.fnpostInputParams(AppSettings.setcouponbyorder,params);
  }

   //SetCartAddress
   fnSetCartAddress(params){    
    return this.fnpostInputParams(AppSettings.setcartaddress,params);
  }

  
 //Get CartAvilablity
 fnGetCartAvilablity(params){    
  return this.fnpostInputParams(AppSettings.getCartAvailablityUrl,params);
 }
  
  
 fnGetDeliverytypeDash(params){    
  return this.fnpostInputParams(AppSettings.getdeliverytypedash,params);
 }
 

 //Set CartAvilablity
 fnSetCartAvilablity(params){    
  return this.fnpostInputParams(AppSettings.SetCartAvailablityUrl,params);
}


  //GetPaymentMode
  fnGetPaymentMode(params){    
    return this.fnpostInputParams(AppSettings.getpaymodebyorder,params);
  }


  //GetNetBanking
  fnGetNetBanking(params){    
    return this.fnpostInputParams(AppSettings.getnetbanking,params);
  }


}
