import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/services/header.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/internal/operators';

@Component({
  selector: 'app-alllist',
  templateUrl: './alllist.component.html',
  styleUrls: ['./alllist.component.css']
})
export class AlllistComponent implements OnInit {

  _isbrandlist:boolean=false;

  _deptColl:any=[];
  _categoryColl:any=[];
  _subcategoryColl:any=[];
  _brandColl:any=[];

  _deptgrpColl:any=[];

  constructor(private headerservice: HeaderService,public router: Router,private cuurenctRoute:ActivatedRoute) { 
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.fnGetUrlParameters();
   });
    
  }

  ngOnInit() {
    try{

    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  fnGetUrlParameters(){

    let _type=this.cuurenctRoute.snapshot.paramMap.get('type');
    if (_type){
    
      if(_type==="Department"){
        this.fngetDepartment();
      }
      else if(_type==="Brand"){
        this._isbrandlist=true;        
        this.fngetBrand();
      
      }

    }else{
      this.fngetDepartment();
    }

  }

  
  fngetDepartment() {   
    var data = {
          'deptid': 0,            
    }
    this.headerservice.fnGetDeparment(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this._deptColl=res.json().result;   
              this.fngetCategory();         
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fngetCategory() {   
    var data = {
      'categoryid': 0,            
      'deptid':0,            
    }
    this.headerservice.fnGetCategory(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this._categoryColl=res.json().result;            
              this.fngetSubCategory();
              
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fngetSubCategory() {   
    var data = {
      'subcategoryid': 0,            
      'categoryid':0,            
    }
    this.headerservice.fnGetSubCategory(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this._subcategoryColl=res.json().result;         
              this.fnSetDepartmentGroup();   
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fngetBrand() {   
    var data = {
      'brandid': 0,                  
    }
    this.headerservice.fnGetBrand(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this._brandColl=res.json().result;                   
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
           
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }


  
  private fnSetDepartmentGroup(){

    

    this._deptgrpColl=[];

    for(let wbo of this._deptColl){
      let _tcategoryColl:any=[];    
      let _tsubcategoryColl:any=[];
     

      for(let cbo of this._categoryColl){
        if(wbo.deptid===cbo.deptid){
            _tsubcategoryColl=[];

          for(let scbo of this._subcategoryColl){
            if(cbo.categoryid===scbo.categoryid){
              _tsubcategoryColl.push(scbo)
            }
          }   
          
          cbo.subcategoryColl=_tsubcategoryColl;

          _tcategoryColl.push(cbo)  
        }
        
      }


      wbo.categoryColl=_tcategoryColl;


      this._deptgrpColl.push(wbo);
    }

    

  }

  private fnDeptClick(index){
    
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _para=_deptid+"-"+_deptname;

      var _deptseourlname=this._deptColl[index].seourlname;

      if (_deptseourlname){
        _para=_deptseourlname;
        }

      this.router.navigate(['/'+_para]);
    }
  }

  private fnCategoryClick(index,caindex){
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname=this._deptColl[index].seourlname;

      var _categoryid=this._deptColl[index].categoryColl[caindex].categoryid;    
      var _categoryname=this._deptColl[index].categoryColl[caindex].categoryname;
      _categoryname= this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname=this._deptColl[index].categoryColl[caindex].categoryseourlname;

      var _deptpara=_deptid+"-"+_deptname;
      var _categorypara=_categoryid+"-"+_categoryname;

      if (_deptseourlname){
        _deptpara=_deptseourlname;
      }
      if (_categoryseourlname){
        _categorypara=_categoryseourlname;
      }

      this.router.navigate(['/'+_deptpara+"/"+_categorypara]);
    }    
  }

  private fnSubCategoryClick(index,caindex,scindex){
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _deptseourlname=this._deptColl[index].seourlname;

      var _categoryid=this._deptColl[index].categoryColl[caindex].categoryid;    
      var _categoryname=this._deptColl[index].categoryColl[caindex].categoryname;
      _categoryname= this.fnRemoveSpecialCharater(_categoryname);
      var _categoryseourlname=this._deptColl[index].categoryColl[caindex].categoryseourlname;


      var _scategoryid=this._deptColl[index].categoryColl[caindex].subcategoryColl[scindex].subcategoryid;    
      var _scategoryname=this._deptColl[index].categoryColl[caindex].subcategoryColl[scindex].subcategoryname;
      _scategoryname= this.fnRemoveSpecialCharater(_scategoryname);
      var _scategoryseourlname=this._deptColl[index].categoryColl[caindex].subcategoryColl[scindex].subcategoryseourlname;

      var _deptpara=_deptid+"-"+_deptname;
      var _categorypara=_categoryid+"-"+_categoryname;
      var _subcategorypara=_scategoryid+"-"+_scategoryname;

      if (_deptseourlname){
        _deptpara=_deptseourlname;
      }
      if (_categoryseourlname){
        _categorypara=_categoryseourlname;
      }
      if (_scategoryseourlname){
        _subcategorypara=_scategoryseourlname;
      }

      this.router.navigate(['/'+_deptpara+"/"+_categorypara+"/"+_subcategorypara]);
    }    
  }


  private fnBrandClick(index){
   
    if(index>-1){
      var _brandid=this._brandColl[index].brandid;    
      var _brandname=this._brandColl[index].brandname;
      _brandname= this.fnRemoveSpecialCharater(_brandname);
      var _para=_brandid+"-"+_brandname;   
      var _brandseourlname=this._brandColl[index].seourlname;
      
      if (_brandseourlname){
        _para=_brandseourlname;
        }

      this.router.navigate(['/'+_para]);
    }
  }


  private fnRemoveSpecialCharater(str){
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    str = str.toString().replace(/ /g, "");
    return str;
  }
  

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
