import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {

  customerBO:any={};   
  customerLoyaltyColl:any=[];

  customerView:any={};

  totalpoints:number=0;

  fromdate:string="";
  todate:string="";



  constructor(private loginservice: LoginService,private cartservice:CartService,public router: Router,private datePipe: DatePipe) { 
    this.fnServiceChanges();
  }

  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                
        }      
        this.fnGetLoyalty();     
    }); 
  }
  

  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }


  fnGetLoyalty(){  

    var data = {     
      'fromdate':this.fnFormatDatetime(this.fromdate),
      'todate':this.fnFormatDatetime(this.todate)    
    }

  
    this.loginservice.fnGetLoyaltyInCustomer(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                        
              this.customerLoyaltyColl=res.json().result;  
              this.fnCalcPoints();                                  
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fnCalcPoints(){
    this.totalpoints=0;
    for(let wbo of  this.customerLoyaltyColl){
      this.totalpoints= this.totalpoints+(wbo.cramt-wbo.dramt);
    }
  }

  
  fnSetRedeemPoints(){  
    var data = { 
       
    }

  
    this.loginservice.fnSetRedeemPointstInCustomer(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                        
              this.loginservice.fnGetCustomerList();                                
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }          
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  

fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
