import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/services/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { google } from "google-maps";
import { HomeService } from 'src/app/services/home.service';
@Component({
  selector: 'app-customeraddress',
  templateUrl: './customeraddress.component.html',
  styleUrls: ['./customeraddress.component.css']
})
export class CustomeraddressComponent implements OnInit,AfterViewInit {
  @ViewChild('search',{static:false})
  public searchElementRef: ElementRef;
  
  @Input() isaddress:boolean=false;
  @Output() emitData = new EventEmitter();
  
  settingsColl:any=[];

  countrycode:string="+91";
  mobilecode:any=[{value:50,displaytxt:50}];

  //Save Validation
  submitted:boolean;
  successfully:boolean;

  cityColl:any=[]; 
  addressColl:any=[]; 
  @Input() addressid:number=0

  latitude = 11.0168;
  longitude = 76.9558;
  mapType = 'terrain';
  markers = [ ];
  selectedMarker;
  private geoCoder;
  
  marker;
  locationname:'';

  
  islocationsuccess:boolean=false;  
  locationstatusmsg:string='';
  locationstatusclass:string='';
  islocationselect:boolean=false;  
  locationareaid:number=0;
  locationcityid:number=0;
  locationmapname:'';

  constructor(public activeModal: NgbActiveModal,public loginservice: LoginService, public homeservice: HomeService, 
    private mapsAPILoader: MapsAPILoader,  private ngZone: NgZone) { }

  
  
  customerform=new FormGroup({
    addressid:new FormControl(0), 
    addressnickname:new FormControl(''),       
    firstname:new FormControl('',Validators.required),         
    lastname:new FormControl(''),
    houseno:new FormControl(''), 
    mobile:new FormControl(null,[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),  
    street:new FormControl('',Validators.required),               
    areaid:new FormControl(0),               
    cityid:new FormControl(0,Validators.required),               
    landmark:new FormControl('',Validators.required),     
    city:new FormControl(''),     
    addressothername:new FormControl(''),    
    prefixmobile:new FormControl(''), 
    isdefault:new FormControl(false),               
  });


  ngOnInit() {
    try{
         
      this.customerform.reset();
      this.fnInitializeCustomerFormGroup(); 
      
      this.fnGetSettingsByType();
   
      this.islocationsuccess=true;
      this.fnGetCity();
      //this.setCurrentLocation();
      //this.fnSetAutoCompleteCity();
      

  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
  }

  ngAfterViewInit() {
   
  }


  fnInitializeCustomerFormGroup(){
    this.customerform.setValue({
      addressid:0,
      addressnickname:'',
      firstname:'',      
      lastname:'',      
      houseno:'',            
      mobile:'',    
      street:'',   
      areaid:null,   
      cityid:0,       
      landmark:'',
      city:'',
      addressothername:'',
      prefixmobile:'',
      isdefault:false       
    })
  }

  fnGetCity() {
    var data = {
      'cityid': 0,
    }

    this.loginservice.fnGetCity(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.cityColl = res.json().result;           
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }
        },
        (err) => {
          this.fnShowErrorMsg('Error' + err);
        })

  }

  
  fnGetSettingsByType() { 
    var data = {   
      'settingstype':'LOGIN'            
    }
    this.homeservice.fnGetSettingsByType(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this.settingsColl=res.json().result;           
              
              const index = this.settingsColl.findIndex(a => a.settingsname ==='COUNTRYCODE' );
              if(index>-1){
                this.countrycode=this.settingsColl[index].settingsvalue;
              }

              // const index1 = this.settingsColl.findIndex(a => a.settingsname ==='MOBILECODE' );
              // if(index1>-1){
              //   let mcode= this.settingsColl[index1].settingsvalue.split(",");
                
              //   this.mobilecode=[];
              //   if(mcode.length){
              //     for(let wbo of mcode){
              //       this.mobilecode.push({value:wbo,displaytxt:wbo});
              //     }
              //   }
                
              // }

             
            }
            else if(res.json().status==400){                          
              this.fnShowErrorMsg('Error'+res.json().err_field);
            }
            
          },
          (err)=>{          
            this.fnShowErrorMsg('Error'+ err);
          })
  }

  fnGetDefaultMobileCode(){

    if(this.mobilecode.length){
      let a=this.mobilecode[0].value;     
      return a;
    }else{
      return "";
    }
  }


  fnGetCustomerAddress(addrid){
    this.addressid=addrid;
    var data = {
      'addressid':this.addressid,            
      'customerid':0
    }
    this.loginservice.fnGetCustomerAddress(data)
    .subscribe(
      (res)=>{           
       
        this.addressColl = res.json().result;
        
        console.log( this.addressColl)
        
        var editdata = {            
          'addressid':this.addressColl[0].addressid,          
          'addressnickname':this.addressColl[0].addressnickname,
          'firstname':this.addressColl[0].firstname,               
          'lastname':this.addressColl[0].lastname,               
          'mobile':this.addressColl[0].mobile,               
          'houseno':this.addressColl[0].houseno,     
          'street':this.addressColl[0].street,     
          'areaid':this.addressColl[0].areaid,     
          'cityid':this.addressColl[0].cityid,     
          'landmark':this.addressColl[0].landmark,
          'latitude':this.addressColl[0].latitude,
          'longitude':this.addressColl[0].longitude,
          'maplocationname':this.addressColl[0].maplocationname,
          'city':this.addressColl[0].city,
          'addressothername':this.addressColl[0].addressothername,
          'isdefault':this.addressColl[0].isdefault,
        }

        this.fnEditMode(editdata);
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
  }

  
  fnSaveBtnClick(){
    try {      
      
      this.submitted=true;
      this.successfully=false;
      //Validate
      if(this.customerform.valid){
        let mobile=this.customerform.get('mobile').value;

        var data = {
          'addressid':this.customerform.get('addressid').value,
          'customerid':0,
          'addressnickname':this.customerform.get('addressnickname').value,
          'firstname':this.customerform.get('firstname').value,               
          'lastname':this.customerform.get('lastname').value,               
          'mobile':mobile,               
          'houseno':this.customerform.get('houseno').value,     
          'street':this.customerform.get('street').value,     
          'areaid':this.customerform.get('areaid').value,     
          'cityid':this.customerform.get('cityid').value,     
          'landmark':this.customerform.get('landmark').value,     
          'city':this.customerform.get('city').value,     
          'addressothername':this.customerform.get('addressothername').value,     
          'isdefault':this.customerform.get('isdefault').value,     
          'latitude':this.latitude,     
          'longitude':this.longitude,     
          'maplocationname':this.locationmapname,    
          'countrycode':this.countrycode,     
       }

       if (this.customerform.get('addressid').value>0){
            this.loginservice.fnSetCustomerAddress(data)
              .subscribe(
                (res)=>{                  
                if(res.json().status==200){                
                                  
                  this.emitData.emit(data.addressid);  
                  this.activeModal.close(true);  
                }
                else if(res.json().status==400){                          
                  this.fnShowErrorMsg('Error'+res.json().err_field);
                }           
              },
              (err)=>{          
                this.fnShowErrorMsg('Error'+ err);
              })
       }
       else {
            this.loginservice.fnInsertCustomerAddress(data)
            .subscribe(
              (res)=>{                  
              if(res.json().status==200){                
                               

                this.emitData.emit(res.json().result[0].addressid);  
                this.activeModal.close(true);  
              }
              else if(res.json().status==400){                          
                this.fnShowErrorMsg('Error'+res.json().err_field);
              }           
            },
            (err)=>{          
              this.fnShowErrorMsg('Error'+ err);
            })
       }


      }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  
  private fnEditMode(deptBO){
    
    this.customerform.patchValue({
      addressid:deptBO.addressid,
      addressnickname:deptBO.addressnickname,
      firstname:deptBO.firstname,      
      lastname:deptBO.lastname,      
      houseno:deptBO.houseno,            
      mobile:deptBO.mobile,    
      street:deptBO.street,   
      areaid:deptBO.areaid,   
      cityid:deptBO.cityid,       
      landmark:deptBO.landmark,   
      city:deptBO.city,   
      addressothername:deptBO.addressothername,   
      prefixmobile:deptBO.mobile.substring(0,2),    
      isdefault:deptBO.isdefault,
    })
    
    this.latitude=deptBO.latitude;
    this.longitude=deptBO.longitude;
    this.locationmapname=deptBO.maplocationname;

    

   // this.getAddress(this.latitude,this.longitude);

  }


  
addMarker(lat: number, lng: number) {
 // this.markers.push({ lat, lng, alpha: 0.4 });
  this.latitude=lat;
  this.longitude=lng;
  this.getAddress(lat,lng);
}

dragMarker(event) {

  this.latitude= event.coords.lat;
  this.longitude=event.coords.lng;
  this.getAddress(this.latitude,this.longitude);
  
}



private setCurrentLocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;  
      this.getAddress(this.latitude,this.longitude);
    });
  }

 
}

getAddress(latitude, longitude) {
  if(latitude<=0){
    return;
  }
  if(longitude<=0){
    return;
  }
  
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
   // console.log(results);
   // console.log(status);
    if (status === 'OK') {
      if (results[0]) {              
        var res=results[0]; 
        this.locationname=res.formatted_address


        this.fnCheckAvaiableLocation(res);
        
       //console.log(res);
      } else {
        window.alert('No results found');
      }
    } else {
      window.alert('Geocoder failed due to: ' + status);
    }

  });
}

fnCheckAvaiableLocation(res1){


  this.islocationselect=false;
  this.locationstatusmsg="Oh no! This location is outside of our service area";
  this.locationstatusclass="location-picker__error";
  this.locationareaid=null;
  this.locationcityid=null;
  this.locationmapname="";


  
  var data = {
    'latitude':parseFloat(this.latitude.toString()).toFixed(4),     
    'longitude':parseFloat(this.longitude.toString()).toFixed(4),        
  }

  this.loginservice.fnGetLocationbyGeocode(data)
  .subscribe(
    (res)=>{                  
    if(res.json().status==200){  

     var resut=res.json().result;        

     if(resut.statustype==='DONE'){
      this.locationstatusmsg="Available Nativelovers near me";
      this.locationstatusclass="location-picker__success";
      this.islocationselect=true;
      this.locationareaid=resut.areainfo[0].areaid;
      this.locationcityid=resut.areainfo[0].cityid;
      this.locationmapname=res1.formatted_address;
     }
    
    
    }
    else if(res.json().status==400){                          
      this.fnShowErrorMsg('Error'+res.json().err_field);
    }           
  },
  (err)=>{          
    this.fnShowErrorMsg('Error'+ err);
  })


  // for(var _abo of this.areaColl){    

  //   if(_abo.latitude>parseFloat(this.latitude.toString()).toFixed(4) && 
  //     _abo.longitude<parseFloat(this.longitude.toString()).toFixed(4)){        
  //       this.locationstatusmsg="Available Nativelovers near me";
  //       this.locationstatusclass="location-picker__success";
  //       this.islocationselect=true;
  //       this.locationareaid=_abo.areaid;
  //       this.locationmapname=res.formatted_address;
  //   }
  // }  
}

fnSetAutoCompleteCity(){

  this.mapsAPILoader.load().then(() => {
    //this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
   // console.log(this.geoCoder)
  });

  //load Places Autocomplete
  this.mapsAPILoader.load().then(() => {
   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
       componentRestrictions: { country:['AE','IN'] },
       types: ["establishment"]
   });
   autocomplete.addListener("place_changed", () => {
       this.ngZone.run(() => {
           //get the place result
           let place: google.maps.places.PlaceResult = autocomplete.getPlace();

           //verify result
           if (place.geometry === undefined || place.geometry === null) {
               return;
           }
          this.latitude=place.geometry.location.lat();
          this.longitude=place.geometry.location.lng(); 
          this.getAddress(this.latitude,this.longitude);             
       });
   });
});
}

fnConfirmLocation(){
  this.islocationsuccess=true;
  // this.customerform.patchValue({  
  //   areaid:this.locationareaid,
  //   cityid:this.locationcityid,        
  // })

}


  private  fnShowErrorMsg(ex){
    console.log('Internal Error', ex);       
  }

}
