import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accountmenu',
  templateUrl: './accountmenu.component.html',
  styleUrls: ['./accountmenu.component.css']
})
export class AccountmenuComponent implements OnInit {

  
  customerBO:any={};   
  customerOrderMainColl:any=[];
  customerOrderDetailsColl:any=[];
  customerView:any={};

  constructor(private loginservice: LoginService,private cartservice:CartService,public router: Router) { 
    this.fnServiceChanges();
  }

  
  fnServiceChanges(){
      
    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                
        }      
        if(this.customerView.ordermain){                
          this.customerOrderMainColl= this.customerView.ordermain   
         
        }
       
        if(this.customerView.orderdetails){                
          this.customerOrderDetailsColl= this.customerView.orderdetails   
        }

    }); 
  }


  ngOnInit() {
    try{
      this.loginservice.fnGetCustomerList();
    }catch(ex){
      this.fnShowErrorMsg('Internal Error'+ex);
    }
  }

  private  fnShowErrorMsg(ex){
    console.log(ex);       
  }

}
