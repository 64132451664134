import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { filter } from 'rxjs/internal/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LoginService } from 'src/app/services/login.service';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-itemdetails',
  templateUrl: './itemdetails.component.html',
  styleUrls: ['./itemdetails.component.css']
})
export class ItemdetailsComponent implements OnInit {
  cartBO:any={};
  cartColl:any=[];
  cartView:any={};

  customerBO:any={};   
  customerView:any={};

  _deptColl:any=[];
  _categoryColl:any=[];    
  _subcategoryColl:any=[];
  _deptgrpColl:any=[];
  _brandColl:any=[];
  _filterheaderColl:any=[];

  _filterheader:String="";

  itemColl:any=[];
  itemBO:any={};
  showImage="";
  itemrelatedColl:any=[];
  itemcrosellColl:any=[];
  itemfaqColl:any=[];
  itemspecColl:any=[];
  itemsimilarColl:any=[];
  itemimagesColl:any=[];
  itemskuColl:any=[];
  reviewsColl:any=[];
  offeritemsColl:any=[];
  itemswarrentyColl:any=[];
  itemspolicyColl:any=[];
  itemsratingsColl:any=[];

  specactiveIds: string[] = [];
  faqactiveIds: string[] = [];

  relatedproductOptions: OwlOptions= {};
  crosellproductOptions: OwlOptions= {};
  similarproductOptions: OwlOptions= {};

  _productid:number=0;
  _currenturlpath:string="";
  isload:boolean=false;
  
  _selecttabset:string="";

  
  constructor(private homeservice: HomeService,private cartservice: CartService,private headerservice: HeaderService,
    public router: Router,private cuurenctRoute:ActivatedRoute,private loginservice: LoginService) { 

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.fnGetUrlParameters();
   });

   this.fnServiceChanges();

  }

  
fnServiceChanges(){

    this.cartView = this.cartservice.cartView; 
    this.cartservice.servicecartChange.subscribe((value) => {
      this.cartView = value
      if(this.cartView.cartmain){
        this.cartBO= this.cartView.cartmain
      }
      if(this.cartView.cartdetails){
        this.cartColl= this.cartView.cartdetails
      }

      this.fnUpdateCartChange();
    });    

    this.customerView = this.loginservice.customerView; 
    this.loginservice.servicecustomerChange.subscribe((value) => {
        this.customerView = value        
        if(this.customerView.customermain){                
          this.customerBO= this.customerView.customermain                
        }           
    }); 



}

ngOnInit() {
  this.fnsetCaroosellOption();
  this.loginservice.fnGetCustomerList();
}

fnsetCaroosellOption(){
  this.relatedproductOptions= { 
    loop: true, 
    margin:10,
    dots: false,   
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    lazyLoad: true,
    nav: true,
    items:5,
    navText:['<img src="assets/images/prev.png"/>', '<img src="assets/images/next.png"/>'], 
    responsive:{ 0:{ items:2 },600:{ items:3 }, 800:{ items:4 }, 1000:{ items:5 }, 1280:{ items:5} } };

  this.crosellproductOptions= { 
      loop: true, 
      margin:10,
      dots: false,   
      autoplay: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      navSpeed: 700,
      lazyLoad: true,
      nav: true,
      navText:['<img src="assets/images/prev.png"/>', '<img src="assets/images/next.png"/>'], 
      responsive:{ 0:{ items:2 }, 600:{ items:3 },800:{ items:4}, 1000:{ items:6 }, 1280:{ items:6 } } };

  this.similarproductOptions= { 
        loop: true, 
        margin:10,
        dots: false,   
        autoplay: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        navSpeed: 700,
        lazyLoad: true,
        nav: true,
        navText:['<img src="assets/images/prev.png"/>', '<img src="assets/images/next.png"/>'], 
        responsive:{ 0:{ items:2 },  600:{ items:3 },800:{ items:4 },1000:{ items:6 }, 1280:{ items:6 } } };
}

//Url ParameterFilter
fnGetUrlParameters(){

    let _productname=this.cuurenctRoute.snapshot.paramMap.get('productname');


    if (_productname){
      let splitstr=_productname.split("-");
      if (splitstr.length>0){
        this._productid=Number(splitstr[0]);
      
      }      
    }
   
    this._currenturlpath=this.router.url;
    let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));

    if(url!=""){
      this._currenturlpath=url;
    }
    

    this.fngetItemList();  
   
}

//Get Item
fngetItemList() { 
  this.fnShowProcess();
    var data = {
      'skuid':this._productid,            
      'customerid':0,
      'sessionid':'',

    }

  
  this.homeservice.fngetItemDetailsUrl(data)
      .subscribe(
        (res)=>{         
          this.fnHideProcess();         
          if(res.json().status==200){      
            this.fnSetItemDetailsData(res);
            this._selecttabset="SPEC";
            // //Main
            // this.itemColl=res.json().result[0];                               
            // if(this.itemColl.length>0){
            //   this.itemBO= this.itemColl[0];              
            //   this.showImage= this.itemBO.picurl;     
            //   //this._prodrating= this.itemBO.ratings;     
            // }            
            // this.itemrelatedColl=res.json().result[1];  
            // this.itemcrosellColl=res.json().result[2]; 
            // this.itemfaqColl=res.json().result[3]; 
            // this.itemspecColl=res.json().result[4]; 
          
            // //Side
            // this._deptColl=res.json().result[5];                   
            // this._categoryColl=res.json().result[6];                   
            // this._subcategoryColl=res.json().result[7];                   
            // this._brandColl=res.json().result[8];   
            // this._filterheaderColl=res.json().result[9]; 

            // this.itemsimilarColl=res.json().result[10]; 

            this.fnSetDepartmentGroup(); 
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnHideProcess();
          this.fnShowErrorMsg('Error'+ err);
        })
}

fnSetItemDetailsData(res){
  var _res =res.json().result;    



   if(_res.productinfo){
     this.itemColl=_res.productinfo;     
     if(this.itemColl.length>0){
      this.itemBO= this.itemColl[0];              
      this.showImage= this.itemBO.picurl;         
    } 
   }

   if(_res.relatedproduct){
     this.itemrelatedColl=_res.relatedproduct;                                              
   }

   if(_res.crosssellproduct){
     this.itemcrosellColl=_res.crosssellproduct;                                              
   }

   if(_res.productfaq){
     this.itemfaqColl=_res.productfaq;                                              
   }

   if(_res.productspecficaion){
     this.itemspecColl=_res.productspecficaion;                                              
   }

   if(_res.relateddepartment){
     this._deptColl=_res.relateddepartment;                                              
   }

   if(_res.relatedcategory){
     this._categoryColl=_res.relatedcategory;                                              
   }

   if(_res.relatedsubcategory){
     this._subcategoryColl=_res.relatedsubcategory;                                              
   }

   if(_res.relatedbrand){
     this._brandColl=_res.relatedbrand;                                              
   }

   if(_res.breadcrumbnav){
     this._filterheaderColl=_res.breadcrumbnav;                                              
   }
  
   if(_res.similarproducts){
     this.itemsimilarColl=_res.similarproducts; 
   }

   if(_res.productimages){
    this.itemimagesColl=_res.productimages; 
   }

    if(_res.productsku){
      this.itemskuColl=_res.productsku; 
    }
   
    if(_res.productreviews){
      this.reviewsColl=_res.productreviews; 
    }
   

    if(_res.offerproduct){
      this.offeritemsColl=_res.offerproduct; 
    }

    if(_res.productwarrenty){
      this.itemswarrentyColl=_res.productwarrenty; 
    }

    if(_res.productpolicy){
      this.itemspolicyColl=_res.productpolicy; 
    }

    if(_res.productratings){
      this.itemsratingsColl=_res.productratings; 
    }
   
   
    
 }


private fnSetDepartmentGroup(){
  this._deptgrpColl=[];

  for(let wbo of this._deptColl){
    let _tcategoryColl:any=[];    
    let _tsubcategoryColl:any=[];
   

    for(let cbo of this._categoryColl){
      if(wbo.deptid===cbo.deptid){
        _tcategoryColl.push(cbo)  

        _tsubcategoryColl=[];

        for(let scbo of this._subcategoryColl){
          if(cbo.categoryid===scbo.categoryid){
            _tsubcategoryColl.push(scbo)
          }
        }                         
      }
      
    }
    wbo.categoryColl=_tcategoryColl;
    wbo.subcategoryColl_tsubcategoryColl;

    this._deptgrpColl.push(wbo);
  }

  this.specactiveIds=[];
  //  let a=0;
  //  for(let cbo of this.itemspecColl){
  //    this.specactiveIds.push("spec-"+a)
  //    a+=1;
  //  }
  if(this.itemspecColl.length){
    this.specactiveIds.push("spec-"+0);
  }
   
  this.faqactiveIds=[];
  if(this.itemfaqColl.length){
    this.faqactiveIds.push("faq-"+0);
  }

}

  //Click Events
  private fnDeptClick(index){
    
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);
      var _para=_deptid+"-"+_deptname;

      this.router.navigate(['/items/'+_para]);
    }
  }

  private fnCategoryClick(index,caindex){
    if(index>-1){
      var _deptid=this._deptColl[index].deptid;    
      var _deptname=this._deptColl[index].deptname;
      _deptname= this.fnRemoveSpecialCharater(_deptname);

      var _categoryid=this._deptColl[index].categoryColl[caindex].categoryid;    
      var _categoryname=this._deptColl[index].categoryColl[caindex].categoryname;
      _categoryname= this.fnRemoveSpecialCharater(_categoryname);

      var _deptpara=_deptid+"-"+_deptname;
      var _categorypara=_categoryid+"-"+_categoryname;

      this.router.navigate(['/items/'+_deptpara+"/"+_categorypara]);
    }    
  }

  private fnHeaderClick(type){
      if(this._filterheaderColl.length>0){
      if(type==="DEPARTMENT"){
        var _deptid=this._filterheaderColl[0].deptid;    
        var _deptname=this._filterheaderColl[0].deptname;
        
        _deptname= this.fnRemoveSpecialCharater(_deptname);
        var _para=_deptid+"-"+_deptname;

        var _deptseourlname=this._filterheaderColl[0].deptseourlname;
        if (_deptseourlname){
          _para=_deptseourlname;
        }

        this.router.navigate(['/'+_para]);  
      }
      else  if(type==="CATEGORY"){
        var _deptid=this._filterheaderColl[0].deptid;    
        var _deptname=this._filterheaderColl[0].deptname;
        _deptname= this.fnRemoveSpecialCharater(_deptname);
        var _deptseourlname=this._filterheaderColl[0].deptseourlname;
       
        var _categoryid=this._filterheaderColl[0].categoryid;    
        var _categoryname=this._filterheaderColl[0].categoryname;
        _categoryname= this.fnRemoveSpecialCharater(_categoryname);
        var _categoryseourlname=this._filterheaderColl[0].categoryseourlname;
  
        var _deptpara=_deptid+"-"+_deptname;
        var _categorypara=_categoryid+"-"+_categoryname;

        if (_deptseourlname){
          _deptpara=_deptseourlname;
        }
        if (_categoryseourlname){
          _categorypara=_categoryseourlname;
        }

        this.router.navigate(['/'+_deptpara+"/"+_categorypara]);
      }

      else  if(type==="SUBCATEGORY"){
        var _deptid=this._filterheaderColl[0].deptid;    
        var _deptname=this._filterheaderColl[0].deptname;
        _deptname= this.fnRemoveSpecialCharater(_deptname);
        var _deptseourlname=this._filterheaderColl[0].deptseourlname;

        var _categoryid=this._filterheaderColl[0].categoryid;    
        var _categoryname=this._filterheaderColl[0].categoryname;
        _categoryname= this.fnRemoveSpecialCharater(_categoryname);
        var _categoryseourlname=this._filterheaderColl[0].categoryseourlname;

        var _scategoryid=this._filterheaderColl[0].subcategoryid;    
        var _scategoryname=this._filterheaderColl[0].subcategoryname;
        _scategoryname= this.fnRemoveSpecialCharater(_scategoryname);
        var _subcategoryseourlname=this._filterheaderColl[0].subcategoryseourlname;

        var _deptpara=_deptid+"-"+_deptname;
        var _categorypara=_categoryid+"-"+_categoryname;
        var _scategorypara=_scategoryid+"-"+_scategoryname;

        if (_deptseourlname){
          _deptpara=_deptseourlname;
        }
        if (_categoryseourlname){
          _categorypara=_categoryseourlname;
        }
        if (_subcategoryseourlname){
          _scategorypara=_subcategoryseourlname;
        }

        this.router.navigate(['/'+_deptpara+"/"+_categorypara+"/"+_scategorypara]);
      }
      
      else if(type==="HOME"){
        this.router.navigate(['/']);
      }
    }
  }

  private fnBrandClick(index){
    
    if(index>-1){
      var _brandid=this._brandColl[index].brandid;    
      var _brandname=this._brandColl[index].brandname;
      _brandname= this.fnRemoveSpecialCharater(_brandname);
      var _para=_brandid+"-"+_brandname;

      this.router.navigate(['/brands/'+_para]);
    }
  }

  private fnSpecificationHeaderExpandClick(index){

    if(this.specactiveIds.length){
      if(this.specactiveIds[0]==="spec-"+index){
        this.specactiveIds=[];
      }else{
        this.specactiveIds=[];
        this.specactiveIds.push("spec-"+index);
      }
    }else{
      this.specactiveIds=[];
      this.specactiveIds.push("spec-"+index);
    }



  }

  private fnFaqHeaderExpandClick(index){

    if(this.faqactiveIds.length){
      if(this.faqactiveIds[0]==="faq-"+index){
        this.faqactiveIds=[];
      }else{
        this.faqactiveIds=[];
        this.faqactiveIds.push("faq-"+index);
      }
    }else{
      this.faqactiveIds=[];
      this.faqactiveIds.push("faq-"+index);
    }



  }

  private fnMultipleImagesClick(index){
    
    if (index<0){
      return
    }
    if(this.itemimagesColl.length ){
      this.showImage= this.itemimagesColl[index].picurl
      this.itemBO.picurl=this.itemimagesColl[index].picurl
    }
   
  }

  fnProductSkuBtnClick(index) {
    if (index<0){
      return
    }
    if(this.itemskuColl.length ){
      var _productidid=this.itemskuColl[index].skuid;    
      var _productidname=this.itemskuColl[index].productname;
      _productidname= this.fnRemoveSpecialCharater(_productidname);
      var _para=_productidid+"-"+_productidname;

      this.router.navigate(['/p/'+_para]);
    }

  }

  fnSetItemWishList() { 
    
      
      if(!this.customerBO.customerid){               
          this.loginservice.fnOpenLogin();
      }
      else{     
        var data = {
          'productid':this.itemBO.productid,            
          'skuid':this.itemBO.skuid,
          'customerid':'',
    
        }         
        this.loginservice.fnSetCustomerWishlist(data)
            .subscribe(
              (res)=>{                    
                if(res.json().status==200){       
                  let wid=res.json().result[0][0].wishlistid;
                  if(wid===0){       
                    this.itemBO.wishlistid=wid;
                  }
                  else if(wid>0){          
                    this.itemBO.wishlistid=wid;
                  }                
                else if(res.json().status==400){                          
                  this.fnShowErrorMsg('Error'+res.json().err_field);
                }   
              }       
              },
              (err)=>{          
                this.fnShowErrorMsg('Error'+ err);
              })
      }
  }


  
fnUpdateCartChange(){

  const index = this.cartColl.findIndex(a => a.productid === this.itemBO.productid && a.skuid === this.itemBO.skuid );
  if(index>-1){
    this.itemBO.qty= this.cartColl[index].qty;
  }else{
    this.itemBO.qty=0;
 }  


 for(let wbo of this.itemrelatedColl){
  const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
  if(index>-1){
    wbo.qty= this.cartColl[index].qty;
  }else{
    wbo.qty=0;
  }    
}  

 
for(let wbo of this.itemcrosellColl){
  const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
  if(index>-1){
    wbo.qty= this.cartColl[index].qty;
  }else{
    wbo.qty=0;
  }    
}  

 
for(let wbo of this.itemsimilarColl){
  const index = this.cartColl.findIndex(a => a.productid === wbo.productid && a.skuid === wbo.skuid );
  if(index>-1){
    wbo.qty= this.cartColl[index].qty;
  }else{
    wbo.qty=0;
  }    
}  

}



//Product
fnAddProductBtnClick(){
    
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': this.itemBO.productid, 
      'skuid':this.itemBO.skuid,
      'qty':1      
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.itemColl.findIndex(a => a.productid === this.itemBO.productid && a.productid === this.itemBO.productid );
          if(index>-1){
            this.itemColl[index].qty=1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })

}

fnProductIncrementBtnClick(){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': this.itemBO.productid, 
      'skuid':this.itemBO.skuid,
      'qty':this.itemBO.qty+1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.itemColl.findIndex(a => a.productid === this.itemBO.productid && a.productid === this.itemBO.productid );
          if(index>-1){
            this.itemColl[index].qty=this.itemBO.qty+1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
}

fnProductDecrementBtnClick(){
    var data = {
      'customerid':0,            
      'sessionid': '',            
      'productid': this.itemBO.productid, 
      'skuid':this.itemBO.skuid,
      'qty':this.itemBO.qty-1     
    }

    this.cartservice.fnInsertCartProduct(data)
    .subscribe(
      (res)=>{                  
        if(res.json().status==200){     

          const index = this.itemColl.findIndex(a => a.productid === this.itemBO.productid && a.productid === this.itemBO.productid );
          if(index>-1){
            this.itemColl[index].qty=this.itemBO.qty-1;
          }
          this.cartservice.fnGetCartList();
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          
        this.fnShowErrorMsg('Error'+ err);
      })
}

//Related Product 
fnAddProductRelatedBtnClick(prodBO){
    
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':1      
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemrelatedColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemrelatedColl[index].qty=1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })

}

fnProductIncrementRelatedBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty+1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemrelatedColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemrelatedColl[index].qty=prodBO.qty+1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnProductDecrementRelatedBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty-1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemrelatedColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemrelatedColl[index].qty=prodBO.qty-1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

//Crossell Product 
fnAddProductCrossellBtnClick(prodBO){
    
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':1      
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemcrosellColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemcrosellColl[index].qty=1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })

}

fnProductIncrementCrossellBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty+1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemcrosellColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemcrosellColl[index].qty=prodBO.qty+1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnProductDecrementCrossellBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty-1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemcrosellColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemcrosellColl[index].qty=prodBO.qty-1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}


//Similar Product 
fnAddProductSimilarBtnClick(prodBO){
    
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':1      
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemsimilarColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemsimilarColl[index].qty=1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })

}

fnProductIncrementSimilarBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty+1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemsimilarColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemsimilarColl[index].qty=prodBO.qty+1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnProductDecrementSimilarBtnClick(prodBO){
  var data = {
    'customerid':0,            
    'sessionid': '',            
    'productid': prodBO.productid, 
    'skuid':prodBO.skuid,
    'qty':prodBO.qty-1     
  }

  this.cartservice.fnInsertCartProduct(data)
  .subscribe(
    (res)=>{                  
      if(res.json().status==200){     

        const index = this.itemsimilarColl.findIndex(a => a.productid === prodBO.productid && a.productid === prodBO.productid );
        if(index>-1){
          this.itemsimilarColl[index].qty=prodBO.qty-1;
        }
        this.cartservice.fnGetCartList();
      }
      else if(res.json().status==400){                          
        this.fnShowErrorMsg('Error'+res.json().err_field);
      }          
    },
    (err)=>{          
      this.fnShowErrorMsg('Error'+ err);
    })
}

fnNotifyMeProductClick(){
  if(!this.customerBO.customerid){               
    this.loginservice.fnOpenLogin();
  }
  else{
      var data = {
        'customerid':0,            
        'sessionid': '',            
        'productid': this.itemBO.productid, 
        'skuid':this.itemBO.skuid,          
      }

      this.cartservice.fnSetCustomerNotifyMe(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){     
          
          }
          else if(res.json().status==400){                          
            this.fnShowErrorMsg('Error'+res.json().err_field);
          }          
        },
        (err)=>{          
          this.fnShowErrorMsg('Error'+ err);
        })
  }

}

fnSeeMoreOfferClick(){
  this.cartservice.fnCheckSlideOfferItems(this.offeritemsColl);
}

fnSellerClick(){
  this.router.navigate(['/'+this.fnRemoveSpecialCharater(this.itemBO.vendorname)]);  
}

fnChangeLocation(){
  this.headerservice.servicelocationChange.next(true)
}

//Share Btn Click
fnFaceBookClick(){
  window.open('http://www.facebook.com/sharer/sharer.php?u=www.google.com');
}


private fnRemoveSpecialCharater(str){
  str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  str = str.toString().replace(/ /g, "");
  return str;
}

private fnShowProcess(){
  this.isload=false;
}
private fnHideProcess(){
  this.isload=true;
}


private  fnShowErrorMsg(ex){
  console.log(ex);       
}


}
